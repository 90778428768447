import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withWidth, {isWidthUp} from '@material-ui/core/withWidth'
import {ProjectType} from '../common/ProjectType'
import {ParagraphHeader} from '../common/common'
import {ProjectExamples} from '../common/ProjectExamples'
import {getImageURLs, getImageTags, ProjectTypeValue} from '../../model/project'
import {BackyardPaverProjectSizeOption} from './project/paver/BackyardPaverProjectSize'
import {BackyardPaverProjectPackageOption} from './project/paver/BackyardPaverProjectPackage'
import {DrivewayPaverProjectSizeOption} from './project/paver/DrivewayPaverProjectSize'
import {GoodDeal} from './project/common/GoodDeal'
import {BrickoProjectSummary} from './project/paver/BrickoProjectSummary'
import {useTheme} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {bpNumber, bpString} from '../../utils/styles'
import {formatPrice} from '../../utils/strings'
import {ShrubCoverageOptions} from './project/common/ShrubCoverage'
import {CheckBoxComp} from '../../components/common/CheckBoxComp'
import {BRAND_YELLOW} from '../../config/styles'

// const drivewayPaverImages = [
//   '/images/examples/17.webp',
//   // '/images/examples/19.webp',
//   '/images/examples/20.webp',
//   // '/images/examples/2.webp',
//   // '/images/examples/3.webp',
//   '/images/examples/4.webp',
//   '/images/examples/5.webp',
//   '/images/examples/6.webp',
//   '/images/examples/7.webp',
//   '/images/examples/18.webp',
//   '/images/examples/9.webp',
//   // '/images/examples/10.webp',
//   '/images/examples/11.webp',
//   '/images/examples/12.webp',
//   // '/images/examples/13.webp',
//   '/images/examples/14.webp',
//   // '/images/examples/15.webp',
//   '/images/examples/16.webp',
// ]

const HideIrrigation = true

export interface ProjectSummaryProps {
  title: string
  addr: string
  addr2?: string
  city: string
  state: string
  zipCode: string
  price: string
  appreciation: string
  streetViewURL: string
  readonly: boolean
  projTypeValue: ProjectTypeValue
  selectedSize: string
  selectedPackage: string
  backyardPaversRegularBase: number
  backyardPaversRegularEntertainment: number
  backyardPaversExtendedEntertainment: number
  backyardPaversExtendedPremiumEntertainment: number
  backyardPaversRegularSqft: number
  backyardPaversExtendedSqft: number
  dtfyRegularWithIrrigation: number
  dtfyRegularNoIrrigation: number
  dtfyDenseWithIrrigation: number
  dtfyDenseNoIrrigation: number
  dtfySideStrip: number
  discount: number
  selectedShrubCoverage: string
  irrigationReLayout: boolean
  includeSideStrip: boolean
  showEnterPromoCode: boolean
  onClickStreetView: () => void
  onProjTypeValueChange: (x: ProjectTypeValue) => void
  onChangeBackyardPaverSize: (size: string) => void
  onChangeDrivewayPaverSize: (size: string) => void
  onChangeSelectedPackage: (pkg: string, price: string) => void
  onClickChangeAddress: () => void
  onChangeShrubCoverage: (sc: string) => void
  onChangeIrrigationRelayout: (irr: boolean) => void
  onChangeIncludeSideStrip: (ss: boolean) => void
  onClickEnterPromoCode: () => void
}

export const ProjectSummary = withWidth()(function (props: ProjectSummaryProps) {
  const theme = useTheme()
  const isSM = isWidthUp('sm', (props as any).width)
  const {
    title,
    addr,
    addr2,
    city,
    state,
    zipCode,
    streetViewURL,
    projTypeValue,
    selectedSize,
    selectedPackage,
    readonly,
    price,
    appreciation,
    backyardPaversRegularBase,
    backyardPaversRegularEntertainment,
    backyardPaversExtendedEntertainment,
    backyardPaversExtendedPremiumEntertainment,
    backyardPaversRegularSqft,
    backyardPaversExtendedSqft,
    dtfyRegularWithIrrigation,
    dtfyRegularNoIrrigation,
    dtfyDenseWithIrrigation,
    dtfyDenseNoIrrigation,
    dtfySideStrip,
    discount,
    selectedShrubCoverage,
    irrigationReLayout,
    includeSideStrip,
    showEnterPromoCode,
    onClickStreetView,
    onProjTypeValueChange,
    onChangeBackyardPaverSize,
    onChangeDrivewayPaverSize,
    onChangeSelectedPackage,
    onClickChangeAddress,
    onChangeShrubCoverage,
    onChangeIrrigationRelayout,
    onChangeIncludeSideStrip,
    onClickEnterPromoCode,
  } = props

  const isBPSP = projTypeValue === ProjectTypeValue.BackyardPavingStonePatio
  const isPSD = projTypeValue === ProjectTypeValue.PavingStoneDriveway
  const isDTFY = projTypeValue === ProjectTypeValue.DroughtTolerantFrontYard
  const prices =
    selectedSize === 'Cozy'
      ? [
          backyardPaversRegularBase ? `${formatPrice(backyardPaversRegularBase - discount)}` : '',
          backyardPaversRegularEntertainment
            ? `${formatPrice(backyardPaversRegularEntertainment - discount)}`
            : '',
          'N/A',
        ]
      : [
          'N/A',
          backyardPaversExtendedEntertainment
            ? `${formatPrice(backyardPaversExtendedEntertainment - discount)}`
            : '',
          backyardPaversExtendedPremiumEntertainment
            ? `${formatPrice(backyardPaversExtendedPremiumEntertainment - discount)}`
            : '',
        ]
  const projectSummaryAndPromo = (
    <Box display="flex" style={{marginTop: 30, marginBottom: 10}}>
      <ParagraphHeader>Project Summary</ParagraphHeader>
      <Box flexGrow={1}></Box>
      {discount ? null : (
        <Box style={{cursor: 'pointer'}} onClick={onClickEnterPromoCode}>
          <Typography
            style={{
              fontSize: '14px',
              lineHeight: '21px',
              fontWeight: 'bold',
              color: BRAND_YELLOW,
            }}>
            Enter Promo Code
          </Typography>
        </Box>
      )}
    </Box>
  )

  const px = bpNumber(theme, useMediaQuery, [20, 48, 96, 128, 144])

  return (
    <Box
      mx={isSM ? 0 : -2}
      style={{marginTop: bpNumber(theme, useMediaQuery, [0, 24, 32, 40, 40])}}>
      <Paper elevation={0} style={{borderRadius: isSM ? 8 : 0, backgroundColor: 'white'}}>
        <Box style={{paddingLeft: px, paddingRight: px, paddingBottom: isSM ? 50 : 30}}>
          <Box style={{paddingTop: isSM ? 30 : 17, paddingBottom: 20}}>
            <Typography
              align="center"
              style={{
                fontSize: bpString(theme, useMediaQuery, ['24px', '27px', '30px', '33px', '36px']),
                lineHeight: bpString(theme, useMediaQuery, [
                  '36px',
                  '40px',
                  '45px',
                  '48px',
                  '54px',
                ]),
                fontWeight: 300,
                color: '#333333',
                textAlign: 'center',
              }}>
              {title}
            </Typography>
          </Box>
          <ProjectType
            isSM={isSM}
            showDescription={true}
            value={projTypeValue}
            onChange={onProjTypeValueChange}
          />

          {isBPSP ? (
            <Box style={{marginTop: 30}}>
              <BackyardPaverProjectSizeOption
                isSM={isSM}
                final={readonly}
                selectedSize={selectedSize}
                regularSqft={backyardPaversRegularSqft}
                extendedSqft={backyardPaversExtendedSqft}
                onChange={onChangeBackyardPaverSize}
              />

              <Box style={{marginTop: 30}}>
                <BackyardPaverProjectPackageOption
                  isSM={isSM}
                  final={readonly}
                  selectedPackage={selectedPackage}
                  selectedSize={selectedSize}
                  prices={prices}
                  onChange={(pkg, price) => onChangeSelectedPackage(pkg, price)}
                />
              </Box>
              {projectSummaryAndPromo}
            </Box>
          ) : isPSD ? (
            <Box style={{marginTop: 30}}>
              <DrivewayPaverProjectSizeOption
                isSM={isSM}
                final={readonly}
                selectedSize={selectedSize}
                regularSqft={backyardPaversRegularSqft}
                extendedSqft={backyardPaversExtendedSqft}
                onChange={onChangeDrivewayPaverSize}
              />
              {projectSummaryAndPromo}
            </Box>
          ) : isDTFY ? (
            <Box style={{marginTop: 30}}>
              <ShrubCoverageOptions
                isSM={isSM}
                final={readonly}
                selectedShrubCoverage={selectedShrubCoverage}
                onChange={onChangeShrubCoverage}
              />

              {HideIrrigation ? null : (
                <Box style={{marginTop: 20}}>
                  <CheckBoxComp
                    checked={irrigationReLayout}
                    label="Install drip irrigation"
                    description="Choose this option if you would like to re-layout your exisitng irrigation system or install a new irrigation system"
                    isSM={isSM}
                    final={readonly}
                    onToggle={() => {
                      onChangeIrrigationRelayout(!irrigationReLayout)
                    }}
                  />
                </Box>
              )}
              <Box style={{marginTop: 20}}>
                <CheckBoxComp
                  checked={includeSideStrip}
                  label="Include parkstrip"
                  description="Choose this option if your house has a parkstrip and you want to include it in this project"
                  isSM={isSM}
                  final={readonly}
                  onToggle={() => {
                    onChangeIncludeSideStrip(!includeSideStrip)
                  }}
                />
              </Box>
              {projectSummaryAndPromo}
            </Box>
          ) : null}

          <BrickoProjectSummary
            addr={addr}
            addr2={addr2}
            city={city}
            state={state}
            zipCode={zipCode}
            streetViewURL={streetViewURL}
            projTypeValue={projTypeValue}
            selectedSize={selectedSize}
            selectedPackage={selectedPackage}
            readonly={readonly}
            price={price}
            appreciation={appreciation}
            shrubCoverage={selectedShrubCoverage}
            irrigationReLayout={irrigationReLayout}
            includeSideStrip={includeSideStrip}
            showEnterPromoCode={showEnterPromoCode}
            discount={discount}
            isSM={isSM}
            onClickStreetView={onClickStreetView}
            onClickChangeAddress={onClickChangeAddress}
            onClickEnterPromoCode={onClickEnterPromoCode}
          />

          <Box style={{marginTop: isSM ? 50 : 20}}>
            <GoodDeal isSM={isSM} />
          </Box>
          {isSM ? (
            <ProjectExamples
              mt={4}
              imageURLs={getImageURLs(projTypeValue)}
              tags={getImageTags(projTypeValue)}
            />
          ) : null}
        </Box>
      </Paper>
    </Box>
  )
})
