import {useState} from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import {BRAND_YELLOW} from '../../config/styles'
import {ParagraphHeader, ParagraphSubHeader} from './common'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    formControl: {
      // margin: theme.spacing(3),
      // fontSize: '14px',
    },
    formLabel: {
      fontSize: '14px',
      lineHeight: '21px',
    },
    formCheckbox: {
      padding: '4px',
      marginLeft: '5px',
      // color: BRAND_YELLOW,
    },
  }),
)

export enum SuggestProjectCheckboxName {
  BackyardPaving = 'backyardPaving',
  DesignerLandscape = 'designerLandscape',
  DroughtTolerantLawn = 'droughtTolerantLawn',
  KitchenRemodel = 'kitchenRemodel',
  BathroomRemodel = 'bathroomRemodel',
  TanklessWaterHeater = 'tanklessWaterHeater',
  WindowReplacement = 'windowReplacement',
}

export interface SuggestProjectsDialogModel {
  backyardPaving: boolean
  designerLandscape: boolean
  droughtTolerantLawn: boolean
  kitchenRemodel: boolean
  bathroomRemodel: boolean
  tanklessWaterHeater: boolean
  windowReplacement: boolean
  other: string
}

export function getBlankSuggestProjectsDialogModel(): SuggestProjectsDialogModel {
  return {
    backyardPaving: false,
    designerLandscape: false,
    droughtTolerantLawn: false,
    kitchenRemodel: false,
    bathroomRemodel: false,
    tanklessWaterHeater: false,
    windowReplacement: false,
    other: '',
  }
}

export interface SuggestProjectsDialogProps {
  open: boolean
  onClose: (model: SuggestProjectsDialogModel | null) => any
  onChange: (model: SuggestProjectsDialogModel) => any
  model: SuggestProjectsDialogModel
}

export function SuggestProjectsDialog(props: SuggestProjectsDialogProps) {
  const classes = useStyles()
  const {onClose, onChange, open, model} = props
  const {
    backyardPaving,
    designerLandscape,
    droughtTolerantLawn,
    kitchenRemodel,
    bathroomRemodel,
    tanklessWaterHeater,
    windowReplacement,
    other,
  } = model

  const dismiss = () => {
    onClose(null)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({...model, [event.target.name]: event.target.checked})
  }

  const setOther = (ot: string) => {
    onChange({...model, other: ot})
  }

  return (
    <Dialog onClose={dismiss} open={open}>
      <IconButton aria-label="close" className={classes.closeButton} onClick={dismiss}>
        <CloseIcon />
      </IconButton>
      <Box py={3} px={5} style={{maxWidth: 450}}>
        <Typography variant="h6" align="center" style={{fontWeight: 'bold', color: BRAND_YELLOW}}>
          Suggest Bricko Projects
        </Typography>
        <Box mt={3}>
          <ParagraphSubHeader>
            Which project(s) would you like to see Bricko offer?
          </ParagraphSubHeader>
        </Box>

        <Box mt={3}>
          <ParagraphHeader>Outdoor</ParagraphHeader>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={backyardPaving}
                    onChange={handleChange}
                    name={SuggestProjectCheckboxName.BackyardPaving}
                    className={classes.formCheckbox}
                    // style={{color: BRAND_YELLOW}}
                  />
                }
                label={<span className={classes.formLabel}>Backyard Paving</span>}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={designerLandscape}
                    onChange={handleChange}
                    name={SuggestProjectCheckboxName.DesignerLandscape}
                    className={classes.formCheckbox}
                  />
                }
                label={
                  <span className={classes.formLabel}>
                    Designer Landscape (Rethink your outdoor green space.)
                  </span>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={droughtTolerantLawn}
                    onChange={handleChange}
                    name={SuggestProjectCheckboxName.DroughtTolerantLawn}
                    className={classes.formCheckbox}
                  />
                }
                label={
                  <span className={classes.formLabel}>
                    Drought Tolerant Lawn (Replace grass with sustainable alternative)
                  </span>
                }
              />
            </FormGroup>
          </FormControl>
        </Box>

        <Box mt={3}>
          <ParagraphHeader>Indoor</ParagraphHeader>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={kitchenRemodel}
                    onChange={handleChange}
                    name={SuggestProjectCheckboxName.KitchenRemodel}
                    className={classes.formCheckbox}
                  />
                }
                label={<span className={classes.formLabel}>Kitchen Remodel</span>}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={bathroomRemodel}
                    onChange={handleChange}
                    name={SuggestProjectCheckboxName.BathroomRemodel}
                    className={classes.formCheckbox}
                  />
                }
                label={<span className={classes.formLabel}>Bathroom Remodel</span>}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={tanklessWaterHeater}
                    onChange={handleChange}
                    name={SuggestProjectCheckboxName.TanklessWaterHeater}
                    className={classes.formCheckbox}
                  />
                }
                label={<span className={classes.formLabel}>Tankless Water Heater</span>}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={windowReplacement}
                    onChange={handleChange}
                    name={SuggestProjectCheckboxName.WindowReplacement}
                    className={classes.formCheckbox}
                  />
                }
                label={<span className={classes.formLabel}>Window Replacement</span>}
              />
            </FormGroup>
          </FormControl>
        </Box>

        <Box mt={3}>
          <ParagraphHeader>Other</ParagraphHeader>
          <ParagraphSubHeader>
            Specify any other projects you'd be interested in, indoor or outdoor.
          </ParagraphSubHeader>
          <Box mt={1}>
            <TextareaAutosize
              aria-label="minimum height"
              rowsMin={5}
              placeholder=""
              value={other}
              style={{padding: '10px'}}
              onChange={(x) => {
                setOther(x.currentTarget.value)
              }}
            />
          </Box>
        </Box>

        <Box mt={4} style={{display: 'flex', justifyContent: 'center'}}>
          <Button
            size="large"
            onClick={() => onClose(model)}
            variant="contained"
            style={{backgroundColor: BRAND_YELLOW, color: 'white', textTransform: 'none'}}>
            OK!
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}
