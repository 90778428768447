import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withWidth, {isWidthUp} from '@material-ui/core/withWidth'
import {ParagraphSubHeader} from '../common/common'
import {AlertCircle} from 'react-feather'
import {PaverProjectConfigModel, PaverProjectConfigProps} from './project/paver/common'
import {PaverStyleSection} from './project/paver/PaverStyle'
import {PaverColorSection} from './project/paver/PaverColor'
import {PaverBorderColorSection} from './project/paver/PaverBorderColor'
import {PaverPatternSection} from './project/paver/PaverPattern'
import {useTheme} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {bpNumber, bpString} from '../../utils/styles'

export const PaverProjectConfigComp = withWidth()(function (props: PaverProjectConfigProps) {
  const theme = useTheme()
  const isMD = isWidthUp('md', (props as any).width)
  const isSM = isWidthUp('sm', (props as any).width)
  const px = isMD ? 40 : 20
  return (
    <Box
      mt={bpNumber(theme, useMediaQuery, [2, 3, 4, 5, 5])}
      mx={bpNumber(theme, useMediaQuery, [-2, 0, 0, 0, 0])}>
      <Paper elevation={0} style={{borderRadius: isMD ? 8 : 0, backgroundColor: 'white'}}>
        <Box
          style={{
            paddingLeft: px,
            paddingRight: px,
            paddingTop: 30,
            paddingBottom: isSM ? 50 : 30,
          }}>
          <Box>
            <Typography
              style={{
                fontSize: bpString(theme, useMediaQuery, ['24px', '27px', '30px', '33px', '36px']),
                lineHeight: bpString(theme, useMediaQuery, [
                  '36px',
                  '40px',
                  '45px',
                  '48px',
                  '54px',
                ]),
                fontWeight: 300,
                color: '#333333',
              }}
              align="center">
              Configuration
            </Typography>
          </Box>

          <Box display="flex" flexDirection="row" style={{marginTop: isSM ? 32 : 11}}>
            <Box>
              <AlertCircle />
            </Box>
            <Box style={{marginLeft: 6}}>
              <ParagraphSubHeader>
                Don’t worry, these choices will be reviewed with you and can be changed later. They
                don’t affect your upfront price.
              </ParagraphSubHeader>
            </Box>
          </Box>

          <Box style={{marginTop: 30}}>
            <PaverStyleSection
              style={props.style}
              isSM={isMD}
              final={props.final}
              projectType={props.projectType}
              onUpdate={props.onUpdate}
            />
          </Box>

          <Box style={{marginTop: 30}}>
            <PaverColorSection
              style={props.style}
              color={props.color}
              borderColor={props.borderColor}
              pattern={props.pattern}
              isSM={isMD}
              final={props.final}
              projectType={props.projectType}
              onUpdate={props.onUpdate}
            />
          </Box>

          <Box style={{marginTop: 30}}>
            <PaverBorderColorSection
              style={props.style}
              color={props.color}
              borderColor={props.borderColor}
              pattern={props.pattern}
              isSM={isMD}
              final={props.final}
              onUpdate={props.onUpdate}
            />
          </Box>

          <Box style={{marginTop: 30}}>
            <PaverPatternSection
              style={props.style}
              color={props.color}
              borderColor={props.borderColor}
              pattern={props.pattern}
              isSM={isMD}
              final={props.final}
              onUpdate={props.onUpdate}
            />
          </Box>
        </Box>
      </Paper>
    </Box>
  )
})
