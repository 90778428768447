import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import {WhatsIncludedContent} from '../../../common/WhatsIncluded'
import Link from '@material-ui/core/Link'
import {ProjectTypeValue} from '../../../../model/project'
import CircularProgress from '@material-ui/core/CircularProgress'

const DontShowSpinner = true

const LabelValue = ({label, value, isSM}: {label: string; value: string; isSM: boolean}) => (
  <Box display="flex">
    <Box width={isSM ? '70%' : '60%'} style={{paddingRight: 10}}>
      <Typography
        style={{
          fontSize: '12px',
          lineHeight: '18px',
          fontWeight: 300,
          color: '#333333',
        }}>
        {label}
      </Typography>
    </Box>
    <Box width={isSM ? '30%' : '40%'}>
      <Typography
        align={isSM ? 'right' : 'left'}
        style={{
          fontSize: '14px',
          lineHeight: '21px',
          fontWeight: 'bold',
          color: '#55c122',
        }}>
        {value}
      </Typography>
    </Box>
  </Box>
)

export interface BrickoProjectSummaryProps {
  addr: string
  addr2?: string
  city: string
  state: string
  zipCode: string
  price: string
  appreciation: string
  streetViewURL: string
  readonly: boolean
  projTypeValue: ProjectTypeValue
  selectedSize: string
  selectedPackage: string
  shrubCoverage: string
  irrigationReLayout: boolean
  includeSideStrip: boolean
  showEnterPromoCode: boolean
  discount: number
  isSM: boolean
  showTitle?: boolean
  onClickStreetView: () => void
  onClickChangeAddress: () => void
  onClickEnterPromoCode: () => void
}
export const BrickoProjectSummary = (props: BrickoProjectSummaryProps) => {
  const {
    addr,
    addr2,
    city,
    state,
    zipCode,
    streetViewURL,
    projTypeValue,
    selectedSize,
    selectedPackage,
    readonly,
    price,
    appreciation,
    shrubCoverage,
    irrigationReLayout,
    includeSideStrip,
    showEnterPromoCode,
    discount,
    isSM,
    showTitle,
    onClickStreetView,
    onClickChangeAddress,
    onClickEnterPromoCode,
  } = props
  const addrStr = addr
    ? `${addr}${addr2 ? ' ' + addr2 : ''}, ${city}, ${state} ${zipCode}`
    : 'Please enter your address'
  const changeAddrLink = (
    <Link
      component="button"
      style={{
        color: '#0085ff',
        fontSize: 14,
        backgroundColor: 'transparent',
        marginLeft: 12,
      }}
      onClick={(e: any) => {
        e.preventDefault()
        onClickChangeAddress()
      }}>
      <Typography
        style={{
          fontSize: '14px',
          lineHeight: '21px',
          fontWeight: 300,
          color: '#0085ff',
        }}>
        Change
      </Typography>
    </Link>
  )
  const streetViewImg = (
    <img
      style={{
        width: isSM ? 100 : 90,
        height: 60,
        objectFit: 'cover',
        cursor: 'pointer',
        borderRadius: 8,
      }}
      src={`${streetViewURL}&size=80x80`}
      onClick={onClickStreetView}
    />
  )

  const addrHeader = (
    <Box display="flex" flexDirection="row">
      <Typography style={{fontSize: '14px', lineHeight: '21px', fontWeight: 600}}>
        Address
      </Typography>
      {readonly ? null : changeAddrLink}
    </Box>
  )

  const addrTextComp = (
    <Typography style={{fontSize: '14px', lineHeight: '21px', fontWeight: 300}}>
      {addrStr}
    </Typography>
  )

  return (
    <Box style={{borderRadius: 8, boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 15px 0px'}}>
      <Box style={{padding: '32px 32px 0 32px'}}>
        {showTitle ? (
          <Box mb={5}>
            <Typography
              align="center"
              style={{color: '#333333', fontSize: '28px', lineHeight: '42px', fontWeight: 300}}>
              {projTypeValue}
            </Typography>
          </Box>
        ) : null}
        <Grid container spacing={0} justify="center">
          <Grid item sm={6} xs={12}>
            <Box
              style={{
                height: '100%',
                borderRight: isSM ? '1px solid #cccccc' : 'none',
              }}
              pb={isSM ? 1 : 3}
              display="flex"
              flexDirection="column"
              alignContent="center"
              justifyContent="center">
              {appreciation ? <Box style={{flexGrow: 1}}></Box> : null}
              <Box m="auto">
                {price || !addr || DontShowSpinner ? (
                  <Typography
                    variant="h4"
                    align="left"
                    style={{
                      color: '#55c122',
                      fontSize: 40,
                      lineHeight: '60px',
                      fontWeight: 'bold',
                    }}>
                    {price}
                  </Typography>
                ) : (
                  <Box
                    mb={1}
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                    }}>
                    <CircularProgress style={{color: '#55c122'}} />
                  </Box>
                )}

                <Typography
                  align="left"
                  style={{fontSize: 22, lineHeight: '33px', fontWeight: 300}}>
                  Upfront Price
                </Typography>
              </Box>
              {isSM && appreciation ? <Box style={{flexGrow: 1, minHeight: 10}}></Box> : null}

              {isSM && appreciation ? (
                <Box
                  style={{
                    width: '90%',
                    border: '1px solid #e5e5e5',
                    borderRadius: 10,
                    marginRight: 20,
                    padding: '10px',
                  }}>
                  {discount ? (
                    <LabelValue label="Discount applied" value={`$${discount}`} isSM={isSM} />
                  ) : null}
                  <Box style={{marginTop: 4}}>
                    <LabelValue
                      label="Estimated home value appreciation"
                      value={appreciation}
                      isSM={isSM}
                    />
                  </Box>
                </Box>
              ) : null}
            </Box>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Box>
              <WhatsIncludedContent
                projectType={projTypeValue}
                selectedSize={selectedSize}
                selectedPackage={selectedPackage}
                shrubCoverage={shrubCoverage}
                irrigationReLayout={irrigationReLayout}
                includeSideStrip={includeSideStrip}
              />
            </Box>
          </Grid>
        </Grid>

        <Box mt={3} mb={1}></Box>
      </Box>

      {isSM || !appreciation ? null : (
        <Box style={{width: '100%', borderTop: '1px solid #e5e5e5', padding: 16}}>
          {discount ? (
            <LabelValue label="Discount applied" value={`$${discount}`} isSM={isSM} />
          ) : null}
          <Box style={{marginTop: 4}}>
            <LabelValue
              label="Estimated home value appreciation"
              value={appreciation}
              isSM={isSM}
            />
          </Box>
        </Box>
      )}

      <Box
        display="flex"
        alignItems={isSM ? 'center' : 'flex-start'}
        px={isSM ? 5 : 2}
        py={2}
        style={{backgroundColor: '#f8f8f8'}}>
        {isSM
          ? [
              <Box>
                {addrHeader}
                {addrTextComp}
              </Box>,
              <Box style={{flexGrow: 1}}></Box>,
              streetViewURL ? <Box style={{height: 60, marginTop: 0}}>{streetViewImg}</Box> : null,
            ]
          : [
              <Box width="70%" style={{paddingRight: 10}}>
                {addrHeader}
                <Box>{addrTextComp}</Box>
              </Box>,
              streetViewURL ? <Box width="30%">{streetViewImg}</Box> : null,
            ]}
      </Box>
    </Box>
  )
}
