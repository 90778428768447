import Box, {BoxProps} from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import withWidth, {isWidthUp} from '@material-ui/core/withWidth'
import {RText36} from './RText'
import {useTheme} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {bpNumber} from '../../utils/styles'

export interface TitledPanelProps extends BoxProps {
  title: string
  narrow: boolean
}
export const TitledPanel = withWidth()(function (props: TitledPanelProps) {
  const {title, width, children, narrow, ...rest} = props
  const theme = useTheme()
  const isSM = isWidthUp('sm', props.width)
  const p = bpNumber(theme, useMediaQuery, [2, 6, 12, 17, 17])
  return (
    <Box {...rest}>
      <Box mt={bpNumber(theme, useMediaQuery, [0, 3, 4, 5, 5])}>
        <Paper elevation={0} style={{borderRadius: isSM ? 8 : 0}}>
          <Box px={p} py={4}>
            <Box pb={3}>
              <RText36
                align="center"
                style={{
                  fontWeight: 300,
                  color: '#333333',
                }}>
                {title}
              </RText36>
            </Box>
            <Box p={2}>{children}</Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  )
})
