import firebase from 'firebase/app'
import Logger from 'js-logger'
import {OrderModel, getOrderData, getDocID} from '../model/common'

import {getEventProjectType} from '../model/project'

const ORDERS_COLL = 'orders2'

// pre condition: model.userId is either not set or == userId
export const callSubmitOrderAPI = async (
  userId: string,
  model: OrderModel,
  onError: (msg: string) => void,
) => {
  const data = getOrderData(model)
  const docID = getDocID(model)
  firebase.analytics().logEvent('submit_order', {
    type: getEventProjectType(model.projectType),
    id: docID,
  })

  // we should not use merge: true here
  // because that way, optional field in old model will remain
  // (e.g., backyard patio was premium, downgrade to entertainment
  // if we use merge:true, the firepit and pergola config will remain)
  // on the other hand, to replace existing order,
  // we must fetch the full order object and only replace the part of the
  // current project type!
  try {
    await firebase
      .firestore()
      .collection(ORDERS_COLL)
      .doc(docID)
      .set(
        {
          ...data,
          userId,
        },
        {merge: true},
      )
    // success
    firebase.analytics().logEvent('order_api_success', {
      ...model,
      operation: 'create',
      id: docID,
    })
  } catch (err) {
    // fail
    Logger.info('Failed to create order:', err)
    firebase.analytics().logEvent('api_error', {
      ...model,
      operation: 'create_order',
      id: docID,
      error: err.toString(),
    })
    onError('Failed to create order, please try again later.')
  }
}
