import firebase from 'firebase/app'
import Logger from 'js-logger'
import {
  OrderModel,
  getDroughtTolerantFrontYardModelPrice,
  getDroughtTolerantFrontYardModelAppreciation,
  getBackyardPaverProjectModelPrice,
  getBackyardPaverProjectModelAppreciation,
  getDrivewayPaverProjectModelPrice,
  getDrivewayPaverProjectModelAppreciation,
} from '../model/common'

export const fetchPriceInfoMaybe = async (
  props: any,
  model: OrderModel,
  onModelUpdate: (mdl: OrderModel) => void,
) => {
  if (props && props.data && props.data.PropertySize && props.data.PropertyAddress) {
    const {AreaLotSF, AreaBuilding, Area2ndFloor, LotWidth, ParkingGarageArea} =
      props.data.PropertySize
    const {City: city, State: state} = props.data.PropertyAddress
    const lotSize = +AreaLotSF
    const lotWidth = +LotWidth
    const garageArea = +ParkingGarageArea
    let buildingArea = +AreaBuilding
    const area2F = +Area2ndFloor
    if (area2F) {
      buildingArea -= area2F
    }
    const priceInfo: any = await firebase.functions().httpsCallable('priceInformation')({
      lotSize,
      lotWidth,
      garageArea,
      buildingArea,
      city,
      state,
    })
    Logger.info('>>> got price information:', priceInfo, 'cached model:', model)

    const {
      drivewayPaversWithWalkwaySideStrip,
      drivewayPaversWithWalkwayNoSideStrip,
      drivewayPaversWithoutWalkwaySideStrip,
      drivewayPaversWithWalkwaySideStripExtended,
      drivewayPaversWithWalkwayNoSideStripExtended,
      drivewayPaversWithoutWalkwaySideStripExtended,
      drivewayPaversWithWalkwaySideStripAppreciation,
      drivewayPaversWithWalkwayNoSideStripAppreciation,
      drivewayPaversWithoutWalkwaySideStripAppreciation,
      drivewayPaversWithWalkwaySideStripExtendedAppreciation,
      drivewayPaversWithWalkwayNoSideStripExtendedAppreciation,
      drivewayPaversWithoutWalkwaySideStripExtendedAppreciation,
      drivewayPaversWithWalkwaySideStripSqft,
      drivewayPaversWithWalkwayNoSideStripSqft,
      drivewayPaversWithoutWalkwaySideStripSqft,
      drivewayPaversWithWalkwaySideStripExtendedSqft,
      drivewayPaversWithWalkwayNoSideStripExtendedSqft,
      drivewayPaversWithoutWalkwaySideStripExtendedSqft,
      backyardPaversRegularBase,
      backyardPaversRegularEntertainment,
      backyardPaversExtendedEntertainment,
      backyardPaversExtendedPremiumEntertainment,
      backyardPaversRegularBaseAppreciation,
      backyardPaversRegularEntertainmentAppreciation,
      backyardPaversExtendedEntertainmentAppreciation,
      backyardPaversExtendedPremiumEntertainmentAppreciation,
      backyardPaversRegularSqft,
      backyardPaversExtendedSqft,
      frontLayoutCategory,
      sideStripWidth,
      sideWalkWidth,
      dtfyRegularWithIrrigation,
      dtfyRegularNoIrrigation,
      dtfyDenseWithIrrigation,
      dtfyDenseNoIrrigation,
      dtfyRegularWithIrrigationAppreciation,
      dtfyRegularNoIrrigationAppreciation,
      dtfyDenseWithIrrigationAppreciation,
      dtfyDenseNoIrrigationAppreciation,
      dtfySideStrip,
      version,
    } = priceInfo.data
    let {drivewayPaverProject, backyardPaverProject, droughtTolerantFrontYardModel} = model

    drivewayPaverProject = {
      ...drivewayPaverProject,
      frontLayoutCategory,
      sideStripWidth,
      sideWalkWidth,
      drivewayPaversWithWalkwaySideStrip,
      drivewayPaversWithWalkwayNoSideStrip,
      drivewayPaversWithoutWalkwaySideStrip,
      drivewayPaversWithWalkwaySideStripExtended,
      drivewayPaversWithWalkwayNoSideStripExtended,
      drivewayPaversWithoutWalkwaySideStripExtended,
      drivewayPaversWithWalkwaySideStripAppreciation,
      drivewayPaversWithWalkwayNoSideStripAppreciation,
      drivewayPaversWithoutWalkwaySideStripAppreciation,
      drivewayPaversWithWalkwaySideStripExtendedAppreciation,
      drivewayPaversWithWalkwayNoSideStripExtendedAppreciation,
      drivewayPaversWithoutWalkwaySideStripExtendedAppreciation,
      drivewayPaversWithWalkwaySideStripSqft,
      drivewayPaversWithWalkwayNoSideStripSqft,
      drivewayPaversWithoutWalkwaySideStripSqft,
      drivewayPaversWithWalkwaySideStripExtendedSqft,
      drivewayPaversWithWalkwayNoSideStripExtendedSqft,
      drivewayPaversWithoutWalkwaySideStripExtendedSqft,
    }
    drivewayPaverProject.price = getDrivewayPaverProjectModelPrice({
      ...model,
      drivewayPaverProject,
    })
    drivewayPaverProject.appreciation =
      getDrivewayPaverProjectModelAppreciation(drivewayPaverProject)

    backyardPaverProject = {
      ...backyardPaverProject,
      backyardPaversRegularBase,
      backyardPaversRegularEntertainment,
      backyardPaversExtendedEntertainment,
      backyardPaversExtendedPremiumEntertainment,
      backyardPaversRegularBaseAppreciation,
      backyardPaversRegularEntertainmentAppreciation,
      backyardPaversExtendedEntertainmentAppreciation,
      backyardPaversExtendedPremiumEntertainmentAppreciation,
      backyardPaversRegularSqft,
      backyardPaversExtendedSqft,
    }
    backyardPaverProject.price = getBackyardPaverProjectModelPrice({
      ...model,
      backyardPaverProject,
    })
    backyardPaverProject.appreciation =
      getBackyardPaverProjectModelAppreciation(backyardPaverProject)

    droughtTolerantFrontYardModel = {
      ...droughtTolerantFrontYardModel,
      dtfyRegularWithIrrigation,
      dtfyRegularNoIrrigation,
      dtfyDenseWithIrrigation,
      dtfyDenseNoIrrigation,
      dtfyRegularWithIrrigationAppreciation,
      dtfyRegularNoIrrigationAppreciation,
      dtfyDenseWithIrrigationAppreciation,
      dtfyDenseNoIrrigationAppreciation,
      dtfySideStrip,
    }
    droughtTolerantFrontYardModel.price = getDroughtTolerantFrontYardModelPrice({
      ...model,
      droughtTolerantFrontYardModel,
    })
    droughtTolerantFrontYardModel.appreciation = getDroughtTolerantFrontYardModelAppreciation(
      droughtTolerantFrontYardModel,
    )

    const m = {
      ...model,
      drivewayPaverProject,
      backyardPaverProject,
      droughtTolerantFrontYardModel,
      lotSize,
      lotWidth,
      garageArea,
      buildingArea,
      version,
    }
    Logger.info('fetchPriceInfoMaybe, set model to', m)
    onModelUpdate(m)
  }
}
