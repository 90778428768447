import {
  PaverOptions,
  PaverColorOption,
  PaverPatternOption,
  PaverOption,
} from '../../../../model/pavers'

import {
  PaverWallConfigModel,
  PaverFirePitConfigModel,
  PergolaConfigModel,
} from '../../../../model/common'
import {ProjectTypeValue} from '../../../../model/project'

export interface PaverProjectConfigModel {
  vendor: string
  style: string
  color: string
  borderColor: string
  pattern: string
}
export function newPaverProjectConfigModel(): PaverProjectConfigModel {
  return {
    vendor: '',
    style: '',
    color: '',
    borderColor: '',
    pattern: '',
  }
}
export function isPaverProjectConfigModelValid(x: PaverProjectConfigModel): boolean {
  return x.vendor && x.style && x.color && x.borderColor && x.pattern ? true : false
}

export interface PaverProjectConfigProps extends PaverProjectConfigModel {
  onUpdate: (model: PaverProjectConfigModel) => void
  final: boolean
  projectType: ProjectTypeValue
}

export interface BackyardPaverProjectConfigModel extends PaverProjectConfigModel {
  wall?: PaverWallConfigModel
  firepit?: PaverFirePitConfigModel
  pergola?: PergolaConfigModel
  size: string
  package: string
}

export interface BackyardPaverProjectConfigProps extends BackyardPaverProjectConfigModel {
  onUpdate: (model: BackyardPaverProjectConfigModel) => void
  final: boolean
  projectType: ProjectTypeValue
}

export const getPaverOption = (style: string): PaverOption | null => {
  if (style) {
    const paverOpt = PaverOptions.find((po) => po.style === style)
    return paverOpt ? paverOpt : null
  }
  return null
}

export const getPaverColors = (style: string, filter: boolean): PaverColorOption[] => {
  const paverOpt = getPaverOption(style)
  if (paverOpt) {
    return filter ? paverOpt.colors.filter((c) => !c.borderOnly) : paverOpt.colors
  }
  return []
}

export const getPaverVendor = (style: string, color: string): string => {
  if (color) {
    const pco = getPaverColors(style, true).find((pc) => pc.name === color)
    return pco ? pco.vendor : ''
  }
  return ''
}

export const getPaverBorderColors = (style: string, color: string): PaverColorOption[] => {
  const paverOpt = getPaverOption(style)
  if (paverOpt) {
    const paverVendor = getPaverVendor(style, color)
    const retColors = paverOpt.colors.filter(
      (p) => p.vendor === paverVendor && (p.borderOK || p.borderOnly || p.name === color),
    )
    retColors.sort((a, b) => (a.name === color ? -1 : b.name === color ? 1 : 0))
    return retColors
  }
  return []
}
export const getPaverPatterns = (style: string, color: string): PaverPatternOption[] => {
  const paverOpt = getPaverOption(style)
  if (paverOpt) {
    const paverVendor = getPaverVendor(style, color)
    return paverOpt.patterns.filter((p) => p.vendors.indexOf(paverVendor) !== -1)
  }
  return []
}

export const getPaverModel = (
  style: string,
  color: string,
  borderColor: string,
  pattern: string,
): PaverProjectConfigModel => {
  let vendor = ''

  if (style) {
    const paverOpt = getPaverOption(style)
    if (paverOpt) {
      if (color) {
        const pco = getPaverColors(style, true).find((pc) => pc.name === color)
        if (pco) {
          vendor = pco.vendor
        }
      }
    }
  }
  return {
    vendor,
    style,
    color,
    borderColor,
    pattern,
  }
}
