export const UseGooglePlacesAutoComplete = true
export const HidePergola = true

export interface CityState {
  city: string
  state: string
}

// master doc in
// https://docs.google.com/spreadsheets/d/1GmNPZDcHy8lmDOV3ZO1u4b7vS388pqGQB6PndjHEry4/edit#gid=1984548819
//
// This is now only used as the default value.
// The actual value comes from
// Firebase remote config product_config property, attribute supported_cities
export const SupportedCityStates: CityState[] = [
  {city: 'San Jose', state: 'CA'},
  {city: 'Cupertino', state: 'CA'},
  {city: 'Santa Clara', state: 'CA'},
  {city: 'Campbell', state: 'CA'},
  {city: 'Saratoga', state: 'CA'},
  {city: 'Los Gatos', state: 'CA'},
  {city: 'Sunnyvale', state: 'CA'},
  {city: 'Mountain View', state: 'CA'},
  {city: 'Los Altos', state: 'CA'},
  {city: 'Palo Alto', state: 'CA'},
  {city: 'Menlo Park', state: 'CA'},
  {city: 'Atherton', state: 'CA'},
  {city: 'Redwood City', state: 'CA'},
  {city: 'Milpitas', state: 'CA'},
  {city: 'Fremont', state: 'CA'},
  {city: 'Pleasanton', state: 'CA'},
  {city: 'Dublin', state: 'CA'},
  {city: 'Hayward', state: 'CA'},
  {city: 'Oakland', state: 'CA'},
  {city: 'San Ramon', state: 'CA'},
  {city: 'San Carlos', state: 'CA'},
  {city: 'Belmont', state: 'CA'},
  {city: 'San Mateo', state: 'CA'},
  {city: 'Foster City', state: 'CA'},
  {city: 'Hillsborough', state: 'CA'},
  {city: 'Burlingame', state: 'CA'},
  {city: 'Millbrae', state: 'CA'},
  {city: 'San Bruno', state: 'CA'},
]
