import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import {BRAND_YELLOW} from '../../config/styles'

export const SubmitButton = ({onClick, disabled}: {disabled: boolean; onClick: () => any}) => {
  return (
    <Box mt={5} display="flex">
      <Box m="auto">
        <Button
          size="large"
          onClick={onClick}
          disabled={disabled}
          style={{backgroundColor: disabled ? '#cccccc' : BRAND_YELLOW, color: 'white'}}
          variant="contained">
          Submit
        </Button>
      </Box>
    </Box>
  )
}
