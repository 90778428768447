import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import withWidth, {isWidthUp} from '@material-ui/core/withWidth'
import {ParagraphHeader, ParagraphSubHeader} from '../common/common'
import {getMinDate, getMaxDate, AppointmentModel, AppointmentState} from '../../model/common'
import {RText36} from '../../components/common/RText'
import {TDIRadioOptionComponent} from './project/common/TDIRadioOptionComponent'
import {TDIOptionComponent} from './project/common/TDIOptionComponent'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'

const showPaymentSection = false
const noop = () => {
  // no op
}

// only needed on iPhone where date bounds are not honored by TextField
function boundDay(day: string, min: string, max: string): string {
  const minMS = Date.parse(min)
  const maxMS = Date.parse(max)
  const dayMS = Date.parse(day)
  if (dayMS < minMS) {
    alert('Day must be after ' + min)
    return min
  }
  if (dayMS > maxMS) {
    alert('Day must be before ' + max)
    return max
  }
  return day
}

const styles = (theme: Theme) =>
  createStyles({
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  })
const useStyles = makeStyles(styles)

export interface AppointmentProps extends AppointmentModel {
  onUpdate: (model: AppointmentModel) => void
  final: boolean
}
export const Appointment = withWidth()(function (props: AppointmentProps) {
  const classes = useStyles()
  const isSM = isWidthUp('sm', (props as any).width)
  const {name, email, phone, day, ampm, final, appointmentState, comments, onUpdate} = props

  const getModel = (): AppointmentModel => {
    const {name, phone, email, day, ampm, appointmentSet, appointmentState, comments} = props
    return {
      name,
      phone,
      email,
      day,
      ampm,
      appointmentSet,
      appointmentState,
      comments,
    }
  }

  const commentsSelected = appointmentState === AppointmentState.Comments
  const appointmentSelected = appointmentState === AppointmentState.Appointment

  const commentsName = 'Enter My Comments Now'
  const commentsDesc =
    'Use the text field below to describe any thoughts or ideas you have about this project. We will take them into account and email a detailed design to you within 2 business days.'
  const apptName = 'In Person Appointment'
  const apptDesc = 'We’ll meet at your home to discuss the project, answer any questions, etc.'

  const onSetAppointmentState = (x: AppointmentState) => {
    const m = getModel()
    onUpdate({...m, appointmentState: x})
  }
  const onSetComments = (x: string) => {
    const m = getModel()
    onUpdate({...m, comments: x})
  }

  const px = isSM ? 144 : 20

  const min = getMinDate()
  const max = getMaxDate()

  return (
    <Box mt={isSM ? 5 : 2} mx={isSM ? 0 : -2}>
      <Paper elevation={0} style={{borderRadius: isSM ? 8 : 0, backgroundColor: 'white'}}>
        <Box style={{paddingTop: 30, paddingBottom: 30, paddingLeft: px, paddingRight: px}}>
          <Box>
            <RText36
              style={{
                fontWeight: 300,
                color: '#333333',
              }}
              align="center">
              Detailed Design
            </RText36>
          </Box>
          <Box style={{marginTop: 30}}>
            <ParagraphHeader variant="h6">Project Details & Requirements</ParagraphHeader>
          </Box>
          <Box>
            <ParagraphSubHeader>
              In order to produce a detailed design for your renovation, we want to hear your
              thoughts and any considerations you have for this project.
              <p>How would you like to proceed?</p>
            </ParagraphSubHeader>
          </Box>
          <Box
            display="flex"
            flexDirection={isSM ? 'row' : 'column'}
            justifyContent="space-between"
            style={{marginBottom: isSM ? 20 : 0}}>
            {isSM ? (
              <TDIOptionComponent
                selected={commentsSelected}
                onClick={() => onSetAppointmentState(AppointmentState.Comments)}
                isSM={isSM}
                final={false}
                image={''}
                alt={''}
                onClickImage={noop}
                name={commentsName}
                description={commentsDesc}
                width="47%"
              />
            ) : (
              <TDIRadioOptionComponent
                selected={commentsSelected}
                onClick={() => onSetAppointmentState(AppointmentState.Comments)}
                isSM={isSM}
                final={false}
                imageURL={''}
                alt={''}
                onClickImage={noop}
                name={commentsName}
                description={commentsDesc}
                price={''}
                disabled={false}
                disabledText={''}
              />
            )}
            {isSM ? (
              <TDIOptionComponent
                selected={appointmentSelected}
                onClick={() => onSetAppointmentState(AppointmentState.Appointment)}
                isSM={isSM}
                final={false}
                image={''}
                alt={''}
                onClickImage={noop}
                name={apptName}
                description={apptDesc}
                width="47%"
              />
            ) : (
              <TDIRadioOptionComponent
                selected={appointmentSelected}
                onClick={() => onSetAppointmentState(AppointmentState.Appointment)}
                isSM={isSM}
                final={false}
                imageURL={''}
                alt={''}
                onClickImage={noop}
                name={apptName}
                description={apptDesc}
                price={''}
                disabled={false}
                disabledText={''}
              />
            )}
          </Box>

          {commentsSelected ? (
            <Box style={{marginTop: 20, marginBottom: isSM ? 10 : 0}}>
              <ParagraphSubHeader>Project Comments</ParagraphSubHeader>
              <Box mt={1}>
                <TextareaAutosize
                  aria-label="minimum height"
                  rowsMin={8}
                  placeholder=""
                  value={comments}
                  style={{padding: '10px'}}
                  onChange={(x) => {
                    onSetComments(x.currentTarget.value)
                  }}
                />
              </Box>
            </Box>
          ) : null}

          {appointmentSelected ? (
            <Box mt={2}>
              <ParagraphSubHeader>
                Choose a date and time for your home consultation appointment. This will take
                approximately 30 minutes.
              </ParagraphSubHeader>
            </Box>
          ) : null}

          {appointmentSelected ? (
            <Box mt={3} mb={5}>
              <ParagraphHeader variant="h6">Appointment Details</ParagraphHeader>
              <Box ml={4} mt={-2}>
                <ul>
                  {[
                    'We will review, discuss and finalize your design choices',
                    'You will choose a time for construction to begin',
                    'You are not committed to the project until this appointment is finished and you give us the go ahead!',
                  ].map((t, i) => (
                    <li key={i} className="browser-default" style={{listStyleType: 'disc'}}>
                      <span style={{fontSize: '14px', lineHeight: '21px', fontWeight: 300}}>
                        {t}
                      </span>
                    </li>
                  ))}
                </ul>
              </Box>
            </Box>
          ) : null}
          <Grid className={classes.sectionDesktop} container spacing={2} justify="center">
            {appointmentSelected ? (
              <Grid item xs={6}>
                <TextField
                  style={{width: '100%'}}
                  id="date"
                  label="Appointment Date"
                  type="date"
                  InputProps={{inputProps: {min, max}}}
                  variant="outlined"
                  value={day}
                  onChange={(x) => {
                    const d = boundDay(x.currentTarget.value, min, max)
                    const m = getModel()
                    onUpdate({...m, day: d})
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  disabled={final}
                />
              </Grid>
            ) : null}
            {appointmentSelected ? (
              <Grid item xs={6}>
                <FormControl style={{marginLeft: 0, width: '100%'}} className={classes.formControl}>
                  <Select
                    labelId="demo-simple-select-desktop-label"
                    id="demo-simple-select-desktop"
                    variant="outlined"
                    value={ampm}
                    style={{height: '60px'}}
                    disabled={final}
                    onChange={(x) => {
                      const ap = x.target.value as string
                      const m = getModel()
                      onUpdate({...m, ampm: ap})
                    }}>
                    <MenuItem value="AM">8AM-12PM</MenuItem>
                    <MenuItem value="PM">1PM-5PM</MenuItem>
                  </Select>
                  <InputLabel
                    style={{
                      paddingTop: 4,
                      paddingLeft: 6,
                      paddingRight: 6,
                      marginTop: -10,
                      marginBottom: 10,
                      marginLeft: 8,
                      backgroundColor: 'white',
                    }}
                    required
                    id="demo-simple-select-desktop-label">
                    Time
                  </InputLabel>
                </FormControl>
              </Grid>
            ) : null}
          </Grid>
          <Grid className={classes.sectionMobile} container spacing={0} justify="center">
            {appointmentSelected ? (
              <Grid item xs={12}>
                <TextField
                  style={{width: '100%'}}
                  id="date-mobile"
                  label="Appointment Date"
                  type="date"
                  InputProps={{inputProps: {min, max}}}
                  variant="outlined"
                  value={day}
                  onChange={(x) => {
                    const d = boundDay(x.currentTarget.value, min, max)
                    const m = getModel()
                    onUpdate({...m, day: d})
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  disabled={final}
                />
              </Grid>
            ) : null}
            {appointmentSelected ? (
              <Grid item xs={12}>
                <Box mt={1}>
                  <FormControl className={classes.formControl} style={{width: '100%'}}>
                    <Select
                      labelId="demo-simple-select-mobile-label"
                      id="demo-simple-select-mobile"
                      variant="outlined"
                      value={ampm}
                      disabled={final}
                      onChange={(x) => {
                        const ap = x.target.value as string
                        const m = getModel()
                        onUpdate({...m, ampm: ap})
                      }}>
                      <MenuItem value="AM">8AM-12PM</MenuItem>
                      <MenuItem value="PM">1PM-5PM</MenuItem>
                    </Select>
                    <InputLabel
                      style={{
                        paddingTop: 4,
                        paddingLeft: 6,
                        paddingRight: 6,
                        marginTop: -10,
                        marginBottom: 10,
                        marginLeft: 8,
                        backgroundColor: 'white',
                      }}
                      required
                      id="demo-simple-select-mobile-label">
                      Time
                    </InputLabel>
                  </FormControl>
                </Box>
              </Grid>
            ) : null}
          </Grid>

          <Box style={{marginTop: 20}}>
            <TextField
              style={{width: '100%'}}
              id="name"
              label="Name"
              variant="outlined"
              value={name}
              onChange={(x) => {
                const n = x.currentTarget.value
                const m = getModel()
                onUpdate({...m, name: n})
              }}
              required
              disabled={final}
            />
          </Box>

          <Grid container spacing={isSM ? 2 : 0} justify="center">
            <Grid item xs={isSM ? 6 : 12}>
              <Box mt={2}>
                <TextField
                  style={{width: '100%'}}
                  id="email"
                  label="Email"
                  variant="outlined"
                  value={email}
                  onChange={(x) => {
                    const em = x.currentTarget.value
                    const m = getModel()
                    onUpdate({...m, email: em})
                  }}
                  required
                  disabled={final}
                />
              </Box>
            </Grid>
            <Grid item xs={isSM ? 6 : 12}>
              <Box mt={2}>
                <TextField
                  style={{width: '100%'}}
                  id="phone"
                  label="Phone Number"
                  variant="outlined"
                  value={phone}
                  onChange={(x) => {
                    const ph = x.currentTarget.value
                    const m = getModel()
                    onUpdate({...m, phone: ph})
                  }}
                  required
                  disabled={final}
                />
              </Box>
            </Grid>
          </Grid>

          {showPaymentSection ? (
            <Box mt={4} mb={5}>
              <ParagraphHeader>Payment</ParagraphHeader>
              <Box>
                <ParagraphSubHeader>
                  After you give us the go ahead during the home construction appointment we will
                  collect a deposit of $1000.
                </ParagraphSubHeader>
              </Box>
              <Box mt={2}>
                <ParagraphSubHeader>
                  The rest of the balance will be collected in three equal installments. One when
                  project construction begins (to cover materials), one midway through the project
                  (to compensate our pros for progress made as well as cover any remaining material
                  costs), and a final one upon completion.
                </ParagraphSubHeader>
              </Box>
              <Box mt={2}>
                <ParagraphSubHeader>We currently accept payment by check.</ParagraphSubHeader>
              </Box>
            </Box>
          ) : (
            <Box mb={5}></Box>
          )}
        </Box>
      </Paper>
    </Box>
  )
})
