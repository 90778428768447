import firebase from 'firebase/app'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import {SubmitButton} from './SubmitButton'
import {Appointment} from './Appointment'
import {Header} from './Header'
import {ProjectSummary} from './ProjectSummary'
import {PaverProjectConfigComp} from './ProjectConfigComp'
import {BackyardPaverProjectConfigComp} from './BackyardPaverProjectConfigComp'
import {PaverProjectConfigModel} from './project/paver/common'
import {HEADER_HEIGHT, MOBILE_HOME_HEADER_HEIGHT} from '../../config/styles'
import withWidth, {isWidthUp} from '@material-ui/core/withWidth'
import {
  OrderModel,
  BackyardPaverProjectModel,
  PaverConfigModel,
  getOrderProjectModel,
  ShrubCoverage,
  AppointmentModel,
  DroughtTolerantFrontYardModel,
  getDroughtTolerantFrontYardModelPrice,
  getDroughtTolerantFrontYardModelAppreciation,
  getBackyardPaverProjectModelPrice,
  getBackyardPaverProjectModelAppreciation,
  getDrivewayPaverProjectModelPrice,
  getDrivewayPaverProjectModelAppreciation,
  sanitizeBackyardPaverConfigModel,
} from '../../model/common'
import {backyardPaverProjectPackages} from '../../model/pavers'

import {ProjectTypeValue} from '../../model/project'
import Logger from 'js-logger'

export interface ShopCompProps {
  readonly: boolean
  model: OrderModel
  pageTitle: string
  streetViewURL: string
  showEnterPromoCode: boolean
  inputValid: boolean
  onProjTypeValueChange: (projectType: ProjectTypeValue) => void
  onClickStreetView: () => void
  onClickChangeAddress: () => void
  onClickEnterPromoCode: () => void
  onSubmit: () => any
  onModelUpdate: (mdl: OrderModel) => void
}
export const ShopComp = withWidth()(function (props: ShopCompProps) {
  const isSM = isWidthUp('sm', (props as any).width)
  const {
    readonly,
    model,
    pageTitle,
    streetViewURL,
    showEnterPromoCode,
    inputValid,
    onProjTypeValueChange,
    onClickStreetView,
    onClickChangeAddress,
    onClickEnterPromoCode,
    onSubmit,
    onModelUpdate,
  } = props

  const projModel = getOrderProjectModel(model)

  const onPaverProjectConfigCompUpdate = (x: PaverProjectConfigModel) => {
    const drivewayPaverProject = model.drivewayPaverProject
    const m = {
      ...model,
      drivewayPaverProject: {...drivewayPaverProject, ...x},
    }
    Logger.info('project config update, set model to', m)
    onModelUpdate(m)
    firebase.analytics().logEvent('paver_config_selection_update', {
      ...x,
      page: 'shop',
    })
  }

  const onBackyardPaverProjectConfigCompUpdate = (x: PaverProjectConfigModel) => {
    const backyardPaverProject = model.backyardPaverProject
    const m = {
      ...model,
      backyardPaverProject: {...backyardPaverProject, ...x},
    }
    Logger.info('project config update, set model to', m)
    onModelUpdate(m)
    firebase.analytics().logEvent('paver_config_selection_update', {
      ...x,
      page: 'shop',
    })
  }

  const updateDroughtTolerantFrontYardModelMaybe = (dtfyModel: any) => {
    if (model.projectType === ProjectTypeValue.DroughtTolerantFrontYard) {
      const droughtTolerantFrontYardModel: DroughtTolerantFrontYardModel = {
        ...model.droughtTolerantFrontYardModel,
        ...dtfyModel,
      }
      droughtTolerantFrontYardModel.price = getDroughtTolerantFrontYardModelPrice({
        ...model,
        droughtTolerantFrontYardModel,
      })
      droughtTolerantFrontYardModel.appreciation = getDroughtTolerantFrontYardModelAppreciation(
        droughtTolerantFrontYardModel,
      )
      const m: OrderModel = {
        ...model,
        droughtTolerantFrontYardModel,
      }
      Logger.info('change shrub coverage, set model to', m)
      onModelUpdate(m)
    }
  }

  const onChangeSelectedSize = (size: string) => {
    // this function is only used for project type = driveway paver or backyard paver
    if (model.projectType === ProjectTypeValue.BackyardPavingStonePatio) {
      const backyardPaverProject = {...model.backyardPaverProject, size}
      let pkg = backyardPaverProject.package
      // if size changed to Cozy and package is extended only,
      // then deselect package
      if (size !== 'Extended') {
        if (pkg) {
          const p = backyardPaverProjectPackages.find((p) => p.name === pkg)
          if (p && p.extendedOnly) {
            pkg = 'Entertainment'
          }
        }
      } else {
        if (pkg) {
          const p = backyardPaverProjectPackages.find((p) => p.name === pkg)
          if (p && p.basicOnly) {
            pkg = 'Entertainment'
          }
        }
      }
      backyardPaverProject.package = pkg
      backyardPaverProject.price = getBackyardPaverProjectModelPrice({
        ...model,
        backyardPaverProject,
      })
      backyardPaverProject.appreciation =
        getBackyardPaverProjectModelAppreciation(backyardPaverProject)
      sanitizeBackyardPaverConfigModel(backyardPaverProject)
      const m: OrderModel = {
        ...model,
        backyardPaverProject,
      }
      Logger.info('change backyard pavers selected size, set model to', m)
      onModelUpdate(m)
    } else {
      // driveway paver
      const drivewayPaverProject = {...model.drivewayPaverProject, size}
      drivewayPaverProject.price = getDrivewayPaverProjectModelPrice({
        ...model,
        drivewayPaverProject,
      })
      drivewayPaverProject.appreciation =
        getDrivewayPaverProjectModelAppreciation(drivewayPaverProject)
      const m: OrderModel = {
        ...model,
        drivewayPaverProject,
      }
      Logger.info('change driveway pavers selected size, set model to', m)
      onModelUpdate(m)
    }
  }

  const onChangeSelectedPackage = (pkg: string, price: string) => {
    if (model.projectType === ProjectTypeValue.BackyardPavingStonePatio) {
      const backyardPaverProject = {
        ...model.backyardPaverProject,
        price,
        package: pkg,
      }
      backyardPaverProject.appreciation =
        getBackyardPaverProjectModelAppreciation(backyardPaverProject)
      sanitizeBackyardPaverConfigModel(backyardPaverProject)
      const m: OrderModel = {
        ...model,
        backyardPaverProject,
      }
      Logger.info('change selected package, set model to', m)
      onModelUpdate(m)
    }
  }

  const onAppointmentUpdate = (x: AppointmentModel) => {
    let m: OrderModel | null = null
    const {name, email, phone, day, ampm, appointmentSet, appointmentState, comments} = x
    switch (model.projectType) {
      case ProjectTypeValue.PavingStoneDriveway:
        const drivewayPaverProject = model.drivewayPaverProject
        m = {
          ...model,
          name,
          email,
          phone,
          drivewayPaverProject: {
            ...drivewayPaverProject,
            day,
            ampm,
            appointmentState,
            comments,
          },
        }
        break
      case ProjectTypeValue.BackyardPavingStonePatio:
        const backyardPaverProject = model.backyardPaverProject
        m = {
          ...model,
          name,
          email,
          phone,
          backyardPaverProject: {
            ...backyardPaverProject,
            day,
            ampm,
            appointmentState,
            comments,
          },
        }
        break
      case ProjectTypeValue.DroughtTolerantFrontYard:
        const droughtTolerantFrontYardModel = model.droughtTolerantFrontYardModel
        m = {
          ...model,
          name,
          email,
          phone,
          droughtTolerantFrontYardModel: {
            ...droughtTolerantFrontYardModel,
            day,
            ampm,
            appointmentState,
            comments,
          },
        }
        break
      default:
        throw Error(`Appointment update, unsupported project type ${model.projectType}?`)
    }

    if (m) {
      Logger.info('appointment model update set model to', m)
      onModelUpdate(m)
      firebase.analytics().logEvent('paver_config_contact_update', {
        ...x,
        page: 'shop',
      })
    }
  }

  return (
    <Box>
      <Header />

      <form
        style={{
          backgroundColor: '#f5f5f5',
          paddingBottom: '40px',
          width: '100%',
          marginTop: isSM ? HEADER_HEIGHT : MOBILE_HOME_HEADER_HEIGHT,
        }}>
        <Container>
          <Grid container spacing={0} justify="center">
            <Grid item lg={9} md={9} sm={11} xs={12}>
              <ProjectSummary
                title={pageTitle}
                addr={model.addr}
                addr2={model.addr2}
                city={model.city}
                state={model.state}
                zipCode={model.zipCode}
                price={projModel.price}
                appreciation={projModel.appreciation}
                streetViewURL={streetViewURL}
                readonly={projModel.final}
                projTypeValue={model.projectType}
                selectedSize={(projModel as BackyardPaverProjectModel).size}
                selectedPackage={(projModel as BackyardPaverProjectModel).package}
                backyardPaversRegularBase={
                  (projModel as BackyardPaverProjectModel).backyardPaversRegularBase
                }
                backyardPaversRegularEntertainment={
                  (projModel as BackyardPaverProjectModel).backyardPaversRegularEntertainment
                }
                backyardPaversExtendedEntertainment={
                  (projModel as BackyardPaverProjectModel).backyardPaversExtendedEntertainment
                }
                backyardPaversExtendedPremiumEntertainment={
                  (projModel as BackyardPaverProjectModel)
                    .backyardPaversExtendedPremiumEntertainment
                }
                backyardPaversRegularSqft={
                  (projModel as BackyardPaverProjectModel).backyardPaversRegularSqft
                }
                backyardPaversExtendedSqft={
                  (projModel as BackyardPaverProjectModel).backyardPaversExtendedSqft
                }
                dtfyRegularWithIrrigation={
                  (projModel as DroughtTolerantFrontYardModel).dtfyRegularWithIrrigation
                }
                dtfyRegularNoIrrigation={
                  (projModel as DroughtTolerantFrontYardModel).dtfyRegularNoIrrigation
                }
                dtfyDenseWithIrrigation={
                  (projModel as DroughtTolerantFrontYardModel).dtfyDenseWithIrrigation
                }
                dtfyDenseNoIrrigation={
                  (projModel as DroughtTolerantFrontYardModel).dtfyDenseNoIrrigation
                }
                dtfySideStrip={(projModel as DroughtTolerantFrontYardModel).dtfySideStrip}
                discount={model.discount}
                selectedShrubCoverage={(projModel as DroughtTolerantFrontYardModel).shrubCoverage}
                irrigationReLayout={(projModel as DroughtTolerantFrontYardModel).irrigationReLayout}
                includeSideStrip={(projModel as DroughtTolerantFrontYardModel).includeSideStrip}
                showEnterPromoCode={showEnterPromoCode}
                onProjTypeValueChange={onProjTypeValueChange}
                onClickStreetView={onClickStreetView}
                onChangeBackyardPaverSize={onChangeSelectedSize}
                onChangeDrivewayPaverSize={onChangeSelectedSize}
                onChangeSelectedPackage={onChangeSelectedPackage}
                onClickChangeAddress={onClickChangeAddress}
                onChangeShrubCoverage={(shrubCoverage: string) => {
                  updateDroughtTolerantFrontYardModelMaybe({
                    shrubCoverage: shrubCoverage as ShrubCoverage,
                  })
                }}
                onChangeIrrigationRelayout={(irrigationReLayout: boolean) => {
                  updateDroughtTolerantFrontYardModelMaybe({
                    irrigationReLayout,
                  })
                }}
                onChangeIncludeSideStrip={(includeSideStrip: boolean) => {
                  updateDroughtTolerantFrontYardModelMaybe({
                    includeSideStrip,
                  })
                }}
                onClickEnterPromoCode={onClickEnterPromoCode}
              />
              {model.projectType === ProjectTypeValue.BackyardPavingStonePatio ? (
                <BackyardPaverProjectConfigComp
                  style={(projModel as PaverConfigModel).style}
                  color={(projModel as PaverConfigModel).color}
                  vendor={(projModel as PaverConfigModel).vendor}
                  borderColor={(projModel as PaverConfigModel).borderColor}
                  pattern={(projModel as PaverConfigModel).pattern}
                  final={projModel.final}
                  projectType={model.projectType}
                  wall={(projModel as BackyardPaverProjectModel).wall}
                  firepit={(projModel as BackyardPaverProjectModel).firepit}
                  pergola={(projModel as BackyardPaverProjectModel).pergola}
                  size={(projModel as BackyardPaverProjectModel).size}
                  package={(projModel as BackyardPaverProjectModel).package}
                  onUpdate={onBackyardPaverProjectConfigCompUpdate}
                />
              ) : model.projectType === ProjectTypeValue.PavingStoneDriveway ? (
                <PaverProjectConfigComp
                  style={(projModel as PaverConfigModel).style}
                  color={(projModel as PaverConfigModel).color}
                  vendor={(projModel as PaverConfigModel).vendor}
                  borderColor={(projModel as PaverConfigModel).borderColor}
                  pattern={(projModel as PaverConfigModel).pattern}
                  final={projModel.final}
                  projectType={model.projectType}
                  onUpdate={onPaverProjectConfigCompUpdate}
                />
              ) : null}

              <Appointment
                day={projModel.day}
                ampm={projModel.ampm}
                name={model.name}
                email={model.email}
                phone={model.phone}
                final={projModel.final}
                comments={projModel.comments}
                appointmentSet={projModel.appointmentSet}
                appointmentState={projModel.appointmentState}
                onUpdate={onAppointmentUpdate}
              />
            </Grid>
          </Grid>
          {readonly || projModel.final ? null : (
            <SubmitButton onClick={onSubmit} disabled={!inputValid} />
          )}
        </Container>
      </form>
    </Box>
  )
})
