import {useEffect, useRef} from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import {BRAND_YELLOW} from '../../config/styles'

export default function LandscapeDesign(props: any) {
  const canvasRef = useRef<any>(null)
  const levenderRef = useRef<any>(null)
  const africanIrisRef = useRef<any>(null)
  const blueWildRyeRef = useRef<any>(null)
  const geraniumRef = useRef<any>(null)
  // const indianHawthornRef = useRef<any>(null)
  const wildStrawberryRef = useRef<any>(null)
  const euryopsRef = useRef<any>(null)
  const canvasWidth = window.innerWidth - 112
  const canvasHeight = 688 // For: 35444 Woodbridge Pl
  // const canvasHeight = 750 // For: 6702 mt leneve dr

  // ctx.filter = 'grayscale(1)'
  // ctx.drawImage(imageRef.current, 500, 300, 100, 100)

  // For: 35444 Woodbridge Pl
  // const drawImage_35444_Woodbridge = () => {
  const drawImage = () => {
    const LL = 109
    const MM = 70
    const SS = 55

    const canvasObj = canvasRef.current
    const ctx = canvasObj.getContext('2d')
    ctx.clearRect(0, 0, canvasWidth, canvasHeight)
    ctx.fillStyle = '#cec3bf'
    ctx.fillRect(0, 70, canvasWidth, canvasHeight)

    ctx.drawImage(euryopsRef.current, 120, 120, LL, LL)
    ctx.drawImage(euryopsRef.current, 460, 460, LL, LL)

    const CC = 310
    ctx.drawImage(geraniumRef.current, CC, CC, MM, MM)
    const dd = 80
    const dd2 = 200
    ctx.drawImage(geraniumRef.current, CC + dd2, CC - dd, MM, MM)
    ctx.drawImage(geraniumRef.current, CC - dd2, CC + dd, MM, MM)

    ctx.drawImage(africanIrisRef.current, CC + dd, CC - dd2, MM, MM)
    ctx.drawImage(africanIrisRef.current, CC - dd, CC + dd2, MM, MM)

    ctx.drawImage(levenderRef.current, 400, 240, SS, SS)
    ctx.drawImage(levenderRef.current, 230, 400, SS, SS)
    ctx.drawImage(levenderRef.current, 50, 510, SS, SS)
    ctx.drawImage(levenderRef.current, 590, 520, SS, SS)
    ctx.drawImage(levenderRef.current, 340, 580, SS, SS)

    ctx.drawImage(wildStrawberryRef.current, 350, 440, SS, SS)
    ctx.drawImage(wildStrawberryRef.current, 280, 200, SS, SS)
    ctx.drawImage(wildStrawberryRef.current, 50, 270, SS, SS)
    ctx.drawImage(wildStrawberryRef.current, 570, 370, SS, SS)

    ctx.drawImage(blueWildRyeRef.current, 200, 280, SS, SS)
    ctx.drawImage(blueWildRyeRef.current, 440, 370, SS, SS)

    ctx.drawImage(blueWildRyeRef.current, 140, 590, SS, SS)
    ctx.drawImage(blueWildRyeRef.current, 520, 590, SS, SS)

    ctx.fillStyle = '#ffffff'

    ctx.fill(
      new Path2D(
        `M ${canvasWidth - 100} ${canvasHeight} L ${canvasWidth} ${canvasHeight} L ${canvasWidth} ${
          canvasHeight - 100
        } A 100 100 0 0 1 ${canvasWidth - 100} ${canvasHeight}`,
      ),
    )
    ctx.fill(
      new Path2D(
        `M 0 ${canvasHeight - 100} L 0 ${canvasHeight} L 100 ${canvasHeight} A 100 100 0 0 1 0 ${
          canvasHeight - 100
        }`,
      ),
    )
  }

  // 6702mtlenevedr
  const drawImage_6702_mt_leneve_dr = () => {
    const LL = 63
    const MM = 41
    const SS = 26

    const canvasObj = canvasRef.current
    const ctx = canvasObj.getContext('2d')
    ctx.save()
    ctx.clearRect(0, 0, canvasWidth, canvasHeight)
    ctx.fillStyle = '#cec3bf'
    // ctx.fillRect(0, 0, canvasWidth, canvasHeight)

    ctx.fill(
      new Path2D(
        'M 140 50 L 0 50 L 0 270 A 800 800 0 0 0 300 710 A 600 600 0 0 1 580 700 L 580 390 A 100 100 0 0 0 480 290 A 80 190 0 0 1 400 100 L 190 100 A 50 50 0 0 1 140 50',
      ),
    )

    ctx.fillStyle = '#aaaaaa'
    ctx.beginPath()
    ctx.arc(400, 500, 35, 0, 2 * Math.PI)
    ctx.fill()

    ctx.drawImage(euryopsRef.current, 40, 170, LL, LL)
    ctx.drawImage(euryopsRef.current, 100, 360, LL, LL)
    ctx.drawImage(euryopsRef.current, 220, 530, LL, LL)

    ctx.drawImage(geraniumRef.current, 40, 100, MM, MM)
    ctx.drawImage(geraniumRef.current, 75, 280, MM, MM)
    ctx.drawImage(geraniumRef.current, 170, 455, MM, MM)
    ctx.drawImage(geraniumRef.current, 290, 620, MM, MM)

    ctx.drawImage(africanIrisRef.current, 309, 310, MM, MM)
    ctx.drawImage(africanIrisRef.current, 141, 127, MM, MM)
    ctx.drawImage(africanIrisRef.current, 500, 600, MM, MM)
    ctx.drawImage(africanIrisRef.current, 500, 380, MM, MM)

    ctx.drawImage(levenderRef.current, 376, 265, SS, SS)
    ctx.drawImage(levenderRef.current, 231, 137, SS, SS)
    ctx.drawImage(levenderRef.current, 502, 470, SS, SS)
    ctx.drawImage(levenderRef.current, 400, 620, SS, SS)
    ctx.drawImage(levenderRef.current, 244, 362, SS, SS)
    ctx.drawImage(levenderRef.current, 160, 209, SS, SS)
    ctx.drawImage(levenderRef.current, 305, 494, SS, SS)

    ctx.drawImage(wildStrawberryRef.current, 339, 137, SS, SS)
    ctx.drawImage(wildStrawberryRef.current, 438, 327, SS, SS)
    ctx.drawImage(wildStrawberryRef.current, 503, 536, SS, SS)
    ctx.drawImage(wildStrawberryRef.current, 446, 434, SS, SS)
    ctx.drawImage(wildStrawberryRef.current, 260, 213, SS, SS)
    ctx.drawImage(wildStrawberryRef.current, 161, 309, SS, SS)
    ctx.drawImage(wildStrawberryRef.current, 244, 437, SS, SS)

    ctx.drawImage(blueWildRyeRef.current, 250, 278, SS, SS)
    ctx.drawImage(blueWildRyeRef.current, 334, 205, SS, SS)
    ctx.drawImage(blueWildRyeRef.current, 308, 402, SS, SS)
    ctx.drawImage(blueWildRyeRef.current, 375, 358, SS, SS)
    ctx.drawImage(blueWildRyeRef.current, 336, 561, SS, SS)
    ctx.drawImage(blueWildRyeRef.current, 430, 545, SS, SS)

    ctx.fillStyle = '#000000'
    ctx.font = '20px Poppins'
    ctx.fillText('Front door', 410, 70)

    ctx.fillText('Driveway', 590, 600)
    ctx.fillText('Sidewalk', 400, 730)

    ctx.translate(0, 440)
    ctx.rotate((50 * Math.PI) / 180)

    ctx.fillText('Property line', 110, 0)
    ctx.translate(0, -440)
    ctx.rotate((-50 * Math.PI) / 180)

    ctx.restore()
  }

  useEffect(() => {
    setTimeout(() => drawImage(), 200)
  })

  const onClickCanvas = (event: any) => {
    const x = event.pageX - 50
    const y = event.pageY - 190
    const dl = 31
    const dm = 20
    const ds = 13
    alert(
      'L:' +
        (x - dl) +
        'x' +
        (y - dl) +
        ', M:' +
        (x - 30) +
        'x' +
        (y - 36) +
        ', S:' +
        (x - 30) +
        'x' +
        (y - 30),
    )
  }

  return (
    <Box>
      <Box mt={5} px={6}>
        <Typography
          align="left"
          style={{
            color: BRAND_YELLOW,
            fontFamily: 'Days One',
            fontWeight: 'normal',
            fontSize: 60,
            lineHeight: '90px',
          }}>
          Bricko
        </Typography>
      </Box>
      <Box px={7}>
        <Typography
          align="left"
          style={{
            fontWeight: 300,
            fontSize: 40,
            lineHeight: '60px',
          }}>
          Landscape Design
        </Typography>
      </Box>

      <Box mt={2} mx={8}>
        <canvas ref={canvasRef} width={canvasWidth} height={canvasHeight} onClick={onClickCanvas} />
      </Box>

      <Box mt={4}>
        <Typography
          align="center"
          style={{
            fontWeight: 300,
            fontSize: 18,
            lineHeight: '27px',
            color: '#333333',
          }}>
          2 of 3
        </Typography>
      </Box>
      <Box style={{display: 'none'}}>
        <img ref={levenderRef} src={'/images/landscape/levender-icon.png'} />
        <img ref={africanIrisRef} src={'/images/landscape/african-iris-icon.png'} />
        <img ref={blueWildRyeRef} src={'/images/landscape/blue-wild-rye-icon.png'} />
        <img ref={geraniumRef} src={'/images/landscape/geranium-icon.png'} />
        {/* <img ref={indianHawthornRef} src={'/images/landscape/indian-hawthorn-icon.png'} /> */}
        <img ref={wildStrawberryRef} src={'/images/landscape/wild-strawberry-icon.png'} />
        <img ref={euryopsRef} src={'/images/landscape/euryops-icon.png'} />
      </Box>
    </Box>
  )
}
