import {useState} from 'react'
import Box from '@material-ui/core/Box'
import {ParagraphHeader, FullScreenImageDialog} from '../../../common/common'
import {backyardPaverProjectPackages} from '../../../../model/pavers'
import {TDIRadioOptionComponent} from '../common/TDIRadioOptionComponent'

export const BackyardPaverProjectPackageOption = ({
  selectedPackage,
  selectedSize,
  onChange,
  isSM,
  final,
  prices,
}: {
  selectedPackage: string
  selectedSize: string
  onChange: (pkg: string, price: string) => void
  isSM: boolean
  final: boolean
  prices: string[]
}) => {
  const [image, setImage] = useState('')

  return (
    <Box>
      <ParagraphHeader>Package</ParagraphHeader>
      <Box style={{marginTop: 10}} display="flex" justifyContent="space-between" flexWrap="wrap">
        {backyardPaverProjectPackages.map((po, i) => {
          const disabled =
            (!!po.extendedOnly && selectedSize !== 'Extended') ||
            (!!po.basicOnly && selectedSize !== 'Cozy')
          const dt = po.extendedOnly ? 'Extended' : 'Cozy'
          const disabledText = `Only Available in ${dt} size`
          const imageURL =
            selectedSize === 'Cozy' ? `/images/${po.imageCozy}` : `/images/${po.imageExtended}`
          const alt = selectedSize === 'Cozy' ? po.altCozy : po.altExtended
          return (
            <TDIRadioOptionComponent
              key={i}
              selected={po.name === selectedPackage}
              onClick={() => onChange(po.name, prices[i])}
              isSM={isSM}
              final={final}
              price={prices[i]}
              onClickImage={(url) => setImage(url)}
              name={po.name}
              description={po.description}
              imageURL={imageURL}
              alt={alt}
              disabled={disabled}
              disabledText={disabledText}
            />
          )
        })}
      </Box>
      <FullScreenImageDialog open={image !== ''} onClose={() => setImage('')} image={image} />
    </Box>
  )
}
