import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import {SectionTitle} from './SectionTitle'
import withWidth, {isWidthUp} from '@material-ui/core/withWidth'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {RText2016, RText2218} from '../../components/common/RText'

const styles = (theme: Theme) =>
  createStyles({
    title: {fontWeight: 600, color: '#333333'},
    description: {fontWeight: 300, color: '#333333'},
  })

const useStyles = makeStyles(styles)

export const AboutBricko = withWidth()(function (props: any) {
  const isSM = isWidthUp('sm', props.width)
  const classes = useStyles()
  return (
    <Box
      style={{
        marginTop: isSM ? '80px' : '0px',
        paddingTop: '50px',
        paddingBottom: isSM ? '80px' : '50px',
        backgroundColor: isSM ? '#f5f5f5' : 'white',
      }}>
      <Container>
        <Box p={isSM ? 0 : 1}>
          <Box>
            <SectionTitle>About</SectionTitle>
          </Box>
          <Box mt={3}>
            <Box mb={1}>
              <RText2218 variant="h3" align="left" className={classes.title}>
                Our Mission
              </RText2218>
            </Box>

            <Grid container spacing={0} justify="center">
              <Grid item xs={12} sm={7}>
                <Box>
                  <RText2016 align="left" className={classes.description}>
                    Home renovations have traditionally been extremely time consuming, frustrating
                    and difficult. We think that technology should make home renovations as simple
                    as using an app to get a ride, or going to a website to order some groceries.
                  </RText2016>
                  <Box mt={3}>
                    <RText2016 align="left" className={classes.description}>
                      Simplicity should be accompanied by honest, transparent pricing and confidence
                      that the end result will be a high quality representation of your vision. Our
                      mission is to make home renovations frictionless and worry-free, instead of
                      something to be feared and dreaded.
                    </RText2016>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={1}></Grid>
              <Grid item xs={12} sm={4}>
                <Box mt={isSM ? 0 : 1}>
                  <img
                    alt="Bricko - home renovation"
                    src="/images/bricko-house.webp"
                    style={{width: '100%', borderRadius: 20}}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box mt={isSM ? 10 : 4} mb={isSM ? 20 : 4}>
            <Box mb={1}>
              <RText2218 variant="h3" align="left" className={classes.title}>
                Who are We?
              </RText2218>
            </Box>

            <Grid container spacing={0} justify="center">
              <Grid item xs={12} sm={7}>
                <Box>
                  <RText2016 align="left" className={classes.description}>
                    We are a Silicon Valley startup seeking to marry technology and construction. We
                    are home owners who got fed up with the difficulty and pain of home renovations
                    &mdash; so much so that we put to use our collective experience of over 80 years
                    executing software, AI, and construction projects to solve this problem
                    ourselves. Introducing Bricko, let's build!
                  </RText2016>
                </Box>
              </Grid>
              <Grid item xs={12} sm={1}></Grid>
              <Grid item xs={12} sm={4}>
                <Box mt={isSM ? 0 : 1}>
                  <img
                    alt="Bricko - who are we"
                    src="/images/bricko-renovation.webp"
                    style={{width: '100%', borderRadius: 20}}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  )
})
