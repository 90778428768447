import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import withWidth, {isWidthUp} from '@material-ui/core/withWidth'
import {SectionTitle} from './SectionTitle'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {RText2016, RSpan1614, RText1614} from '../../components/common/RText'

const styles = (theme: Theme) =>
  createStyles({
    subtitle: {fontWeight: 300, color: '#333333'},
    description: {fontWeight: 300, color: '#333333'},
    emphasize: {fontWeight: 600, color: '#333333'},
  })

const useStyles = makeStyles(styles)

export const TechDriven = withWidth()(function (props: any) {
  const isSM = isWidthUp('sm', props.width)
  const classes = useStyles()
  return (
    <Container
      style={{
        borderBottom: isSM ? 'none' : '5px solid #f5f5f5',
        paddingBottom: isSM ? '0px' : '50px',
      }}>
      <Box p={isSM ? 0 : 1} style={{marginTop: isSM ? '80px' : '50px'}}>
        <Box>
          <SectionTitle>Technology Driven Approach</SectionTitle>
        </Box>
        <Box>
          <RText2016 className={classes.subtitle}>
            Technology is in our DNA; we leverage it thoughtfully with the end goal of making home
            renovations easier and more delightful for you.
          </RText2016>

          <Box mt={4} style={{width: '100%'}}>
            <Grid container spacing={isSM ? 8 : 0} justify="center">
              <Grid item xs={12} sm={6}>
                <Box>
                  <img
                    alt="Bricko - AI blueprint to reality"
                    src="/images/bricko-AI-house.webp"
                    style={{width: '100%', borderRadius: 20, border: '1px solid #dddddd'}}
                  />
                </Box>
                <Box mt={1} mb={isSM ? 0 : 4}>
                  <RText1614
                    variant="h3"
                    className={classes.emphasize}
                    style={{display: 'inline-block'}}>
                    We use AI
                  </RText1614>
                  <RSpan1614 className={classes.description}>
                    &nbsp;to automatically generate Bricko Smart Proposals, which are tailored to
                    your home with no inconvenience and include a fair & transparent upfront price.
                  </RSpan1614>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  <img
                    alt="Bricko - Network of reliable pros"
                    src="/images/bricko-worker.webp"
                    style={{width: '100%', borderRadius: 20}}
                  />
                </Box>

                <Box mt={1}>
                  <RText1614
                    variant="h3"
                    className={classes.emphasize}
                    style={{display: 'inline-block'}}>
                    We maintain a vast network
                  </RText1614>
                  <RSpan1614 className={classes.description}>
                    &nbsp;of reliable pros and automatically select the perfect one to deliver your
                    specific project.
                  </RSpan1614>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Container>
  )
})
