import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import {ProjectType} from '../common/ProjectType'
import {ProjectTypeValue} from '../../model/project'
import GoogleMapsPlacesAutocomplete from '../common/GoogleMapsPlacesAutocomplete'
import {BRAND_YELLOW} from '../../config/styles'
import Button from '@material-ui/core/Button'

import {RText2016} from '../common/RText'
import {MailingAddressModel} from '../../model/common'

export interface AddrProjFormProps {
  addr: string
  addr2: string
  city: string
  state: string
  zipCode: string
  addressGetSmartProposalEnabled: boolean
  projectType: ProjectTypeValue
  onUpdateAddress: (x: MailingAddressModel) => void
  onAddressInputChangeHook: (event: any, newValue: string) => void
  onClickAddressInputGetSmartProposal: () => void
  onChangeProjectType: (value: ProjectTypeValue) => void
}
export const AddrProjForm = ({
  addr,
  addr2,
  city,
  state,
  zipCode,
  addressGetSmartProposalEnabled,
  projectType,
  onUpdateAddress,
  onAddressInputChangeHook,
  onClickAddressInputGetSmartProposal,
  onChangeProjectType,
}: AddrProjFormProps) => {
  return (
    <Box px={2} pt={2} pb={4} style={{borderBottom: '8px solid #f5f5f5'}}>
      <Box>
        <Box>
          <RText2016 style={{fontWeight: 300, color: '#333333'}}>
            Enter your address and choose a project to get an instant AI driven quote, no
            obligations.
          </RText2016>
        </Box>
        <Box mt={2}>
          <GoogleMapsPlacesAutocomplete
            readonly={false}
            addr={addr}
            addr2={addr2}
            city={city}
            state={state}
            zipCode={zipCode}
            onUpdate={onUpdateAddress}
            onInputChangeHook={onAddressInputChangeHook}
          />
        </Box>
        <Box style={{marginTop: 5}}>
          <Typography
            style={{
              fontSize: '12px',
              lineHeight: '18px',
              fontWeight: 300,
              color: '#666666',
            }}>
            We currently offer services in the San Francisco Bay Area
          </Typography>
        </Box>

        <Box style={{marginTop: 23}}>
          <ProjectType
            isSM={false}
            label="Project"
            headerFontWeight={300}
            showDescription={false}
            value={projectType}
            onChange={onChangeProjectType}
          />
        </Box>
        <Box mt={3}>
          <Button
            disabled={!addressGetSmartProposalEnabled}
            size="large"
            onClick={onClickAddressInputGetSmartProposal}
            variant="contained"
            style={{
              backgroundColor: addressGetSmartProposalEnabled ? BRAND_YELLOW : '#cccccc',
              color: 'white',
              textTransform: 'none',
              fontSize: 14,
              fontWeight: 600,
              width: '100%',
            }}>
            Get Instant Smart Proposal
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
