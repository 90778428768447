import {TextInputDialog} from '../common/TextInputDialog'

export interface PhoneVerificationCodeDialogProps {
  open: boolean
  onClose: (value: string) => any
}

export function PhoneVerificationCodeDialog(props: PhoneVerificationCodeDialogProps) {
  return (
    <TextInputDialog
      {...props}
      title="Enter Verification Code"
      message="Enter the verification code sent to your mobile device"
      buttonLabel="Submit"
      textLabel="Verification Code"
      placeholder=""
    />
  )
}
