import {useState} from 'react'
import Box from '@material-ui/core/Box'
import {ParagraphHeader, FullScreenImageDialog} from '../../../common/common'
import {shrubCoverageOptions} from '../../../../model/pavers'
import {TDIRadioOptionComponent} from './TDIRadioOptionComponent'

export const ShrubCoverageOptions = ({
  selectedShrubCoverage,
  onChange,
  isSM,
  final,
}: {
  selectedShrubCoverage: string
  onChange: (sc: string) => void
  isSM: boolean
  final: boolean
}) => {
  const [image, setImage] = useState('')
  return (
    <Box>
      <ParagraphHeader>Shrub coverage</ParagraphHeader>
      <Box style={{marginTop: 10}} display="flex" justifyContent="space-between" flexWrap="wrap">
        {shrubCoverageOptions.map((po, i) => (
          <TDIRadioOptionComponent
            key={i}
            selected={po.name === selectedShrubCoverage}
            onClick={() => onChange(po.name)}
            isSM={isSM}
            final={final}
            price={''}
            onClickImage={() => setImage(`/images/${po.image}`)}
            name={po.name}
            description={po.description}
            imageURL={`/images/${po.image}`}
            alt={po.alt}
            disabled={false}
            disabledText={''}
          />
        ))}
      </Box>
      <FullScreenImageDialog
        width={isSM ? '600px' : ''}
        open={image !== ''}
        onClose={() => setImage('')}
        image={image}
      />
    </Box>
  )
}
