import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import {PaverPatternOption} from '../../../../model/pavers'
import {BRAND_YELLOW} from '../../../../config/styles'
import {ParagraphHeader, ParagraphSubHeader} from '../../../common/common'
import {PaverProjectConfigModel, getPaverModel, getPaverPatterns} from './common'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'

const styles = (theme: Theme) =>
  createStyles({
    patternOpt: {
      marginBottom: 20,
      '&:last-child': {
        marginBottom: 0,
      },
      '&:nth-last-child(2)': {
        marginBottom: 0,
      },
      '&:nth-last-child(3)': {
        marginBottom: 0,
      },
    },
  })

const useStyles = makeStyles(styles)

const PaverPatternOptionComp = ({
  ppo,
  selected,
  onClick,
  isSM,
  final,
}: {
  ppo: PaverPatternOption
  selected: boolean
  onClick: () => void
  isSM: boolean
  final: boolean
}) => {
  const classes = useStyles()
  const handleClick = () => {
    if (final) {
      return
    }
    onClick()
  }
  return (
    <Box
      className={classes.patternOpt}
      onClick={handleClick}
      style={{
        width: isSM ? '18%' : '30%',
        // marginBottom: '20px',
        cursor: selected || final ? 'default' : 'pointer',
      }}>
      <img
        key={ppo.name}
        style={{
          border: selected ? `2px solid ${BRAND_YELLOW}` : '2px solid #dddddd',
          borderRadius: 8,
          padding: 4,
          opacity: final && !selected ? 0.4 : 1,
        }}
        width="100%"
        src={`/images/paver/pattern/${ppo.image}`}></img>
      <Typography
        style={{
          fontSize: 12,
          fontWeight: selected ? 'bold' : 'normal',
          opacity: final && !selected ? 0.4 : 1,
        }}
        align="center">
        {ppo.name}
      </Typography>
    </Box>
  )
}

export const PaverPatternSection = ({
  style,
  color,
  borderColor,
  pattern,
  final,
  isSM,
  onUpdate,
}: {
  style: string
  color: string
  borderColor: string
  pattern: string
  final: boolean
  isSM: boolean
  onUpdate: (model: PaverProjectConfigModel) => void
}) => {
  if (!style || !color) {
    return null
  }
  const patterns = getPaverPatterns(style, color)
  const n = isSM ? 5 : 3
  const w = isSM ? '18%' : '30%'
  let placeholders: any[] = []
  const r = patterns.length % n
  if (r) {
    placeholders = new Array(n - r).fill(0)
  }
  return (
    <Box>
      <Box>
        <ParagraphHeader>Pattern</ParagraphHeader>
        <ParagraphSubHeader>
          Choose the pattern that the paving stones will be laid in (except for the border).
        </ParagraphSubHeader>
      </Box>
      <Box style={{marginTop: 20}} display="flex" justifyContent="space-between" flexWrap="wrap">
        {patterns.map((ppo, i) => (
          <PaverPatternOptionComp
            key={ppo.name}
            ppo={ppo}
            selected={ppo.name === pattern}
            onClick={() => {
              const m = getPaverModel(style, color, borderColor, pattern)
              onUpdate({...m, pattern: ppo.name})
            }}
            isSM={isSM}
            final={final}
          />
        ))}
        {placeholders.map((_, i) => (
          <Box key={i} style={{width: w}}></Box>
        ))}
      </Box>
    </Box>
  )
}
