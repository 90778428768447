import {useEffect, useState, useRef} from 'react'
import firebase from 'firebase/app'
import Box from '@material-ui/core/Box'
import {useHistory, useLocation} from 'react-router-dom'
import {MailingAddressModel} from '../model/common'
import {PhoneVerificationCodeDialog} from '../components/shop/PhoneVerificationCodeDialog'
import {PhoneNumberInputDialog} from '../components/shop/PhoneNumberInputDialog'
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
  getBlankConfirmationDialogProps,
  makeShowErrorDialogFn,
  OrderConfirmationDialog,
  PriceLoadingProgressDialog,
} from '../components/common/dialogs'
import {phoneSignIn, writeEvent, isAuthPending, setAuthPending} from '../services/FirebaseService'
import {fetchPriceInfoMaybe} from '../services/PriceInfoService'
import {callSubmitOrderAPI} from '../services/OrderService'
import {ImageDialog} from '../components/common/common'
import withWidth, {isWidthUp} from '@material-ui/core/withWidth'
import {MailingAddressDialog} from '../components/common/MailingAddressDialog'
import {OrderModel, newOrderModel, setMailingAddress, isOrderModelValid} from '../model/common'
import {ProjectTypeValue, getProjectTypeByQueryParamValue} from '../model/project'
import {normalizeString} from '../utils/strings'
import {PromoCodeDialogWrapper} from '../components/shop/PromoCodeDialogWrapper'
import Logger from 'js-logger'
import {getLandingData} from '../services/LandingService'

import {ShopComp} from '../components/shop/ShopComp'

const noop = () => {
  // no op
}

// cache latest setModel - temp workaround
// for closure capturing stale model issue
// Longer term solution: switch to use redux
// TODO FIXME: replace with useRef
let cachedModel: OrderModel = newOrderModel(ProjectTypeValue.DroughtTolerantFrontYard)
function setCachedModel(m: OrderModel) {
  cachedModel = m
}

const ORDERS_COLL = 'orders2'

enum VerificationDialogType {
  None,
  FetchOrder,
  SubmitOrder,
}

const Shop = withWidth()(function (props: {readonly: boolean}) {
  const isMD = isWidthUp('md', (props as any).width)
  const isSM = isWidthUp('sm', (props as any).width)
  const {readonly} = props
  const query = new URLSearchParams(useLocation().search)
  const pt = query.get('projectType')
  const projectType = getProjectTypeByQueryParamValue(pt)

  const captchaRef = useRef(null)
  const addrRef = useRef('')
  const addressUpdateData = useRef<any>(null)

  const propertyInfoData = useRef<any>(null)
  const userChangedProjectType = useRef(false)

  const [captchaKey, setCaptchaKey] = useState('k-' + Date.now())
  const [inputValid, setInputValid] = useState(false)

  const [pageTitle, setPageTitle] = useState('Your Project')

  const [promoCodeDialogOpen, setPromoCodeDialogOpen] = useState(false)
  const [showEnterPromoCode, setShowEnterPromoCode] = useState(true)

  const [model, setModel] = useState<OrderModel>(newOrderModel(projectType))
  setCachedModel(model)

  const setModelWrapper = (mdl: OrderModel) => {
    setModel(mdl)
    setCachedModel(mdl)
    setInputValid(isOrderModelValid(mdl))
    setShowEnterPromoCode(!mdl.promoCode)
  }

  Logger.info('Shop, model=', model, 'cachedModel=', cachedModel)

  const [phoneNumberInputDialogOpen, setPhoneNumberInputDialogOpen] = useState(false)

  const [orderConfirmationDialogOpen, setOrderConfirmationDialogOpen] = useState(false)
  const [errorDialogProps, setErrorDialogProps] = useState<ConfirmationDialogProps>(
    getBlankConfirmationDialogProps(),
  )
  const showErrorDialog = makeShowErrorDialogFn(setErrorDialogProps)

  const [verificationDialogType, setVerificationDialogType] = useState<VerificationDialogType>(
    VerificationDialogType.None,
  )
  const [confirmationResult, setConfirmationResult] =
    useState<firebase.auth.ConfirmationResult | null>(null)

  const [streetViewURL, setStreetViewURL] = useState('')
  const [streetViewOpen, setStreetViewOpen] = useState(false)

  const [mailingAddressDialogOpen, setMailingAddressDialogOpen] = useState(false)

  const [priceLoadingProgressDialogTimestamp, setPriceLoadingProgressDialogTimestamp] = useState(0)
  const priceTSRef = useRef<any>(null)
  const [priceLoadingFastforward, setPriceLoadingFastforward] = useState(false)

  const hidePriceLoadingProgressDialog = () => {
    setPriceLoadingProgressDialogTimestamp(0)
    setPriceLoadingFastforward(false)
    priceTSRef.current = null
  }

  const [mailingAddressDialogProjType, setMailingAddressDialogProjType] =
    useState<ProjectTypeValue>(projectType)

  const openMailingAddressDialog = () => {
    setMailingAddressDialogProjType(model.projectType)
    setMailingAddressDialogOpen(true)
  }

  const onClosePhoneNumberInputDialog = async (phoneNumber: string) => {
    setPhoneNumberInputDialogOpen(false)
    if (phoneNumber) {
      try {
        const cr = await phoneSignIn(phoneNumber, captchaRef)
        setConfirmationResult(cr)
        // now show dialog to let user enter confirmation code
        setVerificationDialogType(VerificationDialogType.FetchOrder)
      } catch (e) {
        Logger.error('Sign in with phone number failed', e)
        // hack to force clean up of the captcha container
        // recommended clean up code (from: https://firebase.google.com/docs/auth/web/phone-auth)
        // does not work for me
        setCaptchaKey('k-' + Date.now())
        // log event
        firebase.analytics().logEvent('phone_signin_error', {
          phone: phoneNumber,
          page: 'shop',
          error: e.toString(),
        })
        if (e.code === 'auth/invalid-phone-number') {
          showErrorDialog(`Sign in with phone number failed: invalid phone number ${phoneNumber}`)
        } else {
          showErrorDialog(`Sign in with phone number ${phoneNumber} failed`)
        }
      }
    }
  }

  const updateStreetViewMaybe = (props: any) => {
    if (props && props.data && props.data.PropertyAddress) {
      const {Latitude: latitude, Longitude: longitude} = props.data.PropertyAddress
      if (latitude && longitude) {
        // note: the URL does not contain the &size=wxh part
        // this is ok as we are not yet using signed url
        // Later when we switch to use signed url we will
        // include the size in the URL
        // API key here without secret (signature) only allows
        // limited access. Later when we exceed the free quota
        // we will use cloud function to sign the URL
        setStreetViewURL(
          `https://maps.googleapis.com/maps/api/streetview?location=${latitude},${longitude}&key=AIzaSyB5V4BD7pguRepuehqPycOzVdKJoS2KYmE`,
        )
        setModelWrapper({...cachedModel, latitude, longitude})
      }
    }
  }

  const handleAddressUpdate = async ({a1, postal}: {a1: string; postal: string}) => {
    Logger.info('>>> handleAddressUpdate for a1=', a1, ', postal=', postal)
    const addr = normalizeString(`${a1}${postal}`)
    if (addrRef.current === addr) {
      // already fetched, skip
      return
    }
    addrRef.current = addr
    const now = new Date()
    setPriceLoadingProgressDialogTimestamp(now.getTime())
    priceTSRef.current = now

    await doAddressUpdate({a1, postal})
  }
  const doAddressUpdate = async ({a1, postal}: {a1: string; postal: string}) => {
    try {
      const props = await firebase.functions().httpsCallable('propertyInformation2')({
        a1,
        postal,
      })
      propertyInfoData.current = props
      Logger.info('set prop info to:', props)
      updateStreetViewMaybe(props)
      await fetchPriceInfoMaybe(props, cachedModel, setModelWrapper)
    } catch (e) {
      Logger.error('failed to get props:', e)
    } finally {
      // show progress dialog for at least 10 seconds
      if (priceTSRef.current) {
        const timeElapsed = Date.now() - priceTSRef.current.getTime()
        Logger.info('API done, time elapsed:', timeElapsed)
        let timeLeft = 0
        if (timeElapsed < 15000) {
          if (timeElapsed >= 13000) {
            // in step 2 currently
            // continue to show step 2 for timeLeft,
            // so it is shown for 2 seconds
            timeLeft = 15000 - timeElapsed
            setTimeout(() => {
              hidePriceLoadingProgressDialog()
            }, timeLeft)
          } else if (timeElapsed >= 5000) {
            // in step 1 and have shown it for at least 2 seconds,
            // so switch to step 2 for 2 seconds
            // then dismiss dialog
            setPriceLoadingFastforward(true)
            setTimeout(() => {
              hidePriceLoadingProgressDialog()
            }, 2000)
          } else {
            // in step 0 or 1 and have not shown step 1 for 2 seconds,
            // so set a timer to let it show till 2 seconds,
            // then do same logic as above block
            timeLeft = 5000 - timeElapsed
            setTimeout(() => {
              setPriceLoadingFastforward(true)
              setTimeout(() => {
                hidePriceLoadingProgressDialog()
              }, 2000)
            }, timeLeft)
          }
        } else {
          // step 2 already shown at least 2 seconds,
          // so dismiss progress dialog
          hidePriceLoadingProgressDialog()
        }
      }
    }
  }

  // process (and clear) address update data stored in addressUpdateData.current
  const handleAddressUpdateData = async () => {
    if (addressUpdateData.current) {
      const x = addressUpdateData.current
      addressUpdateData.current = null
      const m = setMailingAddress(cachedModel, x)
      if (m.projectType !== mailingAddressDialogProjType) {
        m.projectType = mailingAddressDialogProjType
        userChangedProjectType.current = true
      }
      Logger.info('mailing address dialog update, set model to', m)
      setModelWrapper(m)
      if (x.addr && x.zipCode && x.zipCode.length >= 5) {
        await handleAddressUpdate({a1: x.addr, postal: x.zipCode})
        // write event to Firestore
        const landingData = getLandingData()
        const referrer = landingData?.referrer || document.referrer
        const origin = landingData?.origin || (query.get('origin') ?? '')
        const landing_id = landingData?.id || ''
        const landing_timestamp = landingData?.timestamp || 0
        Logger.info('enter address, prop info is:', propertyInfoData.current)
        const owner = propertyInfoData.current?.data?.PrimaryOwner?.Name1Full ?? 'owner??'
        writeEvent('enter_address', {
          model: cachedModel,
          owner,
          referrer,
          origin,
          landing_id,
          landing_timestamp,
        }).catch((err) => {
          // fail
          Logger.warn('Failed to create enter_address event:', err)
        })
      }
    }
  }

  // handle address passed in via URL query parameter
  useEffect(() => {
    const address = query.get('address')
    if (address) {
      try {
        const addrObj = JSON.parse(decodeURIComponent(address))
        if (addrObj) {
          Logger.info('>>> Got address object from query param:', addrObj)
          addressUpdateData.current = addrObj
          handleAddressUpdateData()
        }
      } catch (e) {
        Logger.warn(e)
      }
    }
  }, [])

  // Listen to the Firebase Auth state
  // if user signed in, then fetch user order
  // if found user order, then use it to populate form
  // and don't show enter address dialog
  useEffect(() => {
    Logger.info('>>> useEffect auth state change setup {')
    return firebase.auth().onAuthStateChanged(async (user) => {
      const signedIn = !!user

      Logger.info('>>> auth changed', Date.now())

      if (signedIn) {
        // if we just submit the order, then the write of userId won't be visible
        // and the following will return empty, which is ok
        // We don't use ref here since auth state change events
        // can come in very close to each other (2-4ms)
        // and mutation of ref.current from first callback
        // does not appear visible in the second callback
        if (isAuthPending()) {
          Logger.info('have user, but auth handler in flight, skip fetch orders...')
          return
        }
        setAuthPending(true)

        let skipShowAddrDialog = false

        Logger.info('have user, fetch orders...')
        try {
          const qs = await firebase
            .firestore()
            .collection(ORDERS_COLL)
            .where('userId', '==', user!.uid)
            .get()
          if (!qs.empty) {
            const doc = qs.docs[0]
            if (doc.exists) {
              const data = doc.data()
              Logger.info('found order:', data)
              skipShowAddrDialog = true
              await handleOrderData(data)
            }
          } else {
            Logger.info('no orders')
          }
        } catch (e) {
          Logger.warn('Fetch order for user failed: ' + e.toString())
        }
        if (!skipShowAddrDialog) {
          openMailingAddressDialog()
        }
        setAuthPending(false)
      }
    })
  }, [])

  const history = useHistory()

  const handleOrderData = async (data: any) => {
    Logger.info('handle order data, merging data:', data, 'into model:', cachedModel)
    // backward compatibility {
    if (data.drivewayPaverProject && data.drivewayPaverProject.appointmentSet === undefined) {
      data.drivewayPaverProject.appointmentSet = true
      data.drivewayPaverProject.comments = ''
    }
    if (data.backyardPaverProject && data.backyardPaverProject.appointmentSet === undefined) {
      data.backyardPaverProject.appointmentSet = true
      data.backyardPaverProject.comments = ''
    }
    if (
      data.droughtTolerantFrontYardModel &&
      data.droughtTolerantFrontYardModel.appointmentSet === undefined
    ) {
      data.droughtTolerantFrontYardModel.appointmentSet = true
      data.droughtTolerantFrontYardModel.comments = ''
    }
    // backward compatibility }
    const m: OrderModel = {...cachedModel, ...data}
    Logger.info('fetch order, set model to', m)
    // if no projectType query parameter and
    // user has not changed project type and
    // order has a single project type data, then set project type
    if (!query.get('projectType') && !userChangedProjectType.current) {
      if (
        data.drivewayPaverProject &&
        !data.backyardPaverProject &&
        !data.droughtTolerantFrontYardModel
      ) {
        m.projectType = ProjectTypeValue.PavingStoneDriveway
      } else if (
        data.backyardPaverProject &&
        !data.drivewayPaverProject &&
        !data.droughtTolerantFrontYardModel
      ) {
        m.projectType = ProjectTypeValue.BackyardPavingStonePatio
      } else if (
        data.droughtTolerantFrontYardModel &&
        !data.drivewayPaverProject &&
        !data.backyardPaverProject
      ) {
        m.projectType = ProjectTypeValue.DroughtTolerantFrontYard
      }
    }
    setModelWrapper(m)
    setPageTitle('My Project')
    // if order data don't have both drivewayPaverProject and backyardPaverProject
    // then fetch pricing info
    // TODO FIXME - in the future we will extract price info out of drivewayPaverProject
    // and backyardPaverProject so we would have all price info as long as we have order,
    // then can skip fetch price info here
    const {addr: a1, zipCode: postal} = m
    await doAddressUpdate({a1, postal})
  }

  useEffect(() => {
    // set timeout to one second since Firebase auth change callback takes about 750ms
    // (in case user is signed in and has an existing order)
    setTimeout(() => {
      const user = firebase.auth().currentUser
      if (!user) {
        if (!query.get('address')) {
          openMailingAddressDialog()
        }
      }
    }, 1000)
  }, [])

  const handleSubmitOrder = async (user: firebase.User) => {
    // only submit order if userId not set or match
    if (cachedModel.userId && user.uid !== cachedModel.userId) {
      throw Error(
        'This address is already claimed by a different phone number. If you think this is a mistake, please contact us.',
      )
    }
    // TODO FIXME - do in transaction
    await callSubmitOrderAPI(user.uid, cachedModel, showErrorDialog)
    if (!cachedModel.userId) {
      setModelWrapper({...cachedModel, userId: user.uid})
    }
    setOrderConfirmationDialogOpen(true)
  }
  const handleOrderConfirmation = () => {
    setOrderConfirmationDialogOpen(false)
    history.push('/')
  }

  const onClosePhoneVerificationCodeDialog = async (code: string) => {
    Logger.info('onClosePhoneVerificationCodeDialog {')
    const vdType = verificationDialogType
    if (vdType === VerificationDialogType.None) {
      return
    }
    setVerificationDialogType(VerificationDialogType.None)
    if (code && confirmationResult) {
      try {
        Logger.info('confirming...')
        const userCreds = await confirmationResult.confirm(code)
        Logger.info('phone verification code confirmed, userCreds=', userCreds)
        const user = userCreds.user
        if (user) {
          if (vdType === VerificationDialogType.SubmitOrder) {
            try {
              await handleSubmitOrder(user)
            } catch (e) {
              showErrorDialog('Failed to submit order: ' + e.toString())
            }
          }
        }
      } catch (e) {
        showErrorDialog('Failed to confirm phone verification code: ' + e.toString())
      }
    } else {
      if (!code) {
        showErrorDialog('No phone confirmation code')
      } else {
        showErrorDialog('No phone confirmation result')
      }
    }
  }
  const handleSubmit = async () => {
    try {
      // see if already signed in
      const user = firebase.auth().currentUser
      // if user signed in, proceed to submit order
      if (user) {
        Logger.info('submit: user already logged in, proceed')
        try {
          await handleSubmitOrder(user)
        } catch (e) {
          Logger.error('Order submission failed', e, user)
          showErrorDialog(`Order submission failed: ${e.toString()}`)
        }
        return
      }
      // user not signed in, try sign in with user phone number
      const cr = await phoneSignIn(cachedModel.phone, captchaRef)
      setConfirmationResult(cr)
      // now show verification dialog to let user enter confirmation code
      // once confirmed, the VerificationDialogType.SubmitOrder flag
      // will trigger handleSubmitOrder processing
      setVerificationDialogType(VerificationDialogType.SubmitOrder)
    } catch (e) {
      Logger.error('Sign in with phone number failed', e)
      // hack to force clean up of the captcha container
      // recommended clean up code (from: https://firebase.google.com/docs/auth/web/phone-auth)
      // does not work for me
      setCaptchaKey('k-' + Date.now())
      // log event
      firebase.analytics().logEvent('phone_signin_error', {
        phone: cachedModel.phone,
        page: 'shop',
        error: e.toString(),
      })
      if (e.code === 'auth/invalid-phone-number') {
        showErrorDialog(
          `Sign in with phone number failed: invalid phone number ${cachedModel.phone}`,
        )
      } else {
        showErrorDialog(`Sign in with phone number ${cachedModel.phone} failed`)
      }
    }
  }

  const onProjTypeValueChange = (projectType: ProjectTypeValue) => {
    const m = {...cachedModel, projectType}
    Logger.info('proj type change, set model to', m)
    userChangedProjectType.current = true
    setModelWrapper(m)
  }

  const onClickStreetView = () => setStreetViewOpen(true)
  const onClickChangeAddress = () => openMailingAddressDialog()
  const onClickEnterPromoCode = () => setPromoCodeDialogOpen(true)

  return (
    <Box>
      <ShopComp
        readonly={readonly}
        model={model}
        pageTitle={pageTitle}
        streetViewURL={streetViewURL}
        showEnterPromoCode={showEnterPromoCode}
        inputValid={inputValid}
        onProjTypeValueChange={onProjTypeValueChange}
        onClickStreetView={onClickStreetView}
        onClickChangeAddress={onClickChangeAddress}
        onClickEnterPromoCode={onClickEnterPromoCode}
        onSubmit={handleSubmit}
        onModelUpdate={setModelWrapper}
      />
      <div key={captchaKey} id="recaptcha-container" ref={captchaRef}></div>
      <PhoneVerificationCodeDialog
        open={verificationDialogType !== VerificationDialogType.None}
        onClose={onClosePhoneVerificationCodeDialog}
      />
      <PhoneNumberInputDialog
        title="Load My Project"
        message="Enter your phone number so we can retrieve your project details"
        open={phoneNumberInputDialogOpen}
        onClose={onClosePhoneNumberInputDialog}
        placeholder={''}
      />
      <ConfirmationDialog {...errorDialogProps} />
      <OrderConfirmationDialog
        open={orderConfirmationDialogOpen}
        onClose={handleOrderConfirmation}
      />
      <ImageDialog
        open={streetViewOpen}
        onClose={() => setStreetViewOpen(false)}
        url={`${streetViewURL}&size=600x600`}
        style={{width: '600px', height: '600px', borderRadius: 8}}
      />

      <MailingAddressDialog
        open={mailingAddressDialogOpen}
        title="Enter Your Address"
        subtitle="So we can produce your instant Bricko Smart Proposal, including upfront Price!"
        buttonLabel="Go!"
        onClose={() => {
          setMailingAddressDialogOpen(false)
          addressUpdateData.current = null
        }}
        onSubmit={async () => {
          setMailingAddressDialogOpen(false)
          await handleAddressUpdateData()
        }}
        addr={model.addr}
        addr2={model.addr2}
        city={model.city}
        state={model.state}
        zipCode={model.zipCode}
        readonly={false}
        optional={false}
        isSM={isMD}
        projTypeValue={mailingAddressDialogProjType}
        onProjTypeValueChange={(x) => setMailingAddressDialogProjType(x)}
        onUpdate={(x: MailingAddressModel) => {
          // save the address for use in onSubmit
          addressUpdateData.current = x
        }}
      />
      <PriceLoadingProgressDialog
        open={priceLoadingProgressDialogTimestamp !== 0}
        onClose={noop}
        isSM={isMD}
        fastforward={priceLoadingFastforward}
        timestamp={priceLoadingProgressDialogTimestamp}
      />
      <PromoCodeDialogWrapper
        model={model}
        onModelUpdate={setModelWrapper}
        open={promoCodeDialogOpen}
        onClose={() => {
          setPromoCodeDialogOpen(false)
        }}
        onError={showErrorDialog}
      />
    </Box>
  )
})

export default Shop
