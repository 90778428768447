import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import {BRAND_YELLOW} from '../../config/styles'
import withWidth, {isWidthUp} from '@material-ui/core/withWidth'
import Button from '@material-ui/core/Button'

export interface HomeHeaderProps {
  onClickLink: (link: string) => void
  onClickGetSmartProposal: () => void
}
export const HomeHeader = withWidth()(function (props: HomeHeaderProps) {
  const width = (props as any).width
  const isSM = isWidthUp('sm', width)
  const isMD = isWidthUp('md', width)

  const links = ['Home', 'Intro', 'Projects', 'Approach', 'About']

  const onClickLink = (e: any, link: string) => {
    e.preventDefault()
    props.onClickLink(link)
  }

  return (
    <Box
      style={{
        position: 'fixed',
        top: 0,
        zIndex: 5,
        width: '100%',
        backgroundColor: 'white',
        borderBottom: '1px solid #eeeeee',
      }}>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: isSM ? '20px 50px' : '15px 20px',
        }}>
        <Box>
          <Typography
            align="left"
            variant="h1"
            style={{
              color: BRAND_YELLOW,
              fontFamily: 'Days One',
              fontSize: isSM ? '48px' : '30px',
              fontWeight: 'normal',
              lineHeight: isSM ? '61px' : '45px',
            }}>
            Bricko
          </Typography>
        </Box>

        <div style={{flexGrow: 1}}></div>

        {isMD
          ? links.map((link, i) => (
              <Link
                key={i}
                component="button"
                style={{
                  color: '#333333',
                  fontSize: 16,
                  backgroundColor: 'white',
                  marginLeft: 30,
                }}
                onClick={(e: any) => onClickLink(e, link)}>
                <Typography
                  style={{
                    fontSize: '16px',
                    lineHeight: '24px',
                    color: '#333333',
                    fontWeight: 'normal',
                  }}>
                  {link}
                </Typography>
              </Link>
            ))
          : null}

        <Box ml={isSM ? 4 : 0}>
          <Button
            size={isSM ? 'large' : 'medium'}
            onClick={props.onClickGetSmartProposal}
            variant="contained"
            style={{
              backgroundColor: BRAND_YELLOW,
              color: 'white',
              textTransform: 'none',
              fontSize: isSM ? 16 : 12,
              fontWeight: 600,
            }}>
            Get Instant Smart Proposal
          </Button>
        </Box>
      </Box>
    </Box>
  )
})
