import {BRAND_YELLOW} from '../../config/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

export const ActionComp = ({
  title,
  description,
  actionLabel,
  isSM,
  onClick,
}: {
  title: string
  description: string
  actionLabel: string
  isSM: boolean
  onClick?: () => void
}) => {
  const titleFontStyle = isSM
    ? {fontSize: '20px', lineHeight: '30px'}
    : {fontSize: '16px', lineHeight: '24px'}
  return (
    <Box display="flex" flexDirection="column" style={{height: '100%'}}>
      <Box>
        <Typography align="left" variant="h3" style={{...titleFontStyle, fontWeight: 'bold'}}>
          {title}
        </Typography>
      </Box>
      <Box style={{paddingRight: 10, flexGrow: 1}}>
        <Typography align="left" style={{fontSize: '16px', lineHeight: '24px', fontWeight: 300}}>
          {description}
        </Typography>
      </Box>
      <Box mt={1} display="flex" style={{flexGrow: 1, alignItems: 'flex-end'}}>
        <Button
          size={'large'}
          onClick={onClick}
          variant="contained"
          style={{
            backgroundColor: BRAND_YELLOW,
            color: 'white',
            textTransform: 'none',
            fontSize: isSM ? 16 : 14,
            fontWeight: 600,
            width: isSM ? 264 : 240,
          }}>
          {actionLabel}
        </Button>
      </Box>
    </Box>
  )
}
