import {useState} from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import {ProjectTypeValue, ProjectSlideInfo} from '../../model/project'
import Button from '@material-ui/core/Button'
import {BRAND_YELLOW, MOBILE_HOME_HEADER_HEIGHT} from '../../config/styles'
import Carousel from 'react-material-ui-carousel'

import GoogleMapsPlacesAutocomplete from '../common/GoogleMapsPlacesAutocomplete'
import {RText2016} from '../common/RText'
import {MailingAddressModel} from '../../model/common'
import * as Icon from 'react-feather'
import {ConfirmationDialog} from '../common/dialogs'

function Item2({
  section,
  isSM,
  slides,
  tags,
}: {
  section: number
  isSM: boolean
  slides: ProjectSlideInfo[]
  tags: string[]
}) {
  const {innerWidth: width, innerHeight: height} = window

  const imgs = slides.slice(section, section + 1)
  const tgs = tags.slice(section, section + 1)
  // const bh = Math.round(0.8 * height) + 'px'
  // const bw = Math.round(0.8 * width) + 'px'
  const bw = width + 'px'

  return (
    <Box
      mt={2}
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{
        // height: bh,
        width: '100%',
      }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{height: '100%', width: bw}}>
        <Grid container spacing={0} justify="center">
          {imgs.map((img, i) => {
            const tag = tgs[i]
            return (
              <Grid item xs={12} sm={12} key={i}>
                <Box
                  display="flex"
                  justifyContent="center"
                  style={{
                    // borderRadius: 8,
                    width: '100%',
                  }}>
                  <Box
                    display="inline-block"
                    style={{
                      position: 'relative',
                      width: isSM ? 'auto' : '100%',
                    }}>
                    <img
                      style={{
                        // borderRadius: 8,
                        padding: 0,
                        width: isSM ? 'auto' : '100%',
                        // height: isSM ? bh : 'auto',
                        height: 200,
                        margin: 'auto',
                      }}
                      src={img.image}></img>
                    {tag ? (
                      <Box
                        style={{
                          padding: '8px 10px 6px 10px',
                          backgroundColor: 'white',
                          position: 'absolute',
                          bottom: 16,
                          left: 10,
                          borderRadius: 6,
                          border: '1px solid #dddddd',
                        }}>
                        <Typography
                          align="center"
                          style={{
                            fontSize: '14px',
                            lineHeight: '21px',
                            fontWeight: 'bold',
                            color: '#55c122',
                          }}>
                          {tag}
                        </Typography>
                      </Box>
                    ) : null}
                  </Box>
                </Box>
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </Box>
  )
}

function LabelValue({
  label,
  value,
  link,
  gv,
  onClick,
}: {
  label: string
  value: string
  link?: string
  gv?: boolean
  onClick?: () => void
}) {
  return (
    <Box width="100%" display="flex" style={{marginTop: 0}}>
      <Box width="35%" style={{paddingRight: 5}}>
        <Typography
          align="right"
          style={{
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 600,
            color: '#333333',
          }}>
          {label}
        </Typography>
      </Box>
      <Box width="65%" style={{paddingLeft: 5}}>
        <Box>
          <Typography
            align="left"
            style={{
              fontSize: '16px',
              lineHeight: '24px',
              fontWeight: gv ? 600 : 300,
              color: gv ? '#55c122' : '#333333',
            }}>
            {value}
          </Typography>
        </Box>

        {link ? (
          <Box
            style={{cursor: 'pointer'}}
            onClick={() => {
              if (onClick) {
                onClick()
              }
            }}>
            <Typography
              align="left"
              style={{
                fontSize: '13px',
                lineHeight: '19px',
                fontWeight: 500,
                color: '#005eb5',
              }}>
              {link}
            </Typography>
          </Box>
        ) : null}
      </Box>
    </Box>
  )
}
// onCarouselIndexChange: (index: number, active: number) => void
export const MobileLanding = ({
  slides,
  onClickLearnMore,
  onClickGetSmartProposal,

  addr,
  addr2,
  city,
  state,
  zipCode,
  addressGetSmartProposalEnabled,
  onUpdateAddress,
  onAddressInputChangeHook,
}: {
  slides: ProjectSlideInfo[]
  onClickLearnMore: (x: ProjectTypeValue) => void
  onClickGetSmartProposal: (x: ProjectTypeValue) => void

  addr: string
  addr2: string
  city: string
  state: string
  zipCode: string
  addressGetSmartProposalEnabled: boolean
  onUpdateAddress: (x: MailingAddressModel) => void
  onAddressInputChangeHook: (event: any, newValue: string) => void
}) => {
  const [carouselIndex, setCarouselIndex] = useState(0)
  const onCarouselIndexChange = (index: number, active: number) => {
    setCarouselIndex(index)
  }
  const slide = slides[carouselIndex]
  const [infoDialogOpen, setInfoDialogOpen] = useState(false)
  return (
    <Box style={{borderBottom: '5px solid #f5f5f5'}}>
      <Box style={{marginTop: MOBILE_HOME_HEADER_HEIGHT}}></Box>
      <Box style={{padding: '20px 0px 0px 0px'}} display="flex" justifyContent="center">
        <Box style={{width: 300}}>
          <Typography
            align="center"
            variant="h1"
            style={{
              fontSize: '26px',
              lineHeight: '39px',
              fontWeight: 'bold',
              color: '#333333',
            }}>
            Home Renovation, Priced Instantly
          </Typography>
        </Box>
      </Box>
      {/* <Box style={{marginTop: 10}} display="flex" justifyContent="center">
        <Box style={{width: 280}}>
          <Typography
            align="center"
            variant="h2"
            style={{
              fontSize: '16px',
              lineHeight: '24px',
              fontWeight: 300,
              color: '#333333',
            }}>
            Customize and price instantly.
          </Typography>
        </Box>
      </Box> */}
      <Box style={{marginTop: 0}}>
        <Carousel
          autoPlay={false}
          animation="slide"
          navButtonsAlwaysVisible
          navButtonsProps={{
            // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
            style: {
              backgroundColor: 'rgba(255,255,255,0.8)',
              color: '#666666',
              marginTop: 0,
              width: '35px',
              height: '35px',
            },
            className: '',
          }}
          activeIndicatorIconButtonProps={{
            style: {
              color: BRAND_YELLOW,
              backgroundColor: BRAND_YELLOW,
              margin: '6px',
            },
            className: '',
          }}
          indicatorIconButtonProps={{
            style: {
              color: '#dddddd',
              backgroundColor: '#dddddd',
              margin: '6px',
            },
            className: '',
          }}
          indicatorContainerProps={{
            style: {
              marginTop: '-6px',
            },
            className: '',
          }}
          IndicatorIcon={
            <div
              style={{
                padding: 0,
                width: '8px',
                height: '8px',
                borderRadius: '4px',
              }}></div>
          }
          index={carouselIndex}
          changeOnFirstRender={false}
          onChange={onCarouselIndexChange}>
          {new Array(slides.length).fill(0).map((_, i) => (
            <Item2 key={i} section={i} isSM={false} slides={slides} tags={[]} />
          ))}
        </Carousel>
      </Box>
      <Box width="100%" style={{marginTop: 8}}>
        <LabelValue
          label="Project Type"
          value={slide.name}
          // link="Learn More"
          onClick={() => onClickLearnMore(slide.projectType)}
        />
        <LabelValue label="Cost" value={slide.price} gv />
        {/* <LabelValue label="Appreciation" value={slide.appreciation} gv /> */}
        <LabelValue label="Location" value={slide.location} />
      </Box>
      {/* <Box mt={1} width="100%">
        <Typography
          align="center"
          style={{
            fontSize: '12px',
            lineHeight: '18px',
            fontWeight: 300,
            color: '#666666',
          }}>
          Interested in this renovation?
        </Typography>
      </Box> */}

      <Box px={2}>
        <Box>
          {/* <Box>
            <RText2016 style={{fontWeight: 300, color: '#333333'}}>
              Enter your address and choose a project to get an instant AI driven quote, no
              obligations.
            </RText2016>
          </Box> */}

          <Box display="flex" style={{marginTop: 14}} onClick={() => setInfoDialogOpen(true)}>
            <Box>
              <Typography
                style={{
                  fontSize: '16px',
                  lineHeight: '24px',
                  fontWeight: 'bold',
                  color: '#333333',
                }}>
                Enter home address to start AI engine &nbsp;
              </Typography>
            </Box>
            <Box style={{marginTop: 2}}>
              <Icon.HelpCircle size={20} />
            </Box>
          </Box>
          <Box style={{marginTop: 0}}>
            <GoogleMapsPlacesAutocomplete
              readonly={false}
              addr={addr}
              addr2={addr2}
              city={city}
              state={state}
              zipCode={zipCode}
              onUpdate={onUpdateAddress}
              onInputChangeHook={onAddressInputChangeHook}
            />
          </Box>
          {/* <Box style={{marginTop: 5}}>
            <Typography
              style={{
                fontSize: '12px',
                lineHeight: '18px',
                fontWeight: 300,
                color: '#666666',
              }}>
              We currently offer services in the San Francisco Bay Area
            </Typography>
          </Box> */}
        </Box>
      </Box>

      <Box mx={2} mb={6} style={{marginTop: 12}}>
        <Button
          disabled={!addressGetSmartProposalEnabled}
          size="large"
          onClick={() => onClickGetSmartProposal(slide.projectType)}
          variant="contained"
          style={{
            backgroundColor: addressGetSmartProposalEnabled ? BRAND_YELLOW : '#cccccc',
            color: 'white',
            textTransform: 'none',
            fontSize: 14,
            fontWeight: 600,
            width: '100%',
          }}>
          Get Instant Price
        </Button>
      </Box>
      <ConfirmationDialog
        open={infoDialogOpen}
        title={'Information'}
        messages={[
          "This address is only used by our AI engine to generate instant prices; You aren't signing up for anything.",
          'We are a Silicon Valley startup company. We will not sell your address.',
        ]}
        buttonLabel={'OK'}
        onClose={() => setInfoDialogOpen(false)}
      />
    </Box>
  )
}
