import {Theme} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {Breakpoint} from '@material-ui/core/styles/createBreakpoints'

/*
xs, extra-small: 0px
sm, small: 600px
md, medium: 960px
lg, large: 1280px
xl, extra-large: 1920px
*/

// pick the right input from the inputs array based on current screen width breakpoint value
// This function can only be called from within a themed React component.
export function bpType<T>(theme: Theme, fn: typeof useMediaQuery, inputs: T[]): T {
  const bps: Breakpoint[] = ['xs', 'sm', 'md', 'lg', 'xl']
  const bpFlags = bps.map((bp) => fn(theme.breakpoints.up(bp)))
  for (let i = bpFlags.length - 1; i >= 0; i--) {
    if (bpFlags[i]) {
      return inputs[i]
    }
  }
  return inputs[0]
}
// specialize bpType to boolean
export function bpBoolean(theme: Theme, fn: typeof useMediaQuery, inputs: boolean[]): boolean {
  return bpType<boolean>(theme, fn, inputs)
}
// specialize bpType to number
export function bpNumber(theme: Theme, fn: typeof useMediaQuery, inputs: number[]): number {
  return bpType<number>(theme, fn, inputs)
}
// specialize bpType to string
export function bpString(theme: Theme, fn: typeof useMediaQuery, inputs: string[]): string {
  return bpType<string>(theme, fn, inputs)
}
