import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import {PaverColorOption} from '../../../../model/pavers'
import {BRAND_YELLOW} from '../../../../config/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import {ParagraphHeader, ParagraphSubHeader} from '../../../common/common'
import {
  PaverProjectConfigModel,
  getPaverColors,
  getPaverModel,
  newPaverProjectConfigModel,
} from './common'
import {ProjectTypeValue} from '../../../../model/project'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'

const styles = (theme: Theme) =>
  createStyles({
    colorOpt: {
      marginBottom: 20,
      '&:last-child': {
        marginBottom: 0,
      },
      '&:nth-last-child(2)': {
        marginBottom: 0,
      },
    },
  })

const useStyles = makeStyles(styles)

export const PaverColorOptionComp = ({
  pco,
  selected,
  onClick,
  isSM,
  final,
  imagePath,
  mainColor,
}: {
  pco: PaverColorOption
  selected: boolean
  onClick: () => void
  isSM: boolean
  final: boolean
  imagePath: string
  mainColor?: string
}) => {
  const classes = useStyles()
  const handleClick = () => {
    if (final) {
      return
    }
    onClick()
  }
  const height = isSM ? 100 : 86
  const marginTop = isSM ? -138 : -124
  const marginBottom = isSM ? 108 : 94
  return (
    <Box
      className={classes.colorOpt}
      onClick={handleClick}
      style={{
        width: isSM ? '22%' : '47%',
        cursor: selected || final ? 'default' : 'pointer',
      }}>
      {!mainColor || pco.name !== mainColor ? (
        <img
          style={{
            borderRadius: 8,
            padding: 0,
            height,
            overflow: 'hidden',
            opacity: final && !selected ? 0.4 : 1,
            objectFit: 'cover',
          }}
          width="100%"
          alt={pco.alt}
          src={`${imagePath}${pco.image}`}></img>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{
            borderRadius: 8,
            backgroundColor: '#eeeeee',
            padding: 0,
            height,
            width: '100%',
            overflow: 'hidden',
            opacity: final && !selected ? 0.4 : 1,
            marginBottom: '5px',
          }}>
          <Typography
            style={{
              fontSize: 12,
              fontWeight: 'normal',
              opacity: final && !selected ? 0.4 : 1,
            }}
            align="center">
            Same As Main Color
          </Typography>
        </Box>
      )}
      <Box>
        <Typography
          style={{
            fontSize: 12,
            fontWeight: selected ? 'bold' : 'normal',
            opacity: final && !selected ? 0.4 : 1,
          }}
          align="center">
          {pco.name}
        </Typography>
      </Box>

      {selected ? (
        <Box
          display="flex"
          zIndex="1"
          justifyContent="flex-end"
          style={{marginTop, marginBottom, marginRight: -12}}>
          <div
            style={{
              position: 'relative',
              top: -4,
              left: 30,
              width: 30,
              height: 30,
              borderRadius: '15px',
              backgroundColor: 'white',
            }}></div>
          <CheckCircleIcon style={{color: BRAND_YELLOW, fontSize: 26, zIndex: 1}} />
        </Box>
      ) : null}
    </Box>
  )
}

export const PaverColorSection = ({
  style,
  color,
  borderColor,
  pattern,
  final,
  isSM,
  projectType,
  onUpdate,
}: {
  style: string
  color: string
  borderColor: string
  pattern: string
  final: boolean
  isSM: boolean
  projectType: ProjectTypeValue
  onUpdate: (model: PaverProjectConfigModel) => void
}) => {
  if (!style) {
    return null
  }
  const colors = getPaverColors(style, true)
  const n = isSM ? 4 : 2
  const w = isSM ? '22%' : '47%'
  let placeholders: any[] = []
  const r = colors.length % n
  if (r) {
    placeholders = new Array(n - r).fill(0)
  }
  const prodName = projectType === ProjectTypeValue.BackyardPavingStonePatio ? 'patio' : 'driveway'

  return (
    <Box>
      <ParagraphHeader>Color</ParagraphHeader>
      <ParagraphSubHeader>{`Choose the main color of your ${prodName}.`}</ParagraphSubHeader>

      <Box style={{marginTop: 20}} display="flex" justifyContent="space-between" flexWrap="wrap">
        {colors.map((pco, i) => (
          <PaverColorOptionComp
            key={pco.name}
            pco={pco}
            selected={pco.name === color}
            onClick={() => {
              const m = getPaverModel(style, color, borderColor, pattern)
              const paverVendor = m.vendor
              if (paverVendor !== pco.vendor) {
                const m0 = newPaverProjectConfigModel()
                onUpdate({...m0, style: m.style, vendor: pco.vendor, color: pco.name})
              } else {
                onUpdate({...m, color: pco.name})
              }
            }}
            isSM={isSM}
            final={final}
            imagePath={'/images/paver/color/'}
          />
        ))}
        {placeholders.map((_, i) => (
          <Box key={i} style={{width: w}}></Box>
        ))}
      </Box>
    </Box>
  )
}
