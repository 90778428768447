import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import withWidth, {isWidthUp} from '@material-ui/core/withWidth'
import {MailingAddressModel} from '../../model/common'

const styles = (theme: Theme) =>
  createStyles({
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  })
const useStyles = makeStyles(styles)

export interface MailingAddressProps extends MailingAddressModel {
  readonly: boolean
  optional?: boolean
  onUpdate: (model: MailingAddressModel) => void
}
export const MailingAddress = withWidth()(function (props: MailingAddressProps) {
  const readonly: boolean = props.readonly

  const classes = useStyles()

  const isSM = isWidthUp('sm', (props as any).width)

  const notifyUpdate = (m: MailingAddressModel) => {
    props.onUpdate(m)
  }
  const getModel = (): MailingAddressModel => {
    const {addr, addr2, city, state, zipCode} = props
    return {
      addr,
      addr2,
      city,
      state,
      zipCode,
    }
  }

  return (
    <Box>
      <Box mt={1}>
        <TextField
          style={{width: '100%'}}
          id="addr1"
          label="Address Line 1"
          variant="outlined"
          value={props.addr}
          onChange={(x) => {
            const a = x.currentTarget.value
            const m = getModel()
            notifyUpdate({...m, addr: a})
          }}
          required={!props.optional}
          disabled={readonly}
        />
      </Box>
      <Box mt={1}>
        <TextField
          style={{width: '100%', marginTop: 5}}
          id="addr2"
          label="Address Line 2"
          variant="outlined"
          value={props.addr2}
          onChange={(x) => {
            const a2 = x.currentTarget.value
            const m = getModel()
            notifyUpdate({...m, addr2: a2})
          }}
          disabled={readonly}
        />
      </Box>

      <Box mt={2} className={classes.sectionDesktop}>
        <Grid container spacing={2} justify="center">
          <Grid item md={4} xs={11}>
            <TextField
              style={{width: '100%'}}
              id="addr-city-desktop"
              label="City"
              variant="outlined"
              value={props.city}
              onChange={(x) => {
                const c = x.currentTarget.value
                const m = getModel()
                notifyUpdate({...m, city: c})
              }}
              required={!props.optional}
              disabled={readonly}
            />
          </Grid>
          <Grid item md={4} xs={11}>
            <TextField
              style={{width: '100%'}}
              id="addr-state-desktop"
              label="State"
              variant="outlined"
              value={props.state}
              onChange={(x) => {
                const s = x.currentTarget.value
                const m = getModel()
                notifyUpdate({...m, state: s})
              }}
              required={!props.optional}
              disabled={readonly}
            />
          </Grid>
          <Grid item md={4} xs={11}>
            <TextField
              style={{width: '100%'}}
              id="addr-zip-desktop"
              label="Zip Code"
              variant="outlined"
              value={props.zipCode}
              onChange={(x) => {
                const z = x.currentTarget.value
                const m = getModel()
                notifyUpdate({...m, zipCode: z})
              }}
              required={!props.optional}
              disabled={readonly}
            />
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.sectionMobile}>
        <Grid container spacing={0} justify="center">
          <Grid item md={4} xs={12}>
            <Box mt={2}>
              <TextField
                style={{width: '100%'}}
                id="addr-city-mobile"
                label="City"
                variant="outlined"
                value={props.city}
                onChange={(x) => {
                  const c = x.currentTarget.value
                  const m = getModel()
                  notifyUpdate({...m, city: c})
                }}
                required={!props.optional}
                disabled={readonly}
              />
            </Box>
          </Grid>
          <Grid item md={4} xs={12}>
            <Box mt={2}>
              <TextField
                style={{width: '100%'}}
                id="addr-state-modile"
                label="State"
                variant="outlined"
                value={props.state}
                onChange={(x) => {
                  const s = x.currentTarget.value
                  const m = getModel()
                  notifyUpdate({...m, state: s})
                }}
                required={!props.optional}
                disabled={readonly}
              />
            </Box>
          </Grid>
          <Grid item md={4} xs={12}>
            <Box mt={2}>
              <TextField
                style={{width: '100%'}}
                id="addr-zip-mobile"
                label="Zip Code"
                variant="outlined"
                value={props.zipCode}
                onChange={(x) => {
                  const z = x.currentTarget.value
                  const m = getModel()
                  notifyUpdate({...m, zipCode: z})
                }}
                required={!props.optional}
                disabled={readonly}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
})
