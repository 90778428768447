import Box from '@material-ui/core/Box'
import React, {useState, useEffect} from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import {BRAND_YELLOW} from '../../config/styles'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'

const noop = () => {}

export interface SimpleConfirmationDialogProps {
  open: boolean
  onClose: () => void
}
export interface ConfirmationDialogProps extends SimpleConfirmationDialogProps {
  title: string
  messages: string[]
  buttonLabel?: string
}

export function getBlankConfirmationDialogProps(): ConfirmationDialogProps {
  return {
    open: false,
    title: '',
    messages: [],
    buttonLabel: '',
    onClose: noop,
  }
}

export function makeShowErrorDialogFn(
  setErrorDialogProps: React.Dispatch<React.SetStateAction<ConfirmationDialogProps>>,
) {
  return (message: string) => {
    setErrorDialogProps({
      open: true,
      title: 'Error',
      messages: [message],
      buttonLabel: 'Dismiss',
      onClose: () => setErrorDialogProps(getBlankConfirmationDialogProps()),
    })
  }
}
export function ConfirmationDialog(props: ConfirmationDialogProps) {
  const {onClose, open, title, messages, buttonLabel} = props
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle
        disableTypography
        style={{
          color: BRAND_YELLOW,
          fontSize: '20px',
          lineHeight: '30px',
          fontWeight: 'bold',
          textAlign: 'center',
        }}>
        {title}
      </DialogTitle>
      <DialogContent style={{maxWidth: 450}}>
        {messages.map((message: string, i: number) => (
          <DialogContentText key={i}>{message}</DialogContentText>
        ))}

        <DialogActions>
          <Box m="auto">
            <Button
              size="large"
              onClick={onClose}
              style={{backgroundColor: BRAND_YELLOW, color: 'white', justifyContent: 'center'}}
              variant="contained">
              {buttonLabel ? buttonLabel : 'OK!'}
            </Button>
          </Box>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export function RequestProposalConfirmationDialog(props: SimpleConfirmationDialogProps) {
  const {onClose, open} = props
  return (
    <ConfirmationDialog
      onClose={onClose}
      open={open}
      title="Confirmation"
      messages={[
        "We've received your request and will be sending out your personalized proposal shortly!",
      ]}
    />
  )
}

export function OrderConfirmationDialog(props: SimpleConfirmationDialogProps) {
  const {onClose, open} = props
  return (
    <ConfirmationDialog
      onClose={onClose}
      open={open}
      title="Confirmation"
      messages={[
        "We've recorded your design choices, made note of your appointment time, and locked in your upfront price!",
        "We'll follow up with a reminder the day before your home consultation appointment.",
      ]}
    />
  )
}

export function InviteConfirmationDialog(props: SimpleConfirmationDialogProps) {
  const {onClose, open} = props
  return (
    <ConfirmationDialog
      onClose={onClose}
      open={open}
      title="Confirmation"
      messages={[
        'Thanks for inviting your friend to Bricko! We will let you know if they do a project and you qualify for the $500 credit.',
      ]}
    />
  )
}

const priceLoadingProgressDialogTexts = [
  'Fetching property information',
  'Calculating guaranteed & fair pricing',
  'Finding available pros',
]
export interface PriceLoadingProgressDialogProps extends SimpleConfirmationDialogProps {
  isSM: boolean
  timestamp: number
  fastforward: boolean
}
export function PriceLoadingProgressDialog(props: PriceLoadingProgressDialogProps) {
  const {onClose, open, isSM, timestamp, fastforward} = props
  const [step, setStep] = useState(0)

  useEffect(() => {
    if (fastforward && step !== 2) {
      setStep(2)
    }
  }, [fastforward])

  useEffect(() => {
    if (timestamp === 0) {
      return
    }
    setStep(0)
    const elapsed = Date.now() - timestamp
    const timer = setTimeout(() => {
      setStep(1)
    }, 3000 - elapsed)
    const timer2 = setTimeout(() => {
      setStep(2)
    }, 13000 - elapsed)
    return () => {
      clearTimeout(timer)
      clearTimeout(timer2)
    }
  }, [timestamp])

  const barSegWidth = isSM ? 100 : 80
  const barSegHeight = isSM ? 30 : 24
  const barSegSep = isSM ? 10 : 8
  const barGray = '#dddddd'

  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{style: {backgroundColor: 'transparent', boxShadow: 'none'}}}>
      <Box
        style={{
          width: isSM ? 600 : 310,
          height: isSM ? 343 : 250,
          backgroundColor: 'white',
          borderRadius: 20,
          padding: isSM ? '50px 0px' : '30px 0px',
        }}>
        <Box>
          <Typography
            align="center"
            style={{
              fontSize: isSM ? '24px' : '18px',
              lineHeight: isSM ? '36px' : '27px',
              fontWeight: 300,
              color: '#000000',
            }}>
            Generating Smart Proposal...
          </Typography>
        </Box>
        <Box mt={isSM ? 8 : 4} display="flex" justifyContent="center">
          <CircularProgress style={{color: BRAND_YELLOW}} />
        </Box>
        <Box mt={isSM ? 6 : 4} display="flex" justifyContent="center">
          <Box
            style={{
              width: barSegWidth,
              height: barSegHeight,
              borderRadius: isSM ? '25px 0 0 25px' : '20px 0 0 20px',
              backgroundColor: BRAND_YELLOW,
            }}></Box>
          <Box
            style={{
              width: barSegWidth,
              height: barSegHeight,
              marginLeft: barSegSep,
              backgroundColor: step === 0 ? barGray : BRAND_YELLOW,
            }}></Box>
          <Box
            style={{
              width: barSegWidth,
              height: barSegHeight,
              marginLeft: barSegSep,
              borderRadius: isSM ? '0 25px 25px 0' : '0 20px 20px 0',
              backgroundColor: step !== 2 ? barGray : BRAND_YELLOW,
            }}></Box>
        </Box>
        <Box mt={2}>
          <Typography
            align="center"
            style={{
              fontSize: isSM ? '16px' : '14px',
              lineHeight: isSM ? '24px' : '21px',
              fontWeight: 300,
              color: '#000000',
            }}>
            {priceLoadingProgressDialogTexts[step]}
          </Typography>
        </Box>
      </Box>
    </Dialog>
  )
}
