import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import {ProjectTypeValue} from '../../model/project'
// import {ParagraphHeader, ParagraphSubHeader, ParagraphSmallPrint} from './common'

function ParagraphHeader(props: any) {
  const {children, fontWeight, ...rest} = props
  return (
    <Typography
      {...rest}
      style={{
        fontSize: '18px',
        lineHeight: '27px',
        fontWeight: fontWeight || 600,
        color: '#333333',
      }}>
      {children}
    </Typography>
  )
}

function ParagraphSubHeader(props: any) {
  const {children, ...rest} = props
  return (
    <Typography
      {...rest}
      style={{fontSize: '18px', lineHeight: '27px', fontWeight: 300, color: '#333333'}}>
      {children}
    </Typography>
  )
}

function ParagraphSmallPrint(props: any) {
  const {children, color, ...rest} = props
  return (
    <Typography
      {...rest}
      style={{fontSize: '16px', lineHeight: '24px', fontWeight: 300, color: color || '#333333'}}>
      {children}
    </Typography>
  )
}

export function WhatsIncluded2(props: any) {
  const {
    projectType,
    selectedSize,
    selectedPackage,
    shrubCoverage,
    irrigationReLayout,
    includeSideStrip,
    ...rest
  } = props
  return (
    <Box {...rest}>
      <Box
        mt={4}
        p={4}
        style={{borderRadius: 8, boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 15px 0px'}}>
        <WhatsIncludedContent2
          projectType={projectType}
          selectedSize={selectedSize}
          selectedPackage={selectedPackage}
          shrubCoverage={shrubCoverage}
          irrigationReLayout={irrigationReLayout}
          includeSideStrip={includeSideStrip}
        />
      </Box>
    </Box>
  )
}
export function WhatsIncludedContent2(props: any) {
  const {
    projectType,
    selectedSize,
    selectedPackage,
    shrubCoverage,
    irrigationReLayout,
    includeSideStrip,
    ...rest
  } = props
  let x: string[] = []
  if (projectType === ProjectTypeValue.BackyardPavingStonePatio) {
    if (selectedSize === 'Cozy') {
      switch (selectedPackage) {
        case 'Basic Patio':
          x = ['Cozy Size', 'Base Package', 'Demolition', 'Hauling', 'Labor & Materials']
          break
        case 'Entertainment':
          x = ['Cozy Size', 'Entertainment Package', 'Demolition', 'Hauling', 'Labor & Materials']
          break
        case 'Premium Entertainment':
          x = [
            'Cozy Size',
            'Premium Entertainment Package',
            'Demolition',
            'Hauling',
            'Labor & Materials',
          ]
          break
      }
    } else {
      switch (selectedPackage) {
        case 'Basic Patio':
          x = ['Extended Size', 'Base Package', 'Demolition', 'Hauling', 'Labor & Materials']
          break
        case 'Entertainment':
          x = [
            'Extended Size',
            'Entertainment Package',
            'Demolition',
            'Hauling',
            'Labor & Materials',
          ]
          break
        case 'Premium Entertainment':
          x = [
            'Extended Size',
            'Premium Entertainment Package',
            'Demolition',
            'Hauling',
            'Labor & Materials',
          ]
          break
      }
    }
  } else if (projectType === ProjectTypeValue.PavingStoneDriveway) {
    if (selectedSize === 'Extended') {
      x = ['Extended driveway', 'Path to front door', 'Demolition', 'Hauling', 'Labor & Materials']
    } else {
      x = ['Standard driveway', 'Path to front door', 'Demolition', 'Hauling', 'Labor & Materials']
    }
  } else if (projectType === ProjectTypeValue.DroughtTolerantFrontYard) {
    x = ['Design', 'Drought Tolerant Plants', 'Mulch', 'Weed Barrier']
    if (irrigationReLayout) {
      x.push('Irrigation')
    }
    if (includeSideStrip) {
      x.push('Parkstrip coverage')
    }
    x.push('Labor & Materials')
  } else {
    throw Error('Whats included: unsupported project type: ' + projectType)
  }
  return (
    <Box {...rest}>
      <ParagraphHeader align="center">What's included?</ParagraphHeader>
      {x.map((txt, i) => (
        <ParagraphSubHeader key={i} align="center">
          {txt}
        </ParagraphSubHeader>
      ))}
      <ParagraphSmallPrint align="center" color="#666666">
        No hidden Costs!
      </ParagraphSmallPrint>
    </Box>
  )
}
