import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import {useHistory} from 'react-router-dom'
import withWidth, {isWidthUp} from '@material-ui/core/withWidth'
import {SectionTitle} from './SectionTitle'
import {ValueComp} from './ValueComp'
import {DollarSign, ThumbsUp, Zap} from 'react-feather'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {RText2016} from '../../components/common/RText'
import {useTheme} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {bpNumber, bpString} from '../../utils/styles'

const styles = (theme: Theme) =>
  createStyles({
    description: {fontWeight: 300, color: '#333333'},
  })

const useStyles = makeStyles(styles)

export const BrickoValues = withWidth()(function (props: any) {
  const theme = useTheme()
  const isSM = isWidthUp('md', props.width)
  const classes = useStyles()
  return (
    <Box
      style={{
        marginTop: isSM ? '80px' : '30px',
        paddingTop: isSM ? '50px' : '30px',
        paddingBottom: isSM ? '80px' : '30px',
        backgroundColor: '#f5f5f5',
      }}>
      <Container>
        <Box p={isSM ? 0 : 1}>
          <Box>
            <SectionTitle>What Makes Bricko Unique?</SectionTitle>
          </Box>

          <Box mb={2}>
            <RText2016 className={classes.description}>
              We fix what's broken with home renovations in the following ways.
            </RText2016>
          </Box>

          <Grid container spacing={0} justify="center">
            <Grid item xs={12}>
              <Box my={2}>
                <Grid
                  container
                  spacing={bpNumber(theme, useMediaQuery, [0, 0, 4, 6, 6]) as any}
                  justify="center">
                  <Grid item xs={12} md={4}>
                    <ValueComp
                      mt={0}
                      title="Upfront Pricing"
                      description="Industry's first upfront and instant pricing with no games, haggling or surprises"
                      IconComp={DollarSign}
                      isSM={isSM}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ValueComp
                      mt={isSM ? 0 : 4}
                      title="Hassle Free"
                      description="No searching for quality contractors, checking references, evaluating proposals, etc"
                      IconComp={ThumbsUp}
                      isSM={isSM}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ValueComp
                      mt={isSM ? 0 : 4}
                      title="In a Flash"
                      description="We'll take you from zero to a finalized project plan for your next renovation in 2 days"
                      IconComp={Zap}
                      isSM={isSM}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  )
})
