import Logger from 'js-logger'

const API_URL = `https://api.ipregistry.co/?key=2fs8suc7p99ove`
let clientIPData: any = null
let requestInFlight = false

export async function getClientIPData(): Promise<any> {
  if (!clientIPData) {
    if (!requestInFlight) {
      requestInFlight = true
      try {
        const response = await fetch(API_URL)
        const data = await response.json()
        clientIPData = data
        Logger.info('ClientIPService.getClientIPData: got:', clientIPData)
      } catch (e) {
        Logger.error(e)
      }
      requestInFlight = false
    }
  }
  return clientIPData
}

export function getCachedClientIPData(): any {
  return clientIPData
}
