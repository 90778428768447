import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import {useState, useRef} from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import {BRAND_YELLOW} from '../../config/styles'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import {MailingAddress, MailingAddressProps} from './MailingAddress'
import {SimpleConfirmationDialogProps} from './dialogs'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {AlertCircle} from 'react-feather'
import GoogleMapsPlacesAutocomplete from './GoogleMapsPlacesAutocomplete'
import {UseGooglePlacesAutoComplete} from '../../config/config'
import {ProjectType} from './ProjectType'
import {
  MailingAddressModel,
  isMailingAddressModelValid,
  mailingAddressModelToString,
} from '../../model/common'
import {ProjectTypeValue} from '../../model/project'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  }),
)

export interface MailingAddressDialogProps
  extends SimpleConfirmationDialogProps,
    MailingAddressProps {
  title: string
  subtitle?: string
  buttonLabel?: string
  isSM: boolean
  projTypeValue: ProjectTypeValue
  onSubmit: () => void
  onProjTypeValueChange: (x: ProjectTypeValue) => void
}

export function MailingAddressDialog(props: MailingAddressDialogProps) {
  const classes = useStyles()
  const {
    onClose,
    onSubmit,
    open,
    title,
    subtitle,
    buttonLabel,
    isSM,
    projTypeValue,
    onProjTypeValueChange,
    ...rest
  } = props
  const [submitEnabled, setSubmitEnabled] = useState(false)
  const addressRef = useRef<any>(null)
  const {onUpdate: onUpdateOrig} = rest
  const onUpdate = (x: MailingAddressModel) => {
    onUpdateOrig(x)
    setSubmitEnabled(isMailingAddressModelValid(x))
    addressRef.current = x
  }
  const onInputChangeHook = (event: any, newValue: string) => {
    // check address match with auto complete - force user to use auto complete
    setSubmitEnabled(
      addressRef.current && newValue.trim() === mailingAddressModelToString(addressRef.current),
    )
  }
  return (
    <Dialog onClose={onClose} open={open}>
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <DialogTitle
        disableTypography
        style={{
          color: BRAND_YELLOW,
          fontSize: '20px',
          lineHeight: '30px',
          fontWeight: 'bold',
          textAlign: 'center',
        }}>
        {title}
      </DialogTitle>
      {subtitle ? (
        <Box mt={-1} mb={2}>
          <Typography
            align="center"
            style={{
              fontSize: '16px',
              lineHeight: '24px',
              fontWeight: 300,
              color: '#333333',
              maxWidth: 570,
              padding: isSM ? '0 100px' : '0 30px',
            }}>
            {subtitle}
          </Typography>
        </Box>
      ) : null}
      <DialogContent style={{maxWidth: 570}}>
        <Box mb={3} display="flex" flexDirection="row" alignItems="center">
          <Box>
            <AlertCircle />
          </Box>
          <Box ml={1}>
            <Typography
              style={{
                fontSize: '14px',
                lineHeight: '21px',
                paddingBottom: '5px',
                fontWeight: 300,
              }}>
              Don't worry, this address is only used to generate the proposal; You aren't signing up
              for anything.
            </Typography>
          </Box>
        </Box>
        <Box mb={3}>
          {UseGooglePlacesAutoComplete ? (
            <Box>
              <GoogleMapsPlacesAutocomplete
                {...rest}
                onUpdate={onUpdate}
                onInputChangeHook={onInputChangeHook}
              />
              <Box style={{marginTop: 5}}>
                <Typography
                  style={{
                    fontSize: '12px',
                    lineHeight: '18px',
                    fontWeight: 300,
                    color: '#666666',
                  }}>
                  We currently offer services in the San Francisco Bay Area
                </Typography>
              </Box>

              <Box style={{marginTop: 23}}>
                <ProjectType
                  isSM={isSM}
                  headerFontWeight={300}
                  value={projTypeValue}
                  showDescription={false}
                  onChange={onProjTypeValueChange}
                />
              </Box>

              <Box style={{marginTop: 5}}>
                <Typography
                  style={{
                    fontSize: '12px',
                    lineHeight: '18px',
                    fontWeight: 300,
                    color: '#666666',
                  }}>
                  You can browse through renovations in the Smart Proposal, this is just the one we
                  will show you first
                </Typography>
              </Box>
            </Box>
          ) : (
            <MailingAddress {...rest} onUpdate={onUpdate} />
          )}
        </Box>

        <DialogActions>
          <Box m="auto" mb={1}>
            <Button
              size="large"
              disabled={!submitEnabled}
              onClick={onSubmit}
              style={{
                backgroundColor: submitEnabled ? BRAND_YELLOW : '#cccccc',
                color: 'white',
                justifyContent: 'center',
                textTransform: 'none',
              }}
              variant="contained">
              {buttonLabel ? buttonLabel : 'OK!'}
            </Button>
          </Box>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
