import {useState} from 'react'
import firebase from 'firebase/app'
import {useHistory} from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import {Header} from '../components/shop/Header'
import {MailingAddress} from '../components/common/MailingAddress'
import GoogleMapsPlacesAutocomplete from '../components/common/GoogleMapsPlacesAutocomplete'
import {
  MailingAddressModel,
  isMailingAddressModelValid,
  newMailingAddressModel,
} from '../model/common'
import {TitledPanel} from '../components/common/TitledPanel'
import {ProjectType} from '../components/common/ProjectType'
import {WhatsNextParagraph, ParagraphHeader, ParagraphSubHeader} from '../components/common/common'
import {ProjectExamples} from '../components/common/ProjectExamples'
import {WhatsIncluded} from '../components/common/WhatsIncluded'
import {SubmitButton} from '../components/shop/SubmitButton'
import {
  RequestProposalConfirmationDialog,
  InviteConfirmationDialog,
  ConfirmationDialog,
  ConfirmationDialogProps,
  getBlankConfirmationDialogProps,
  makeShowErrorDialogFn,
} from '../components/common/dialogs'
import {AlertCircle} from 'react-feather'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {isEmailValid} from '../utils/validation'
import {HEADER_HEIGHT, MOBILE_HOME_HEADER_HEIGHT} from '../config/styles'
import withWidth, {isWidthUp} from '@material-ui/core/withWidth'
import {writeEvent} from '../services/FirebaseService'
import {getImageURLs, getImageTags, ProjectTypeValue} from '../model/project'
import {UseGooglePlacesAutoComplete} from '../config/config'
import Logger from 'js-logger'

const mmt = 2

const styles = (theme: Theme) =>
  createStyles({
    description: {fontSize: '14px', lineHeight: '21px', fontWeight: 300, color: '#333333'},
    emphasize: {fontSize: '14px', lineHeight: '21px', fontWeight: 600, color: '#333333'},
  })
const useStyles = makeStyles(styles)

function isAllValid(
  friend: boolean,
  x: MailingAddressModel,
  email: string,
  name: string,
  phone: string,
): boolean {
  if (friend) {
    if (email && name) {
      if (phone) {
        return true
      }
      const user = firebase.auth().currentUser
      return !!user
    }
    return false
  } else {
    return isMailingAddressModelValid(x) && isEmailValid(email)
  }
}

function WhatsNextPanel({isSM}: {isSM: boolean}) {
  const classes = useStyles()
  return (
    <TitledPanel title="What's Next?" narrow={true} mt={isSM ? 0 : mmt}>
      <WhatsNextParagraph />
      <Box mt={4}>
        <Typography className={classes.emphasize}>After receiving your proposal:</Typography>
        <Box ml={4} mt={-2}>
          <ul>
            {[
              'Come back to bricko.co',
              'Customize your project to best match your style!',
              'Meet with us in person to finalize project details and choose a date for the work to begin!',
            ].map((t, i) => (
              <li key={i} className="browser-default" style={{listStyleType: 'disc'}}>
                <span className={classes.description}>{t}</span>
              </li>
            ))}
          </ul>
        </Box>
      </Box>
    </TitledPanel>
  )
}

export const RequestProposal = withWidth()(function (props: {friend: boolean}) {
  const isMD = isWidthUp('md', (props as any).width)
  const isSM = isWidthUp('sm', (props as any).width)
  const {friend} = props
  const [addrInfo, setAddrInfo] = useState<MailingAddressModel>(newMailingAddressModel())
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [name, setName] = useState('')
  const [inputValid, setInputValid] = useState(false)
  const history = useHistory()
  const [projTypeValue, setProjTypeValue] = useState(ProjectTypeValue.PavingStoneDriveway)

  const [rfpConfirmationDialogOpen, setRfpConfirmationDialogOpen] = useState(false)
  const [inviteConfirmationDialogOpen, setInviteConfirmationDialogOpen] = useState(false)
  const [errorDialogProps, setErrorDialogProps] = useState<ConfirmationDialogProps>(
    getBlankConfirmationDialogProps(),
  )
  const showErrorDialog = makeShowErrorDialogFn(setErrorDialogProps)

  const user = firebase.auth().currentUser

  const submitHandler = () => {
    let userId: any = null
    const user = firebase.auth().currentUser
    if (user) {
      userId = user.uid
    }
    const doc = {
      ...addrInfo,
      email,
      name,
      phone,
      friend,
      userId,
      projectType: 'Paving Stone Driveway',
      createAt: new Date(),
    }
    firebase
      .firestore()
      .collection('proposal_requests')
      .doc()
      .set(doc)
      .then(() => {
        // success
        firebase.analytics().logEvent('rfp_api_success', {
          ...doc,
          operation: 'create',
        })
        if (friend) {
          setInviteConfirmationDialogOpen(true)
        } else {
          setRfpConfirmationDialogOpen(true)
        }

        // write event to Firestore
        const eventName = friend ? 'invite_friend' : 'request_proposal'
        writeEvent(eventName, doc).catch((err) => {
          // fail
          Logger.info('Failed to create event:', err)
        })
      })
      .catch((err) => {
        // fail
        Logger.info('Failed to create proposal request:', err)
        firebase.analytics().logEvent('api_error', {
          ...doc,
          operation: 'create_proposal_request',
          error: err.toString(),
        })
        showErrorDialog('Failed to handle your request, please try again later.')
      })
  }

  const handleRfpConfirmationDialogClose = () => {
    setRfpConfirmationDialogOpen(false)
    history.push('/')
  }

  const handleInviteConfirmationDialogClose = () => {
    setInviteConfirmationDialogOpen(false)
    history.push('/')
  }

  const title = friend ? 'Invite a Friend' : 'Request a Proposal'
  const friendInfoDesc =
    "Entering your friend's information will enable us to send them a personalized proposal right away! Otherwise, they can enter it themselves later."
  const addrDesc = friend
    ? 'Enter the address where your friend may want to have this renovation done.'
    : 'Enter the address where you want this renovation to be done.'
  const emailDesc = friend
    ? "Enter your friend's email address so we can send them the project proposal."
    : 'We will send the project proposal to this email address'

  const infoTitle = friend ? "Friend's Information (Optional)" : 'Your Information'

  const MailingAddressComp = UseGooglePlacesAutoComplete
    ? GoogleMapsPlacesAutocomplete
    : MailingAddress

  return (
    <Box>
      <Header />
      <Box
        style={{
          backgroundColor: '#f5f5f5',
          marginTop: isSM ? HEADER_HEIGHT : MOBILE_HOME_HEADER_HEIGHT,
        }}>
        <Container style={{padding: 0}}>
          <Grid container spacing={0} justify="center">
            <Grid item md={9} xs={12}>
              {friend ? (
                <TitledPanel title={title} narrow={true} mt={0}>
                  <Box mb={3}>
                    <ParagraphHeader>
                      Get a $500 credit for your Bricko renovation by referring a friend!
                    </ParagraphHeader>
                  </Box>
                  <Box mb={3}>
                    <ParagraphSubHeader>
                      Simply enter a friend's email address below and if they do a project with
                      Bricko you will earn a $500 credit towards your renovation.
                    </ParagraphSubHeader>
                  </Box>

                  <Box mb={3} display="flex" flexDirection="row" alignItems="center">
                    <Box>
                      <AlertCircle />
                    </Box>
                    <Box ml={1}>
                      <Typography
                        style={{
                          fontSize: '14px',
                          lineHeight: '24px',
                          paddingBottom: '5px',
                          fontWeight: 300,
                        }}>
                        Bricko currently serves the SF Bay Area so please invite accordingly.
                      </Typography>
                    </Box>
                  </Box>

                  <Box mt={3}>
                    <ParagraphSubHeader>Friend's Email Address</ParagraphSubHeader>
                    <Box mt={2}>
                      <TextField
                        style={{width: '100%'}}
                        id="email"
                        label="Email"
                        variant="outlined"
                        value={email}
                        onChange={(x) => {
                          const em = x.currentTarget.value
                          setEmail(em)
                          setInputValid(isAllValid(friend, addrInfo, em, name, phone))
                        }}
                        required
                      />
                    </Box>
                  </Box>

                  {user ? null : (
                    <Box mt={3}>
                      <ParagraphSubHeader>
                        Your Phone Number (So we can give you credit for the referral!)
                      </ParagraphSubHeader>
                      <Box mt={2}>
                        <TextField
                          style={{width: '100%'}}
                          id="phone"
                          label="Phone"
                          variant="outlined"
                          value={phone}
                          onChange={(x) => {
                            const ph = x.currentTarget.value
                            setPhone(ph)
                            setInputValid(isAllValid(friend, addrInfo, email, name, ph))
                          }}
                          required
                        />
                      </Box>
                    </Box>
                  )}
                </TitledPanel>
              ) : (
                <TitledPanel title={title} narrow={true} mt={isMD ? 0 : mmt}>
                  <ProjectType
                    isSM={isMD}
                    showDescription={true}
                    value={projTypeValue}
                    onChange={(p) => setProjTypeValue(p)}
                  />
                  <WhatsIncluded
                    projectType={projTypeValue}
                    selectedSize=""
                    selectedPackage=""
                    shrubCoverage=""
                    irrigationReLayout={true}
                    includeSideStrip={false}
                  />
                  <ProjectExamples
                    mt={4}
                    imageURLs={getImageURLs(projTypeValue)}
                    tags={getImageTags(projTypeValue)}
                  />
                </TitledPanel>
              )}

              <TitledPanel title={infoTitle} narrow={true} mt={isMD ? 0 : mmt}>
                {friend ? (
                  <Box>
                    <Box mb={3}>
                      <ParagraphSubHeader>{friendInfoDesc}</ParagraphSubHeader>
                    </Box>
                    <Box mb={3}>
                      <TextField
                        style={{width: '100%'}}
                        id="friendName"
                        label="Name"
                        variant="outlined"
                        value={name}
                        onChange={(x) => {
                          const nm = x.currentTarget.value
                          setName(nm)
                          setInputValid(isAllValid(friend, addrInfo, email, nm, phone))
                        }}
                        required
                      />
                    </Box>
                  </Box>
                ) : (
                  <Box mb={3} display="flex" flexDirection="row" alignItems="center">
                    <Box>
                      <AlertCircle />
                    </Box>
                    <Box ml={1}>
                      <Typography
                        style={{
                          fontSize: '14px',
                          lineHeight: '24px',
                          paddingBottom: '5px',
                          fontWeight: 300,
                        }}>
                        Bricko currently serves the SF Bay Area
                      </Typography>
                    </Box>
                  </Box>
                )}
                <Box mb={3}>
                  <ParagraphHeader>Address</ParagraphHeader>
                  <ParagraphSubHeader>{addrDesc}</ParagraphSubHeader>
                </Box>
                <MailingAddressComp
                  addr={addrInfo.addr}
                  addr2={addrInfo.addr2}
                  city={addrInfo.city}
                  state={addrInfo.state}
                  zipCode={addrInfo.zipCode}
                  readonly={false}
                  optional={friend}
                  onUpdate={(x: MailingAddressModel) => {
                    setAddrInfo(x)
                    setInputValid(isAllValid(friend, x, email, name, phone))
                  }}
                  onInputChangeHook={(event: any, newValue: string) => Logger.info('')}
                />
                {friend ? null : (
                  <Box mt={3}>
                    <ParagraphHeader>Email Address</ParagraphHeader>
                    <ParagraphSubHeader>{emailDesc}</ParagraphSubHeader>

                    <Box mt={2}>
                      <TextField
                        style={{width: '100%'}}
                        id="email"
                        label="Email"
                        variant="outlined"
                        value={email}
                        onChange={(x) => {
                          const em = x.currentTarget.value
                          setEmail(em)
                          setInputValid(isAllValid(friend, addrInfo, em, name, phone))
                        }}
                        required
                      />
                    </Box>
                  </Box>
                )}
              </TitledPanel>

              {friend ? null : <WhatsNextPanel isSM={isMD} />}

              <Box m={4}>
                <SubmitButton onClick={submitHandler} disabled={!inputValid} />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <RequestProposalConfirmationDialog
        onClose={handleRfpConfirmationDialogClose}
        open={rfpConfirmationDialogOpen}
      />
      <InviteConfirmationDialog
        onClose={handleInviteConfirmationDialogClose}
        open={inviteConfirmationDialogOpen}
      />
      <ConfirmationDialog {...errorDialogProps} />
    </Box>
  )
})
