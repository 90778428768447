import React, {useState, useEffect} from 'react'
// import './App.css'
import {createStyles, makeStyles, Theme, ThemeProvider} from '@material-ui/core/styles'
import {createTheme} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import {BrowserRouter as Router, Switch, Route, useHistory, useLocation} from 'react-router-dom'
import {TransitionGroup, CSSTransition} from 'react-transition-group'
import Home from './pages/HomeV2'
import Shop from './pages/ShopV2'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/remote-config'
import 'firebase/storage'
import {RequestProposal} from './pages/RequestProposal'
import {Jobs} from './pages/Jobs'
import {Footer} from './Footer'
import {ScrollToTop} from './utils/ScrollToTop'
import Logger from 'js-logger'
import {landing} from './services/LandingService'
import SmartProposal from './pages/internal/SmartProposal'
import SmartProposal2 from './pages/internal/SmartProposal2'
import smoothscroll from 'smoothscroll-polyfill'
import LandscapeDetails from './pages/internal/LandscapeDetails'
import LandscapeDesign from './pages/internal/LandscapeDesign'

// kick off the polyfill!
smoothscroll.polyfill()

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    grow: {
      flexGrow: 1,
    },
  })
})

function AppComp() {
  const classes = useStyles()

  // only works when component is nested inside Router
  const history = useHistory()
  let location = useLocation()

  const [isSignedIn, setIsSignedIn] = useState(false) // Local signed-in state.

  const isDevOrTest = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'
  const isTest = process.env.NODE_ENV === 'test'
  Logger.useDefaults()
  Logger.setHandler(
    Logger.createDefaultHandler({
      formatter: function (messages, context) {
        // prefix each log message with a timestamp.
        // Logger.info('Logger context:', context)
        messages.unshift(new Date().toLocaleTimeString('en-US', {hour12: false}))
      },
    }),
  )
  Logger.setLevel(isDevOrTest ? Logger.INFO : Logger.WARN)

  useEffect(() => {
    return firebase.auth().onAuthStateChanged((user) => {
      setIsSignedIn(!!user)
    })
  }, [])

  const signInOut = () => {
    if (isSignedIn) {
      // sign out
      firebase
        .auth()
        .signOut()
        .then(() => {
          Logger.info('sign out successful')
        })
        .catch((error) => {
          Logger.warn('sign out failed:', error)
        })
    } else {
      // TODO: sign in not yet supported here
    }
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null)

  const isMenuOpen = Boolean(anchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    handleMobileMenuClose()
  }

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const menuId = 'primary-search-account-menu'
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      id={menuId}
      keepMounted
      transformOrigin={{vertical: 'top', horizontal: 'right'}}
      open={isMenuOpen}
      onClose={handleMenuClose}>
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  )

  const mobileMenuId = 'primary-search-account-menu-mobile'
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{vertical: 'top', horizontal: 'right'}}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}>
      <MenuItem onClick={() => history.push('/')}>
        <p>Home</p>
      </MenuItem>
      <MenuItem onClick={() => history.push('/shop')}>
        <p>Shop</p>
      </MenuItem>
      <MenuItem onClick={signInOut}>
        <p>{isSignedIn ? 'Log Out' : 'Log In'}</p>
      </MenuItem>
    </Menu>
  )

  const theme = createTheme({
    typography: {
      fontFamily: `"Poppins", "Roboto", "Helvetica", "Arial", sans-serif`,
      fontSize: 15,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
  })

  const onClickQuestions = () => {
    firebase.analytics().logEvent('action_info_email', {operation: 'open', page: 'footer'})
    return true
  }

  const query = useQuery()
  const reset = query.get('reset')
  if (reset) {
    firebase.auth().signOut()
  }
  const noFooter = query.get('footer') === 'none'

  if (!isDevOrTest) {
    landing(query.get('origin') ?? '')
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.grow}>
        {renderMobileMenu}
        {renderMenu}

        <TransitionGroup>
          {/*
            This is no different than other usage of
            <CSSTransition>, just make sure to pass
            `location` to `Switch` so it can match
            the old location as it animates out.
          */}
          <CSSTransition key={location.key} classNames="fade" timeout={300}>
            <Box>
              <div>
                {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
                <Switch>
                  <Route path="/shop/:personalCode">
                    <Shop readonly={false} />
                  </Route>
                  <Route path="/shop">
                    <Shop readonly={false} />
                  </Route>
                  <Route path="/rfp">
                    <RequestProposal friend={false} />
                  </Route>
                  <Route path="/invite">
                    <RequestProposal friend={true} />
                  </Route>
                  <Route path="/track">
                    <Shop readonly={true} />
                  </Route>
                  <Route path="/jobs">
                    <Jobs />
                  </Route>
                  <Route path="/sp">
                    <SmartProposal />
                  </Route>
                  <Route path="/sp2">
                    <SmartProposal2 />
                  </Route>
                  <Route path="/ld">
                    <LandscapeDetails />
                  </Route>
                  <Route path="/lde">
                    <LandscapeDesign />
                  </Route>

                  <Route path="/">
                    <Home />
                  </Route>
                </Switch>
              </div>

              {noFooter ? null : <Footer onClickQuestions={onClickQuestions} />}
            </Box>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </ThemeProvider>
  )
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <AppComp />
    </Router>
  )
}

export default App
