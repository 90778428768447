import {useState} from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import withWidth, {isWidthUp} from '@material-ui/core/withWidth'
import Grid from '@material-ui/core/Grid'
import Carousel from 'react-material-ui-carousel'
import {BRAND_YELLOW} from '../../config/styles'
import {ImageCarousel} from './ImageCarousel'
import {ParagraphHeader, ParagraphSubHeader, FullScreenDialog} from './common'

function Item2({
  section,
  isSM,
  imageURLs,
  tags,
}: {
  section: number
  isSM: boolean
  imageURLs: string[]
  tags: string[]
}) {
  const {innerWidth: width, innerHeight: height} = window

  const imgs = imageURLs.slice(section, section + 1)
  const tgs = tags.slice(section, section + 1)
  const bh = Math.round(0.8 * height) + 'px'
  const bw = Math.round(0.8 * width) + 'px'

  return (
    <Box
      mt={isSM ? 8 : 1}
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{
        height: bh,
        width: '100%',
      }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{height: '100%', width: bw}}>
        <Grid container spacing={0} justify="center">
          {imgs.map((img, i) => {
            const tag = tgs[i]
            return (
              <Grid item xs={12} sm={12} key={i}>
                <Box
                  display="flex"
                  justifyContent="center"
                  style={{
                    borderRadius: 8,
                    width: '100%',
                  }}>
                  <Box
                    display="inline-block"
                    style={{
                      position: 'relative',
                      width: isSM ? 'auto' : '100%',
                    }}>
                    <img
                      style={{
                        borderRadius: 8,
                        padding: 0,
                        width: isSM ? 'auto' : '100%',
                        height: isSM ? bh : 'auto',
                        margin: 'auto',
                      }}
                      src={img}></img>
                    {tag ? (
                      <Box
                        style={{
                          padding: '8px 10px 6px 10px',
                          backgroundColor: 'white',
                          position: 'absolute',
                          bottom: 16,
                          left: 10,
                          borderRadius: 6,
                          border: '1px solid #dddddd',
                        }}>
                        <Typography
                          align="center"
                          style={{
                            fontSize: '14px',
                            lineHeight: '21px',
                            fontWeight: 'bold',
                            color: '#55c122',
                          }}>
                          {tag}
                        </Typography>
                      </Box>
                    ) : null}
                  </Box>
                </Box>
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </Box>
  )
}

interface ProjectExamplesFullScreenProps {
  open: boolean
  onClose: () => void
  index: number
  imageURLs: string[]
  tags: string[]
  onChange: (index: number, active: number) => void
}
export const ProjectExamplesFullScreen = withWidth()(function (
  props: ProjectExamplesFullScreenProps,
) {
  const isSM = isWidthUp('sm', (props as any).width)
  const {index, imageURLs, tags, onChange, ...rest} = props

  return (
    <FullScreenDialog {...rest}>
      <Carousel
        autoPlay={false}
        animation="slide"
        navButtonsAlwaysVisible
        navButtonsProps={{
          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
          style: {
            backgroundColor: 'transparent',
            color: '#666666',
            margin: isSM ? 30 : -5,
            marginTop: isSM ? 0 : -65,
          },
          className: '',
        }}
        activeIndicatorIconButtonProps={{
          style: {
            color: BRAND_YELLOW,
            backgroundColor: BRAND_YELLOW,
            margin: '6px',
          },
          className: '',
        }}
        indicatorIconButtonProps={{
          style: {
            color: '#dddddd',
            backgroundColor: '#dddddd',
            margin: '6px',
          },
          className: '',
        }}
        indicatorContainerProps={{
          style: {
            marginTop: '60px',
          },
          className: '',
        }}
        IndicatorIcon={
          <div
            style={{
              padding: 0,
              width: '8px',
              height: '8px',
              borderRadius: '4px',
            }}></div>
        }
        index={index}
        changeOnFirstRender={false}
        onChange={onChange}>
        {new Array(imageURLs.length).fill(0).map((_, i) => (
          <Item2 key={i} section={i} isSM={isSM} imageURLs={imageURLs} tags={tags} />
        ))}
      </Carousel>
    </FullScreenDialog>
  )
})

export interface BrowsingState {
  open: boolean
  index: number
}

export const ProjectExamples = withWidth()(function (props: any) {
  const isSM = isWidthUp('sm', (props as any).width)
  const [carouselIndex, setCarouselIndex] = useState(0)
  const [browsingState, setBrowsingState] = useState<BrowsingState>({open: false, index: 0})
  const onClick = (imageName: string, index: number) => {
    setBrowsingState({
      open: true,
      index,
    })
  }
  const onClose = () => {
    const ci = isSM ? Math.floor(browsingState.index / 3) : browsingState.index
    setCarouselIndex(ci)
    setBrowsingState({
      ...browsingState,
      open: false,
    })
  }
  const onChange = (index: number, active: number) => {
    setBrowsingState({
      ...browsingState,
      index,
    })
  }
  const onCarouselIndexChange = (index: number, active: number) => {
    setCarouselIndex(index)
  }
  const {
    imageURLs,
    tags,
    hideDescription,
    itemHeight,
    indicators,
    navButtonsAlwaysInvisible,
    ...rest
  } = props
  return (
    <Box {...rest}>
      {hideDescription ? null : <ParagraphHeader>Examples</ParagraphHeader>}
      {hideDescription ? null : (
        <ParagraphSubHeader>
          Here are some examples of this project delivered by us, so you can get a sense of the
          quality of the end result!
        </ParagraphSubHeader>
      )}
      <Box mx={isSM ? (navButtonsAlwaysInvisible ? -8 : -3) : 0}>
        <ImageCarousel
          carouselIndex={carouselIndex}
          isSM={isSM}
          imageURLs={imageURLs}
          tags={tags}
          countPerSection={isSM ? 3 : 1}
          itemHeight={itemHeight || (isSM ? 130 : 160)}
          indicators={indicators}
          navButtonsAlwaysInvisible={navButtonsAlwaysInvisible}
          onClick={onClick}
          onCarouselIndexChange={onCarouselIndexChange}
        />
      </Box>
      <ProjectExamplesFullScreen
        open={browsingState.open}
        onClose={onClose}
        index={browsingState.index}
        imageURLs={imageURLs}
        tags={tags}
        onChange={onChange}
      />
    </Box>
  )
})
