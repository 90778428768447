import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import {BRAND_YELLOW} from '../../config/styles'
import {SectionTitle} from './SectionTitle'
import withWidth, {isWidthUp} from '@material-ui/core/withWidth'
import {SuggestProjectsDialog, SuggestProjectsDialogModel} from '../common/SuggestProjectsDialog'

export interface BrickoServicesProps {
  onClickSuggestProject: () => void
  suggestProjectsDialogOpen: boolean
  onCloseSuggestProjectsDialog: (model: SuggestProjectsDialogModel | null) => void
  model: SuggestProjectsDialogModel
  onChange: (model: SuggestProjectsDialogModel) => any
}

export const BrickoServices = withWidth()(function (props: BrickoServicesProps) {
  const showBrickoService = false
  const isSM = isWidthUp('sm', (props as any).width)
  const {
    onClickSuggestProject,
    suggestProjectsDialogOpen,
    onCloseSuggestProjectsDialog,
    onChange,
    model,
  } = props
  return (
    <Container>
      {showBrickoService ? (
        <Box>
          <Box mt={5}>
            <SectionTitle>What Services Does Bricko Provide?</SectionTitle>
          </Box>
          <Box mt={5} px={isSM ? 30 : 4}>
            <Typography align="left" style={{fontSize: 20}}>
              Bricko has tackled custom driveway paving as our first home renovation service, as
              paved driveways have become increasingly popular due to their beauty, durability,
              maintainability, and impact on home value.
            </Typography>

            <Box mt={2}>
              <Typography align="left" style={{fontSize: 20}}>
                <Link
                  component="button"
                  variant="body2"
                  style={{
                    color: BRAND_YELLOW,
                    fontSize: 20,
                    marginTop: -2,
                    backgroundColor: 'white',
                  }}
                  onClick={onClickSuggestProject}>
                  Let us know
                </Link>{' '}
                what other home renovation projects you would like to see us provide.
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : null}
      <SuggestProjectsDialog
        open={suggestProjectsDialogOpen}
        onClose={onCloseSuggestProjectsDialog}
        onChange={onChange}
        model={model}
      />
    </Container>
  )
})
