import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import {BRAND_YELLOW} from '../../../../config/styles'
import {DollarSign} from 'react-feather'
import {Link as RLink} from 'react-router-dom'

export const GoodDeal = ({isSM}: {isSM: boolean}) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      style={{borderRadius: 8, padding: '20px', backgroundColor: '#f5f5f5'}}>
      <Box>
        <DollarSign size={isSM ? 100 : 50} style={{color: '#dddddd'}} />
      </Box>
      <Box ml={1}>
        <Typography
          style={{fontSize: '16px', lineHeight: '24px', fontWeight: 600, color: '#333333'}}>
          Wonder if this is a good deal?
        </Typography>
        <Typography style={{fontSize: '14px', lineHeight: '21px', fontWeight: 300}}>
          Don't take our word for it, check out pricing data from{' '}
          <RLink to="//homeadvisor.com" target="_blank" rel="noreferrer" style={{color: 'red'}}>
            <span
              style={{
                fontSize: '14px',
                lineHeight: '21px',
                fontWeight: 600,
                color: BRAND_YELLOW,
              }}>
              HomeAdvisor
            </span>
          </RLink>{' '}
          or{' '}
          <RLink to="//fixr.com" target="_blank" rel="noreferrer" style={{color: 'red'}}>
            <span
              style={{
                fontSize: '14px',
                lineHeight: '21px',
                fontWeight: 600,
                color: BRAND_YELLOW,
              }}>
              Fixr
            </span>
          </RLink>{' '}
          for this specific renovation.
        </Typography>
      </Box>
    </Box>
  )
}
