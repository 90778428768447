import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import {ParagraphHeader} from '../../../common/common'
import {BRAND_YELLOW} from '../../../../config/styles'
import {ProjectSize, drivewayPaverProjectSizes} from '../../../../model/pavers'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'

const styles = (theme: Theme) =>
  createStyles({
    sizeOpt: {
      marginBottom: 20,
      '&:last-child': {
        marginBottom: 0,
      },
      '&:nth-last-child(2)': {
        marginBottom: 0,
      },
    },
  })

const useStyles = makeStyles(styles)

const DrivewayPaverProjectSizeComp = ({
  po,
  selected,
  onClick,
  isSM,
  final,
  regularSqft,
  extendedSqft,
}: {
  po: ProjectSize
  selected: boolean
  onClick: () => void
  isSM: boolean
  final: boolean
  regularSqft: number
  extendedSqft: number
}) => {
  const classes = useStyles()
  const handleClick = () => {
    if (final) {
      return
    }
    onClick()
  }
  const isCozy = po.name === 'Cozy'
  const largeLot = regularSqft > 570
  const description = po.description
  return (
    <Box
      className={classes.sizeOpt}
      onClick={handleClick}
      style={{
        width: '48%',
        padding: isSM ? '20px 18px' : '10px',
        border: selected ? `2px solid ${BRAND_YELLOW}` : '2px solid transparent',
        borderRadius: 8,
        backgroundColor: selected ? 'white' : '#f5f5f5',
        cursor: selected || final ? 'default' : 'pointer',
        position: 'relative',
      }}>
      <Typography
        align="center"
        style={{
          fontSize: isSM ? '22px' : '18px',
          lineHeight: isSM ? '33px' : '27px',
          fontWeight: 600,
          color: '#333333',
          opacity: final && !selected ? 0.4 : 1,
        }}>
        {po.name}
      </Typography>

      <Typography
        align="center"
        style={{
          marginTop: 8,
          fontSize: '13px',
          lineHeight: '19px',
          fontWeight: 300,
          opacity: final && !selected ? 0.4 : 1,
        }}>
        {description}
      </Typography>
    </Box>
  )
}

export const DrivewayPaverProjectSizeOption = ({
  selectedSize,
  onChange,
  isSM,
  final,
  regularSqft,
  extendedSqft,
}: {
  selectedSize: string
  onChange: (size: string) => void
  isSM: boolean
  final: boolean
  regularSqft: number
  extendedSqft: number
}) => {
  return (
    <Box>
      <ParagraphHeader>Size</ParagraphHeader>
      <Box style={{marginTop: 10}} display="flex" justifyContent="space-between" flexWrap="wrap">
        {drivewayPaverProjectSizes.map((po, i) => (
          <DrivewayPaverProjectSizeComp
            key={i}
            po={po}
            selected={po.name === selectedSize}
            isSM={isSM}
            final={final}
            regularSqft={regularSqft}
            extendedSqft={extendedSqft}
            onClick={() => onChange(po.name)}
          />
        ))}
      </Box>
    </Box>
  )
}
