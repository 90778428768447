import Box from '@material-ui/core/Box'
import {ParagraphHeader, ParagraphSubHeader} from '../../../common/common'
import {PaverProjectConfigModel, getPaverBorderColors, getPaverModel} from './common'
import {PaverColorOptionComp} from './PaverColor'

export const PaverBorderColorSection = ({
  style,
  color,
  borderColor,
  pattern,
  final,
  isSM,
  onUpdate,
}: {
  style: string
  color: string
  borderColor: string
  pattern: string
  final: boolean
  isSM: boolean
  onUpdate: (model: PaverProjectConfigModel) => void
}) => {
  if (!style || !color) {
    return null
  }
  const colors = getPaverBorderColors(style, color)
  const n = isSM ? 4 : 2
  const w = isSM ? '22%' : '47%'
  let placeholders: any[] = []
  const r = colors.length % n
  if (r) {
    placeholders = new Array(n - r).fill(0)
  }
  return (
    <Box>
      <Box>
        <ParagraphHeader>Border Color</ParagraphHeader>

        <ParagraphSubHeader>
          This is a border of 6x9 blocks laid in a straight line. Choose the same color as the main
          color for a simpler look, or a different color for something more bold!
        </ParagraphSubHeader>
      </Box>
      <Box style={{marginTop: 20}} display="flex" justifyContent="space-between" flexWrap="wrap">
        {colors.map((pco, i) => (
          <PaverColorOptionComp
            key={pco.name}
            pco={pco}
            selected={pco.name === borderColor}
            onClick={() => {
              const m = getPaverModel(style, color, borderColor, pattern)
              onUpdate({...m, borderColor: pco.name})
            }}
            isSM={isSM}
            final={final}
            mainColor={color}
            imagePath={'/images/paver/color/'}
          />
        ))}
        {placeholders.map((_, i) => (
          <Box key={i} style={{width: w}}></Box>
        ))}
      </Box>
    </Box>
  )
}
