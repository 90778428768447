import {TextInputDialog} from '../common/TextInputDialog'

export interface PhoneNumberInputDialogProps {
  open: boolean
  onClose: (phoneNumber: string) => any
  title: string
  message: string
  placeholder: string
}

export function PhoneNumberInputDialog(props: PhoneNumberInputDialogProps) {
  return <TextInputDialog {...props} buttonLabel="Go!" textLabel="Phone Number" />
}
