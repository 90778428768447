export function normalizeString(s: string): string {
  return s.replace(/ /g, '').toLowerCase()
}

// taken from: https://blog.abelotech.com/posts/number-currency-formatting-javascript/
export function formatNumber(num: number): string {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export function formatPrice(num: number): string {
  return '$' + formatNumber(num)
}
