import {useState} from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import TextField from '@material-ui/core/TextField'
import {BRAND_YELLOW} from '../../config/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  }),
)

export interface TextInputDialogProps {
  open: boolean
  onClose: (phoneNumber: string) => any
  title: string
  message: string
  placeholder: string
  textLabel: string
  buttonLabel: string
}

export function TextInputDialog(props: TextInputDialogProps) {
  const classes = useStyles()
  const {onClose, open, title, message, placeholder, textLabel, buttonLabel} = props
  const [text, setText] = useState('')

  const dismiss = () => {
    onClose('')
  }

  return (
    <Dialog onClose={dismiss} open={open}>
      <IconButton aria-label="close" className={classes.closeButton} onClick={dismiss}>
        <CloseIcon />
      </IconButton>
      <Box py={3} px={5} style={{maxWidth: 450}}>
        <Typography variant="h6" align="center" style={{fontWeight: 'bold', color: BRAND_YELLOW}}>
          {title}
        </Typography>
        <Typography align="center">{message}</Typography>

        <Box mt={4} mb={2} style={{display: 'flex', justifyContent: 'center'}}>
          <TextField
            style={{width: '100%'}}
            value={text}
            placeholder={placeholder}
            variant="outlined"
            label={textLabel}
            onChange={(x) => {
              setText(x.currentTarget.value)
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                onClose(text)
              }
            }}
          />
        </Box>

        <Box mt={4} style={{display: 'flex', justifyContent: 'center'}}>
          <Button
            size="large"
            onClick={() => onClose(text)}
            variant="contained"
            style={{backgroundColor: BRAND_YELLOW, color: 'white', textTransform: 'none'}}>
            {buttonLabel}
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}
