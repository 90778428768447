import React from 'react'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withWidth, {isWidthUp} from '@material-ui/core/withWidth'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import {TransitionProps} from '@material-ui/core/transitions'
import DialogContent from '@material-ui/core/DialogContent'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(4),
      top: theme.spacing(4),
      color: theme.palette.grey[500],
    },
  }),
)

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {children?: React.ReactElement},
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export function ParagraphHeader(props: any) {
  const {children, fontWeight, ...rest} = props
  return (
    <Typography
      {...rest}
      style={{
        fontSize: '14px',
        lineHeight: '21px',
        fontWeight: fontWeight || 600,
        color: '#333333',
      }}>
      {children}
    </Typography>
  )
}

export function ParagraphSubHeader(props: any) {
  const {children, ...rest} = props
  return (
    <Typography
      {...rest}
      style={{fontSize: '14px', lineHeight: '21px', fontWeight: 300, color: '#333333'}}>
      {children}
    </Typography>
  )
}

export function ParagraphSmallPrint(props: any) {
  const {children, color, ...rest} = props
  return (
    <Typography
      {...rest}
      style={{fontSize: '12px', lineHeight: '18px', fontWeight: 300, color: color || '#333333'}}>
      {children}
    </Typography>
  )
}

export function WhatsNextParagraph(props: any) {
  return (
    <Box {...props}>
      <ParagraphSubHeader>
        Our system will automatically do the analysis on your home & surrounding area to determine
        your personal project proposal, including the upfront price. You should receive your
        proposal within minutes!.
      </ParagraphSubHeader>
    </Box>
  )
}

export const FullScreenDialog = function (props: any) {
  const classes = useStyles()
  const {open, onClose, children} = props

  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
      {children}
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </Dialog>
  )
}

interface FullScreenImageDialogProps {
  open: boolean
  onClose: () => void
  image: string
  width?: string
}

export const FullScreenImageDialog = function (props: FullScreenImageDialogProps) {
  const {image, width, ...rest} = props
  const imgStyle: any = {
    borderRadius: 8,
    overflow: 'hidden',
    height: '90vh',
    objectFit: 'contain',
  }
  if (width) {
    imgStyle.maxWidth = width
  }
  return (
    <FullScreenDialog {...rest}>
      <Box
        display="flex"
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        style={{
          width: '100%',
          marginTop: 'calc(5vh - 20px)',
          borderRadius: 8,
          overflow: 'hidden',
        }}>
        <img style={imgStyle} src={image}></img>
      </Box>
    </FullScreenDialog>
  )
}

export interface ImageDialogProps {
  open: boolean
  onClose: () => void
  url: string
  style?: React.CSSProperties
}
export function ImageDialog(props: ImageDialogProps) {
  const {open, onClose, url, style} = props
  const classes = useStyles()
  return (
    <Dialog maxWidth="lg" open={open} onClose={onClose} TransitionComponent={Transition}>
      <DialogContent>
        <Box display="flex" alignItems="center" justifyContent="center" style={{padding: '80px'}}>
          <img src={url} style={style} />
        </Box>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogContent>
    </Dialog>
  )
}
