import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import {BRAND_YELLOW} from '../../../../config/styles'
import {ZoomIn} from 'react-feather'
import IconButton from '@material-ui/core/IconButton'
import {String} from 'lodash'

export const TDIOptionComponent = ({
  selected,
  isSM,
  final,
  image,
  alt,
  name,
  description,
  width,
  onClick,
  onClickImage,
}: {
  selected: boolean
  isSM: boolean
  final: boolean
  image: string
  alt: string
  name: string
  description: string
  width?: string
  onClick: () => void
  onClickImage: () => void
}) => {
  const handleClick = () => {
    if (final) {
      return
    }
    onClick()
  }
  return (
    <Box
      onClick={handleClick}
      style={{
        width: isSM ? (width as any) || '30%' : '100%',
        padding: '20px 18px 0 18px',
        border: selected ? `2px solid ${BRAND_YELLOW}` : '2px solid transparent',
        borderRadius: 8,
        marginBottom: isSM ? 0 : 15,
        backgroundColor: selected ? 'white' : '#f5f5f5',
        cursor: selected || final ? 'default' : 'pointer',
        position: 'relative',
        paddingBottom: isSM ? (image ? 220 : 16) : 0,
      }}>
      <Typography
        align="center"
        style={{
          fontSize: isSM ? '22px' : '18px',
          lineHeight: isSM ? '33px' : '27px',
          fontWeight: 500,
          color: '#333333',
          marginBottom: 4,
          opacity: final && !selected ? 0.4 : 1,
        }}>
        {name}
      </Typography>
      <Typography
        align="center"
        style={{
          fontSize: '13px',
          lineHeight: '19px',
          fontWeight: 300,
          opacity: final && !selected ? 0.4 : 1,
        }}>
        {description}
      </Typography>
      {image ? (
        <Box
          style={{
            position: isSM ? 'absolute' : 'relative',
            bottom: 0,
            left: 0,
            padding: '10px 10px 5px 10px',
          }}>
          <img
            src={image}
            alt={alt}
            width="100%"
            style={{borderRadius: 8, height: '183px', objectFit: 'cover'}}
          />
          <div
            style={{
              width: 40,
              height: 40,
              position: 'absolute',
              bottom: 20,
              right: 20,
              borderRadius: 20,
              backgroundColor: 'rgba(0, 0, 0, 0.30)',
              display: 'flex',
              justifyContent: 'center',
            }}>
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                setTimeout(() => {
                  onClickImage()
                }, 200)
              }}
              style={{backgroundColor: 'transparent'}}>
              <ZoomIn color="white" />
            </IconButton>
          </div>
        </Box>
      ) : null}
    </Box>
  )
}
