import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import withWidth, {isWidthUp} from '@material-ui/core/withWidth'
import {SectionTitle} from './SectionTitle'
import {ActionComp} from './ActionComp'

export interface BrickoActionsProps {
  onClickOpenFromPanelButton: () => void
  onClickProjectDetails: () => void
  onClickInviteFriend: () => void
}
export const BrickoActions = withWidth()(function (props: BrickoActionsProps) {
  const isMD = isWidthUp('md', (props as any).width)
  const isSM = isWidthUp('sm', (props as any).width)

  return (
    <Box
      style={{
        backgroundColor: isSM ? '#f5f5f5' : 'white',
        marginTop: isSM ? (isMD ? '80px' : '30px') : '0px',
        paddingTop: isMD ? '50px' : '30px',
        paddingBottom: isMD ? '50px' : '30px',
        borderBottom: isSM ? 'none' : '5px solid #f5f5f5',
      }}>
      <Container>
        <Box p={isMD ? 0 : 1}>
          <Box>
            <SectionTitle>Take Action</SectionTitle>
          </Box>
          <Box mt={3}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <ActionComp
                  title="Get Started"
                  description="Browse projects, customize to your style, and instantly view your upfront price."
                  actionLabel="Get Instant Smart Proposal"
                  onClick={props.onClickOpenFromPanelButton}
                  isSM={isMD}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={4}>
                <ActionComp
                  title="Track My Project"
                  description="Check project details, including design customizations & appointment info."
                  actionLabel="Project Details"
                  onClick={props.onClickProjectDetails}
                  isSM={isMD}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={4}>
                <ActionComp
                  title="Invite a Friend"
                  description="Get $500 off your Bricko project by inviting a friend!"
                  actionLabel="Invite Friend"
                  onClick={props.onClickInviteFriend}
                  isSM={isMD}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  )
})
