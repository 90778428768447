/// <reference types="google.maps" />
// reference: https://material-ui.com/components/autocomplete/
import React, {useMemo} from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {makeStyles} from '@material-ui/core/styles'
import parse from 'autosuggest-highlight/parse'
import throttle from 'lodash/throttle'
import {MailingAddressModel, mailingAddressModelToString} from '../../model/common'
import {MailingAddressProps} from './MailingAddress'
import Logger from 'js-logger'
import firebase from 'firebase/app'

const DEBUG = false

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return
  }

  const script = document.createElement('script')
  script.setAttribute('async', '')
  script.setAttribute('id', id)
  script.src = src
  position.appendChild(script)
}

const autocompleteService = {current: null}

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}))

// call this lazily to ensure google is loaded
function geoCenter() {
  return new google.maps.LatLng({lat: 37.49, lng: -122.22})
}

export interface GoogleMapsPlacesAutocompleteProps extends MailingAddressProps {
  onInputChangeHook: (event: any, newValue: string) => void
}
export default function GoogleMapsPlacesAutocomplete(props: GoogleMapsPlacesAutocompleteProps) {
  const classes = useStyles()
  const [value, setValue] = React.useState<google.maps.places.AutocompletePrediction | null>(null)
  const [inputValue, setInputValue] = React.useState(mailingAddressModelToString(props))
  const [options, setOptions] = React.useState<google.maps.places.AutocompletePrediction[]>([])
  const loaded = React.useRef(false)

  const pc = firebase.remoteConfig().getValue('product_config').asString()
  const supportedCityStates = useMemo(() => {
    return JSON.parse(pc).supported_cities.map((cs: any) => `${cs.city}, ${cs.state}`)
  }, [pc])

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyB5V4BD7pguRepuehqPycOzVdKJoS2KYmE&libraries=places',
        document.querySelector('head'),
        'google-maps',
      )
    }
    loaded.current = true
  }

  const geoRadius = 50000 // 50 km
  const fetch = React.useMemo(
    () =>
      throttle(
        (
          request: {input: string},
          callback: (results: google.maps.places.AutocompletePrediction[] | null) => void,
        ) => {
          ;(
            autocompleteService.current as any as google.maps.places.AutocompleteService
          ).getPlacePredictions(
            {
              ...request,
              types: ['address'],
              location: geoCenter(),
              origin: geoCenter(),
              radius: geoRadius,
            },
            callback,
          )
        },
        200,
      ),
    [],
  )

  React.useEffect(() => {
    let active = true

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService()
    }
    if (!autocompleteService.current) {
      return undefined
    }

    if (inputValue === '') {
      setOptions(value ? [value] : [])
      return undefined
    }

    fetch({input: inputValue}, (results: google.maps.places.AutocompletePrediction[] | null) => {
      if (active) {
        let newOptions = [] as google.maps.places.AutocompletePrediction[]

        if (value) {
          newOptions = [value]
        }

        if (results) {
          if (DEBUG) {
            Logger.info('>>> filtering places autocomplete results:', results)
          }
          results = results.filter((x) => x.distance_meters && x.distance_meters < geoRadius)
          results = results.filter((x) => {
            for (let i = 0; i < supportedCityStates.length; i++) {
              const cs = supportedCityStates[i]
              if (x.description.indexOf(cs) !== -1) {
                return true
              }
            }
            // drop address - unsupported city
            if (DEBUG) {
              Logger.info('>>> dropping address for unsupported city:', x.description)
            }
            return false
          })
          newOptions = [...newOptions, ...results]
        }

        setOptions(newOptions)
      }
    })

    return () => {
      active = false
    }
  }, [value, inputValue, fetch])

  return (
    <Autocomplete
      id="google-map-demo"
      style={{width: '100%'}}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText={
        <span>{inputValue ? 'No results found in our service area' : 'Enter address'}</span>
      }
      onChange={async (event: any, newValue: google.maps.places.AutocompletePrediction | null) => {
        setOptions(newValue ? [newValue, ...options] : options)
        if (DEBUG) {
          Logger.info('>>> calling setValue, newValue=', newValue)
        }
        if (newValue && window.google) {
          // use PlacesService getDetails API to fetch zip code
          try {
            const mailingAddr: MailingAddressModel = await new Promise((resolve, reject) => {
              new window.google.maps.places.PlacesService(document.createElement('div')).getDetails(
                {
                  placeId: newValue.place_id,
                  fields: ['address_components'],
                },
                (details) => {
                  if (details && details.address_components) {
                    let postcode = null
                    let addr = '',
                      city = '',
                      state = '',
                      zipCode = '',
                      streetNo = '',
                      route = ''
                    details.address_components.forEach((entry) => {
                      switch (entry.types[0]) {
                        case 'street_number':
                          streetNo = entry.long_name
                          break
                        case 'route':
                          route = entry.short_name
                          break
                        case 'locality':
                          city = entry.long_name
                          break
                        case 'administrative_area_level_1':
                          state = entry.short_name
                          break
                        case 'postal_code':
                          postcode = zipCode = entry.long_name
                          break
                      }
                    })
                    addr = `${streetNo} ${route}`
                    if (DEBUG) {
                      Logger.info('>>> got address details:', details)
                    }
                    resolve({addr, addr2: '', city, state, zipCode})
                  } else {
                    reject(Error('not found'))
                  }
                },
              )
            })
            // remove trailing USA, add postal code
            const desc = mailingAddressModelToString(mailingAddr)
            if (DEBUG) {
              Logger.info('replace desc', newValue.description, ' with ', desc)
            }
            newValue.description = desc
            // call props onUpdate
            props.onUpdate(mailingAddr)
          } catch (e) {
            Logger.warn(e)
          }
        }

        setValue(newValue)
      }}
      onInputChange={(event, newInputValue) => {
        if (DEBUG) {
          Logger.info('input change: event', event, 'value:', newInputValue)
        }
        setInputValue(newInputValue)
        props.onInputChangeHook(event, newInputValue)
      }}
      renderInput={(params) => (
        <TextField {...params} label="Address" variant="outlined" fullWidth />
      )}
      renderOption={(option) => {
        const matches = option.structured_formatting.main_text_matched_substrings
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length]),
        )

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} style={{fontWeight: part.highlight ? 700 : 400}}>
                  {part.text}
                </span>
              ))}
              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        )
      }}
    />
  )
}
