import {PaverWallOptions, PaverColorOption, PaverWallOption} from '../../../../model/pavers'

export const getPaverWallOption = (style: string): PaverWallOption | null => {
  if (style) {
    const paverOpt = PaverWallOptions.find((po) => po.style === style)
    return paverOpt ? paverOpt : null
  }
  return null
}

export const getPaverWallColors = (style: string): PaverColorOption[] => {
  const paverOpt = getPaverWallOption(style)
  if (paverOpt) {
    return paverOpt.colors
  }
  return []
}
