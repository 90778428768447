import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import {BRAND_YELLOW} from '../../config/styles'
import {useLocation} from 'react-router-dom'

interface LandscapeItem {
  name: string
  icon: string
  image: string
}
const liMap: {[key: string]: LandscapeItem} = {
  a: {name: 'African Iris', icon: 'african-iris-icon.png', image: 'african-iris.png'},
  b: {name: 'Blue Wild Rye', icon: 'blue-wild-rye-icon.png', image: 'blue-wild-rye.png'},
  e: {name: 'Euryops', icon: 'euryops-icon.png', image: 'euryops.png'},
  g: {name: 'Geranium', icon: 'geranium-icon.png', image: 'geranium.png'},
  i: {
    name: 'Indian Hawthorn',
    icon: 'indian-hawthorn-icon.png',
    image: 'indian-hawthorn.png',
  },
  l: {name: 'Levender', icon: 'levender-icon.png', image: 'levender.png'},
  m: {name: 'Bark Mulch', icon: 'mulch-icon.png', image: 'mulch.png'},
  w: {
    name: 'Wild Strawberry',
    icon: 'wild-strawberry-icon.png',
    image: 'wild-strawberry.png',
  },
}

interface LandscapeItemInstance {
  name: string
  icon: string
  image: string
  count?: number
}

export default function LandscapeDetails(props: any) {
  const query = new URLSearchParams(useLocation().search)
  const landscapeItems: LandscapeItemInstance[] = []
  Object.keys(liMap).forEach((k) => {
    const kv = query.get(k)
    if (kv !== null) {
      const count = parseInt(kv)
      if (count) {
        landscapeItems.push({...liMap[k], count})
      } else {
        landscapeItems.push(liMap[k])
      }
    }
  })

  return (
    <Box>
      <Box mt={5} px={6}>
        <Typography
          align="left"
          style={{
            color: BRAND_YELLOW,
            fontFamily: 'Days One',
            fontWeight: 'normal',
            fontSize: 60,
            lineHeight: '90px',
          }}>
          Bricko
        </Typography>
      </Box>
      <Box px={7}>
        <Typography
          align="left"
          style={{
            fontWeight: 300,
            fontSize: 40,
            lineHeight: '60px',
          }}>
          Landscape Details
        </Typography>
      </Box>

      <Box mt={8} display="flex" flexWrap="wrap" style={{padding: '0 26px'}}>
        {landscapeItems.map((li, i) => (
          <Box
            key={i}
            display="flex"
            alignItems="center"
            style={{
              width: '50%',
              padding: i === 0 || i === 1 ? '0 30px' : '30px 30px 0 30px',
              borderRight: i % 2 ? 'none' : '1px solid #cccccc',
            }}>
            <Box width="50%">
              <Box>
                <img src={'/images/landscape/' + li.icon} style={{width: '80px'}} />
              </Box>
              <Box>
                <Typography
                  align="left"
                  style={{fontSize: 20, lineHeight: '22px', fontWeight: 300}}>
                  {li.name}
                </Typography>
              </Box>
              {li.count ? (
                <Box>
                  <Typography
                    align="left"
                    style={{fontSize: 18, lineHeight: '27px', fontWeight: 300}}>
                    {'Included: ' + li.count}
                  </Typography>
                </Box>
              ) : null}
            </Box>
            <Box width="50%" display="flex" justifyContent="flex-end">
              <img src={'/images/landscape/' + li.image} style={{width: '200px'}} />
            </Box>
          </Box>
        ))}
      </Box>

      <Box mt={3}>
        <Typography
          align="center"
          style={{
            fontWeight: 300,
            fontSize: 18,
            lineHeight: '27px',
            color: '#333333',
          }}>
          3 of 3
        </Typography>
      </Box>
    </Box>
  )
}
