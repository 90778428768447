import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Carousel from 'react-material-ui-carousel'
import {BRAND_YELLOW} from '../../config/styles'

function Item({
  imageURLs,
  tags,
  section,
  countPerSection,
  isSM,
  height,
  onClick,
}: {
  imageURLs: string[]
  tags: string[]
  section: number
  countPerSection: number
  isSM: boolean
  height: number
  onClick?: (imgName: string, index: number) => void
}) {
  const imgs = imageURLs.slice(countPerSection * section, countPerSection * (section + 1))
  const tgs = tags.slice(countPerSection * section, countPerSection * (section + 1))
  let pad: any[] = []
  if (isSM && imgs.length !== countPerSection) {
    pad = new Array(countPerSection - imgs.length).fill(0)
  }
  const normalTag = isSM && height > 130
  return (
    <Box
      mx={isSM ? 8 : countPerSection === 1 ? 4 : 2}
      style={{height}}
      display="flex"
      alignItems="center">
      <Box mt={isSM || countPerSection === 1 ? 4 : 2} style={{width: '100%'}}>
        <Grid container spacing={isSM ? 4 : 2} justify="center">
          {imgs.map((img, i) => {
            const tag = tgs[i]
            return (
              <Grid item xs={Math.floor(12 / countPerSection) as any} key={i}>
                <Box
                  style={{
                    width: '100%',
                    height: height - 40,
                    borderRadius: 8,
                    backgroundColor: '#999999',
                    position: 'relative',
                  }}>
                  <img
                    style={{
                      borderRadius: 8,
                      padding: 0,
                      height: height - 40,
                      overflow: 'hidden',
                      objectFit: 'cover',
                      cursor: onClick ? 'pointer' : 'default',
                    }}
                    width="100%"
                    src={img}
                    onClick={() => onClick && onClick(img, section * countPerSection + i)}></img>
                  {tag ? (
                    <Box
                      style={{
                        padding: normalTag ? '8px 10px 6px 10px' : '4px 5px 3px 5px',
                        backgroundColor: 'white',
                        position: 'absolute',
                        bottom: normalTag ? 10 : 5,
                        left: normalTag ? 10 : 5,
                        borderRadius: 6,
                      }}>
                      <Typography
                        align="center"
                        style={{
                          fontSize: normalTag ? '14px' : '12px',
                          lineHeight: normalTag ? '21px' : '18px',
                          fontWeight: 'bold',
                          color: '#55c122',
                        }}>
                        {tag}
                      </Typography>
                    </Box>
                  ) : null}
                </Box>
              </Grid>
            )
          })}
          {pad.map((_, i) => (
            <Grid item xs={12} sm={4} key={3 - i}></Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  )
}

export interface ImageCarouselProps {
  carouselIndex: number
  isSM: boolean
  imageURLs: string[]
  tags: string[]
  countPerSection: number
  itemHeight: number
  indicators?: boolean
  navButtonsAlwaysInvisible?: boolean
  onClick?: (imageName: string, index: number) => void
  onCarouselIndexChange: (index: number, active: number) => void
}
export const ImageCarousel = (props: ImageCarouselProps) => {
  const {
    carouselIndex,
    isSM,
    imageURLs,
    tags,
    countPerSection,
    itemHeight,
    indicators,
    navButtonsAlwaysInvisible,
    onClick,
    onCarouselIndexChange,
  } = props
  const sectionCount = Math.floor((imageURLs.length + countPerSection - 1) / countPerSection)
  const navButtonsPropsStyle: any =
    isSM || countPerSection === 1
      ? {
          backgroundColor: 'transparent',
          color: '#666666',
          margin: isSM ? 14 : -10,
          marginTop: 0,
        }
      : {
          backgroundColor: 'transparent',
          color: '#666666',
          margin: -7,
          padding: 0,
        }
  return (
    <Carousel
      autoPlay={false}
      animation="slide"
      indicators={indicators || indicators === undefined}
      navButtonsAlwaysVisible={!navButtonsAlwaysInvisible}
      navButtonsAlwaysInvisible={navButtonsAlwaysInvisible || false}
      navButtonsProps={{
        // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
        style: navButtonsPropsStyle,
        className: '',
      }}
      activeIndicatorIconButtonProps={{
        style: {
          color: BRAND_YELLOW,
          backgroundColor: BRAND_YELLOW,
          margin: '6px',
        },
        className: '',
      }}
      indicatorIconButtonProps={{
        style: {
          color: '#dddddd',
          backgroundColor: '#dddddd',
          margin: '6px',
        },
        className: '',
      }}
      IndicatorIcon={
        <div
          style={{
            padding: 0,
            width: '8px',
            height: '8px',
            borderRadius: '4px',
          }}></div>
      }
      index={carouselIndex}
      changeOnFirstRender={false}
      onChange={onCarouselIndexChange}>
      {new Array(sectionCount).fill(0).map((_, i) => (
        <Item
          key={i}
          imageURLs={imageURLs}
          tags={tags}
          countPerSection={countPerSection}
          section={i}
          isSM={isSM}
          height={itemHeight}
          onClick={onClick}
        />
      ))}
    </Carousel>
  )
}
