import firebase from 'firebase/app'
import React from 'react'
import {parsePhoneNumberFromString} from 'libphonenumber-js'
import Logger from 'js-logger'
import {getClientIPData} from './ClientIPService'
import {getLandingData} from './LandingService'

let authPending = false

export function isAuthPending(): boolean {
  return authPending
}
export function setAuthPending(pending: boolean) {
  authPending = pending
}
function getNormalizedPhoneNumber(phoneNumber: string): string {
  const ph = parsePhoneNumberFromString(phoneNumber, 'US')
  if (ph) {
    return ph.number.toString()
  } else {
    throw Error('Failed to parse phone number: ' + phoneNumber)
  }
}

export async function phoneSignIn(
  phoneNumber: string,
  captchaRef: React.MutableRefObject<null>,
): Promise<firebase.auth.ConfirmationResult> {
  const phone = getNormalizedPhoneNumber(phoneNumber)

  return await firebase.auth().signInWithPhoneNumber(
    phone,
    new firebase.auth.RecaptchaVerifier(captchaRef.current, {
      size: 'invisible',
      callback: (response: any) => {
        // console.info('>>> Invisible Captcha', response)
        // reCAPTCHA solved, allow signInWithPhoneNumber.
      },
    }),
  )
}

function getUID(): string {
  let uid = '?'
  const user = firebase.auth().currentUser
  if (user) {
    uid = user.uid
  }
  return uid
}

const eventTimeMap: any = {}

export async function writeEvent(name: string, data: any, id?: string): Promise<void> {
  // throttle: at most one event of the same name in one minute
  const now = Date.now()
  const ts = eventTimeMap[name]
  if (ts) {
    if (now < ts + 60000) {
      // skip
      Logger.info('skip recently logged event:', name)
      return Promise.resolve()
    }
  }
  // skip B010 code for now
  if (data.code === 'B010') {
    return Promise.resolve()
  }
  eventTimeMap[name] = now
  const uid = getUID()
  let client: any = null
  try {
    client = await getClientIPData()
  } catch (e) {
    // ignore
  }
  if (!id) {
    id = firebase.firestore().collection('events').doc().id
  }

  if (!data.timestamp) {
    const timestamp = new Date().toString()
    data = {...data, client, uid, timestamp}
  } else {
    data = {...data, client, uid}
  }

  if (name !== 'landing' && name !== 'enter_address') {
    const landingData = getLandingData()
    if (landingData) {
      const {id: landing_id, origin, referrer, timestamp: landing_timestamp} = landingData
      data = {...data, origin, referrer, landing_id, landing_timestamp}
    }
  }

  firebase.analytics().logEvent(name, {
    ...data,
    id,
    type: 'firestore_event',
  })

  return firebase.firestore().collection('events').doc(id).set({
    name,
    data,
  })
}
