import {useState} from 'react'
import Box from '@material-ui/core/Box'
import {PaverWallOptions, PaverWallOption} from '../../../../model/pavers'
import {ParagraphHeader, ParagraphSubHeader, FullScreenImageDialog} from '../../../common/common'
import {PaverWallConfigModel, newPaverWallConfigModel} from '../../../../model/common'
import {TDIOptionComponent} from '../common/TDIOptionComponent'
import {TDIRadioOptionComponent} from '../common/TDIRadioOptionComponent'

const PaverWallOptionComp = ({
  po,
  selected,
  onClick,
  isSM,
  final,
  image,
  alt,
  onClickImage,
}: {
  po: PaverWallOption
  selected: boolean
  onClick: () => void
  isSM: boolean
  final: boolean
  image: string
  alt: string
  onClickImage: () => void
}) => {
  return isSM ? (
    <TDIOptionComponent
      selected={selected}
      onClick={onClick}
      isSM={isSM}
      final={final}
      image={image}
      alt={alt}
      onClickImage={onClickImage}
      name={po.style}
      description={po.description}
    />
  ) : (
    <TDIRadioOptionComponent
      selected={selected}
      onClick={onClick}
      isSM={isSM}
      final={final}
      imageURL={image}
      onClickImage={onClickImage}
      name={po.style}
      alt={alt}
      description={po.description}
      price={''}
      disabled={false}
      disabledText={''}
    />
  )
}

export const PaverWallStyleSection = ({
  style,
  final,
  isSM,
  onUpdate,
}: {
  style?: string
  final: boolean
  isSM: boolean
  onUpdate: (model: PaverWallConfigModel) => void
}) => {
  const [open, setOpen] = useState(false)
  const [image, setImage] = useState('')
  return (
    <Box>
      <Box>
        <ParagraphHeader>Style</ParagraphHeader>
        <ParagraphSubHeader>
          Choose the type of paving stones that will be used for your patio wall.
        </ParagraphSubHeader>
      </Box>
      <Box style={{marginTop: 20}} display="flex" justifyContent="space-between" flexWrap="wrap">
        {PaverWallOptions.map((po, i) => (
          <PaverWallOptionComp
            key={po.style}
            po={po}
            image={`/images/wall/${po.image}`}
            alt={po.alt}
            selected={po.style === style}
            onClick={() => {
              const m = newPaverWallConfigModel()
              onUpdate({...m, style: po.style})
            }}
            onClickImage={() => setImage(`/images/wall/${po.image}`)}
            isSM={isSM}
            final={final}
          />
        ))}
      </Box>
      <FullScreenImageDialog open={image !== ''} onClose={() => setImage('')} image={image} />
    </Box>
  )
}
