import Box from '@material-ui/core/Box'
import {ParagraphHeader, ParagraphSubHeader} from '../../../common/common'
import {getPaverFirePitColors} from './firepit'
import {PaverFirePitConfigModel} from '../../../../model/common'
import {PaverColorOptionComp} from './PaverColor'

export const PaverFirePitColorSection = ({
  shape,
  color,
  final,
  isSM,
  onUpdate,
}: {
  shape?: string
  color?: string
  final: boolean
  isSM: boolean
  onUpdate: (model: PaverFirePitConfigModel) => void
}) => {
  if (!shape) {
    return null
  }
  const colors = getPaverFirePitColors(shape)
  const n = isSM ? 4 : 2
  const w = isSM ? '22%' : '47%'
  let placeholders: any[] = []
  const r = colors.length % n
  if (r) {
    placeholders = new Array(n - r).fill(0)
  }
  return (
    <Box>
      <ParagraphHeader>Color</ParagraphHeader>
      <ParagraphSubHeader>Choose the color of your fire pit.</ParagraphSubHeader>

      <Box style={{marginTop: 20}} display="flex" justifyContent="space-between" flexWrap="wrap">
        {colors.map((pco, i) => (
          <PaverColorOptionComp
            key={pco.name}
            pco={pco}
            selected={pco.name === color}
            onClick={() => {
              onUpdate({shape, color: pco.name})
            }}
            isSM={isSM}
            final={final}
            imagePath={'/images/firepit/color/'}
          />
        ))}
        {placeholders.map((_, i) => (
          <Box key={i} style={{width: w}}></Box>
        ))}
      </Box>
    </Box>
  )
}
