import {useState} from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import {SectionTitle} from './SectionTitle'
import {IconLine} from './IconLine'
import {Tool, Calendar, Mail, Monitor, Smile, Frown} from 'react-feather'
import {Typography} from '@material-ui/core'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import withWidth, {isWidthUp} from '@material-ui/core/withWidth'
import {BRAND_YELLOW} from '../../config/styles'
import Divider from '@material-ui/core/Divider'
import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon'
import {RText2016, RText2218, RText1614} from '../../components/common/RText'

const styles = (theme: Theme) =>
  createStyles({
    title: {fontWeight: 600, color: '#333333'},
    description: {fontWeight: 300, color: '#333333'},
    smallText: {fontSize: '14px', lineHeight: '21px', fontWeight: 300, color: '#333333'},
    smallTextBold: {fontSize: '14px', lineHeight: '21px', fontWeight: 600, color: '#333333'},
    mediumText: {fontSize: '16px', lineHeight: '24px', fontWeight: 300, color: '#333333'},
    smallEm: {fontWeight: 600, color: BRAND_YELLOW},
  })

const useStyles = makeStyles(styles)

const CustomButton = ({
  onClick,
  classes,
  headingText,
  buttonText,
}: {
  onClick: () => void
  classes: any
  headingText: string
  buttonText: string
}) => {
  const [hover, setHover] = useState(false)
  return (
    <Box
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        width: '47%',
        padding: 14,
        borderRadius: 8,
        border: hover ? '1px solid #f4bf03' : '1px solid #dddddd',
        cursor: 'pointer',
      }}>
      <Typography align="center" className={classes.smallText}>
        {headingText}
      </Typography>
      <RText1614 align="center" className={classes.smallEm}>
        {buttonText}
      </RText1614>
    </Box>
  )
}
interface IconTextContent {
  Icon: any
  text: string
}
const iconTexts: IconTextContent[] = [
  {
    Icon: Monitor,
    text: 'Pick your custom project, get instant Smart Proposal with guaranteed price',
  },
  {
    Icon: Calendar,
    text: 'Meet with us at your home to finalize the project details',
  },
  {
    Icon: Smile,
    text: 'Revnovation begins and is executed with quality, stress-free',
  },
]
const IconText = ({classes, text, Icon}: {text: string; classes: any; Icon: any}) => {
  return (
    <Box mt={5} width="30%" p={2}>
      <Box display="flex" justifyContent="center">
        <Icon size={50} />
      </Box>
      <Box mt={1} style={{padding: '10px 10px'}}>
        <Typography align="center" className={classes.mediumText}>
          {text}
        </Typography>
      </Box>
    </Box>
  )
}

const CirclePoint = () => {
  return (
    <Box display="flex" zIndex="1" justifyContent="center" style={{width: '30%', height: '16px'}}>
      <Box
        style={{
          width: '16px',
          height: '16px',
          borderRadius: 8,
          border: '3px solid #f4bf03',
          backgroundColor: 'white',
        }}></Box>
    </Box>
  )
}
const CenterText = ({text, subtext, classes}: {text: string; subtext?: string; classes: any}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      // zIndex="1"
      justifyContent="flex-start"
      alignItems="center"
      style={{width: '30%', height: '50px'}}>
      <Typography align="center" className={classes.smallText}>
        {text}
      </Typography>
      {subtext ? (
        <Typography align="center" className={classes.smallText} style={{color: '#999999'}}>
          {subtext}
        </Typography>
      ) : null}
    </Box>
  )
}

const HowBrickoWorksDesktopSVG = ({classes}: {classes: any}) => {
  return (
    <Box mt={3} mx={10}>
      <Box display="flex" justifyContent="center">
        <RText2218 align="center" className={classes.title}>
          How it Works?
        </RText2218>
      </Box>
      <Box mx={5} display="flex" justifyContent="space-between" style={{position: 'relative'}}>
        {iconTexts.map((it, i) => (
          <IconText key={i} classes={classes} text={it.text} Icon={it.Icon} />
        ))}

        <Box style={{position: 'absolute', top: 20, left: '25%', transform: 'rotate(135deg)'}}>
          <SvgIcon htmlColor="#f9df81" style={{fontSize: 125}}>
            <path d="M 3 2 L 4 2 L 2 0 L 0 2 L 1 2 A 24 24 0 0 0 22 24 L 22 22 A 22 22 0 0 1 3 2" />
          </SvgIcon>
        </Box>
        <Box style={{position: 'absolute', top: 20, right: '25%', transform: 'rotate(135deg)'}}>
          <SvgIcon htmlColor="#f9df81" style={{fontSize: 125}}>
            <path d="M 3 2 L 4 2 L 2 0 L 0 2 L 1 2 A 24 24 0 0 0 22 24 L 22 22 A 22 22 0 0 1 3 2" />
          </SvgIcon>
        </Box>
      </Box>
      <Box
        mx={5}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        style={{height: '32px', position: 'relative'}}>
        <Divider
          style={{
            height: '1px',
            width: '100%',
            backgroundColor: '#dddddd',
            position: 'absolute',
            top: 16,
            left: 0,
          }}
        />
        <CirclePoint />
        <CirclePoint />
        <CirclePoint />
      </Box>

      <Box
        mt={3}
        mx={5}
        pb={5}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        style={{height: '32px', position: 'relative'}}>
        <CenterText classes={classes} text="Minutes" />
        <CenterText classes={classes} text="2 days" />
        <CenterText classes={classes} text="~2 weeks" subtext="Depending on Renovation" />
      </Box>

      <Box my={3}>
        <Divider style={{height: '10px', backgroundColor: '#eeeeee'}} />
      </Box>
      <Box mt={6}>
        <Box>
          <Typography align="center" className={classes.smallText}>
            Compared to
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center">
        <RText2218 align="center" className={classes.title}>
          The Old Fashioned Way
        </RText2218>
      </Box>
      <Box mt={2} style={{height: '560px', position: 'relative'}}>
        <Box
          style={{
            position: 'absolute',
            bottom: 'calc(25% + 260px)',
            left: 'calc(50% + 10px)',
            width: 'calc(50% - 125px)',
          }}>
          <Divider orientation="vertical" style={{position: 'absolute', left: 12, top: -9}} />
          <Box
            style={{
              width: '16px',
              height: '16px',
              borderRadius: 8,
              border: '3px solid #666666',
              backgroundColor: 'white',
              position: 'absolute',
              top: -9,
              left: 5,
            }}></Box>
          <Box style={{marginLeft: 31, marginTop: -10, paddingBottom: 30}}>
            <Typography className={classes.smallTextBold}>Research</Typography>
            <Typography className={classes.smallText}>
              Spend a frustrating amount of time trying to find quality contractors
            </Typography>
          </Box>
        </Box>
        <Box
          style={{
            position: 'absolute',
            top: 'calc(50% - 125px)',
            left: 'calc(50% - 125px)',
            width: 250,
            height: 250,
            borderRadius: 125,
          }}>
          <img
            src="/images/bricko-help.webp"
            style={{
              position: 'absolute',
              top: 32,
              left: 32,
              width: 186,
              height: 186,
              borderRadius: 93,
              objectFit: 'cover',
            }}
          />
          <Box style={{position: 'absolute', bottom: -5, left: -5}}>
            <SvgIcon htmlColor="#eeeeee" style={{fontSize: 125}}>
              <path d="M 3 2 L 4 2 L 2 0 L 0 2 L 1 2 A 24 24 0 0 0 22 24 L 22 22 A 22 22 0 0 1 3 2" />
            </SvgIcon>
          </Box>
          <Box style={{position: 'absolute', top: -7, left: -2, transform: 'rotate(90deg)'}}>
            <SvgIcon htmlColor="#eeeeee" style={{fontSize: 125}}>
              <path d="M 3 2 L 4 2 L 2 0 L 0 2 L 1 2 A 24 24 0 0 0 22 24 L 22 22 A 22 22 0 0 1 3 2" />
            </SvgIcon>
          </Box>
          <Box style={{position: 'absolute', top: -5, right: -5, transform: 'rotate(180deg)'}}>
            <SvgIcon htmlColor="#eeeeee" style={{fontSize: 125}}>
              <path d="M 3 2 L 4 2 L 2 0 L 0 2 L 1 2 A 24 24 0 0 0 22 24 L 22 22 A 22 22 0 0 1 3 2" />
            </SvgIcon>
          </Box>
          <Box
            style={{
              position: 'absolute',
              bottom: -7,
              right: -2,
              transform: 'rotate(270deg)',
            }}>
            <SvgIcon htmlColor="#eeeeee" style={{fontSize: 125}}>
              <path d="M 3 2 L 4 2 L 2 0 L 0 2 L 1 2 A 24 24 0 0 0 22 24 L 22 22 A 22 22 0 0 1 3 2" />
            </SvgIcon>
          </Box>
        </Box>

        <Box
          style={{
            position: 'absolute',
            top: 'calc(25% + 260px)',
            left: 'calc(50% - 10px)',
            height: '100px',
            width: 'calc(50% - 125px)',
          }}>
          <Divider
            orientation="vertical"
            style={{position: 'absolute', left: -13, top: 8, height: 50}}
          />
          <Box
            style={{
              width: '16px',
              height: '16px',
              borderRadius: 8,
              border: '3px solid #666666',
              backgroundColor: 'white',
              position: 'absolute',
              top: 58,
              left: -20,
            }}></Box>
          <Box style={{marginLeft: 0, marginTop: 56}}>
            <Typography className={classes.smallTextBold}>Meet & Pray</Typography>
            <Typography className={classes.smallText}>
              Take time off work to meet with several contractors and pray for a decent quote.
            </Typography>
          </Box>
        </Box>

        <Box
          style={{
            position: 'absolute',
            top: 'calc(50% + 10px)',
            left: 'calc(50% + 125px)',
            height: '100px',
            width: 'calc(50% - 125px)',
          }}>
          <Divider style={{position: 'absolute', left: 4, top: 10, width: '50px'}} />
          <Box
            style={{
              width: '16px',
              height: '16px',
              borderRadius: 8,
              border: '3px solid #666666',
              backgroundColor: 'white',
              position: 'absolute',
              top: 2,
              left: 44,
            }}></Box>
          <Box style={{marginLeft: 69, marginTop: 1}}>
            <Typography className={classes.smallTextBold}>Call & Wait</Typography>
            <Typography className={classes.smallText}>
              Play phone tag with contractors, hoping for a call back.
            </Typography>
          </Box>
        </Box>

        <Box
          style={{
            position: 'absolute',
            top: 'calc(50% - 10px)',
            right: 'calc(50% + 125px)',
            height: '100px',
            width: 'calc(50% - 125px)',
          }}>
          <Divider style={{position: 'absolute', right: 4, top: -14, width: 50}} />
          <Box
            style={{
              width: '16px',
              height: '16px',
              borderRadius: 8,
              border: '3px solid #666666',
              backgroundColor: 'white',
              position: 'absolute',
              top: -22,
              right: 49,
            }}></Box>
          <Box style={{marginRight: 75, marginTop: -24}}>
            <Typography align="right" className={classes.smallTextBold}>
              Haggle & Cry
            </Typography>
            <Typography align="right" className={classes.smallText}>
              Get quote, haggle on price, cry at the lack of progress. Go back to researching.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center">
        <Box
          display="flex"
          style={{
            borderRadius: 30,
            padding: '18px 30px',
            backgroundColor: '#f5f5f5',
          }}>
          <Frown />
          <Typography className={classes.mediumText} style={{marginLeft: 10}}>
            Several months and back at square 1
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
const UseHowItWorksImage = true

export interface HowBrickoWorksProps {
  onClickGetSmartProposal: () => void
}
export const HowBrickoWorks = withWidth()(function (props: HowBrickoWorksProps) {
  const isMD = isWidthUp('md', (props as any).width)
  const isSM = isWidthUp('sm', (props as any).width)
  const {onClickGetSmartProposal} = props
  const classes = useStyles()
  return (
    <Container style={{borderBottom: isSM ? 'none' : '5px solid #f5f5f5'}}>
      <Box
        style={{
          marginTop: '50px',
          marginBottom: isMD ? '50px' : '30px',
          paddingLeft: isMD ? 0 : 4,
          paddingRight: isMD ? 0 : 4,
        }}>
        <Box>
          <SectionTitle>Introducing Bricko!</SectionTitle>
        </Box>
        <Box>
          <RText2016 variant="h3" className={classes.description}>
            Bricko is a full service home renovation platform that brings simplicity, speed, and
            transparency to home renovation projects by taking an AI-based approach.
          </RText2016>
        </Box>
        <Box mt={3}>
          <RText2016 variant="h3" className={classes.description}>
            With our Bricko Smart Proposals, you get instant and guaranteed pricing on a home
            renovation of interest with no talking to humans!
          </RText2016>
        </Box>
        {isSM ? (
          UseHowItWorksImage ? (
            <Box mt={3}>
              <Box display="flex" justifyContent="center">
                <RText2218 variant="h3" align="center" className={classes.title}>
                  How it Works?
                </RText2218>
              </Box>
              <Box mt={2} display="flex" justifyContent="center">
                <img
                  alt="Bricko - the new way for home renovation"
                  width="100%"
                  src="/images/bricko-how-it-works-new-v2.webp"
                  style={{maxWidth: '840px'}}
                />
              </Box>
              <Box my={3}>
                <Divider style={{height: '10px', backgroundColor: '#eeeeee'}} />
              </Box>
              <Box mt={4}>
                <Box>
                  <Typography align="center" className={classes.smallText}>
                    Compared to
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" justifyContent="center">
                <RText2218 variant="h3" align="center" className={classes.title}>
                  The Old Fashioned Way
                </RText2218>
              </Box>
              <Box mt={4} display="flex" justifyContent="center">
                <img
                  alt="Bricko - compare with the old way for home renovation"
                  width="100%"
                  src="/images/bricko-how-it-works-old-v2.webp"
                  style={{maxWidth: '992px'}}
                />
              </Box>
            </Box>
          ) : (
            <HowBrickoWorksDesktopSVG classes={classes} />
          )
        ) : UseHowItWorksImage ? (
          <Box mt={3}>
            <Box display="flex" justifyContent="center">
              <RText2218 variant="h3" align="center" className={classes.title}>
                How it Works?
              </RText2218>
            </Box>
            <Box mt={2}>
              <img width="100%" src="/images/bricko-how-it-works-new-mobile-v2.webp" />
            </Box>
            <Box my={3}>
              <Divider style={{height: '10px', backgroundColor: '#eeeeee'}} />
            </Box>
            <Box mt={4}>
              <Box>
                <Typography align="center" className={classes.smallText}>
                  Compared to
                </Typography>
              </Box>
            </Box>
            <Box display="flex" justifyContent="center">
              <RText2218 variant="h3" align="center" className={classes.title}>
                The Old Fashioned Way
              </RText2218>
            </Box>
            <Box mt={4}>
              <img width="100%" src="/images/bricko-how-it-works-old-mobile-v2.webp" />
            </Box>
          </Box>
        ) : (
          <Box mt={3} style={{border: '2px dashed #dddddd', borderRadius: 8}}>
            <Box
              style={{
                marginTop: '30px',
                // marginBottom: '50px',
                padding: isMD ? '0px 50px' : '0px 15px',
              }}
              display="flex"
              alignItems="center"
              flexDirection="column">
              <Box>
                <RText2218 className={classes.title}>How it Works?</RText2218>
              </Box>
              <IconLine
                Icon={Tool}
                text="Choose a project you are interested in and customize to match your style"
                isSM={isMD}
              />
              <IconLine
                Icon={Mail}
                text="View instant Bricko Smart Proposal including upfront price"
                isSM={isMD}
              />
              <IconLine
                Icon={Calendar}
                text="Schedule an appointment to meet with us at your home to finalize the project details"
                isSM={isMD}
              />

              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                style={{width: '100%', marginTop: 30}}>
                <CustomButton
                  classes={classes}
                  headingText="Starting a new project?"
                  buttonText="Get Instant Smart Proposal"
                  onClick={onClickGetSmartProposal}
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Container>
  )
})
