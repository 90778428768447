import {normalizeString, formatPrice} from '../utils/strings'
import {HidePergola} from '../config/config'
import {backyardPaverProjectPackages} from './pavers'
import {ProjectTypeValue} from './project'
import Logger from 'js-logger'

function getDateString(d: Date): string {
  const mm = d.getMonth() + 1
  const m = mm < 10 ? '0' + mm : '' + mm
  const dd = d.getDate()
  const ds = dd < 10 ? '0' + dd : '' + dd
  const s = `${d.getFullYear()}-${m}-${ds}`
  return s
}

export function getDefaultDate(): string {
  const d = new Date(Date.now() + 86400000 * 3)
  return getDateString(d)
}
export function getMinDate(): string {
  const d = new Date(Date.now() + 86400000 * 2)
  return getDateString(d)
}
export function getMaxDate(): string {
  const d = new Date(Date.now() + 86400000 * 16)
  return getDateString(d)
}

export interface ContactModel {
  name: string
  phone: string
  email: string
}

export function newContactModel(): ContactModel {
  return {
    name: '',
    phone: '',
    email: '',
  }
}
export function isContactModelValid(x: ContactModel): boolean {
  return x.name && x.email && x.phone ? true : false
}

export enum AppointmentState {
  None = 0,
  Comments = 1,
  Appointment = 2,
}

export interface ScheduleModel {
  day: string
  ampm: string
  appointmentSet: boolean
  appointmentState: AppointmentState
  comments: string
}
export function newScheduleModel(): ScheduleModel {
  return {
    day: getDefaultDate(),
    ampm: 'AM',
    appointmentSet: false,
    appointmentState: AppointmentState.Comments,
    comments: '',
  }
}
export function isScheduleModelValid(x: ScheduleModel): boolean {
  switch (x.appointmentState) {
    case AppointmentState.None:
      return false
    case AppointmentState.Appointment:
      return x.day && x.ampm ? true : false
    case AppointmentState.Comments:
      Logger.info(`>>> isScheduleModelValid: comments="${x.comments}"`)
      return !!x.comments
  }
}

export interface AppointmentModel extends ContactModel, ScheduleModel {}
export function newAppointmentModel(): AppointmentModel {
  return {
    name: '',
    phone: '',
    email: '',
    day: getDefaultDate(),
    ampm: 'AM',
    appointmentSet: false,
    appointmentState: AppointmentState.Comments,
    comments: '',
  }
}
export function isAppointmentModelValid(x: AppointmentModel): boolean {
  return isContactModelValid(x) && isScheduleModelValid(x)
}

export interface MailingAddressModel {
  addr: string
  addr2: string
  city: string
  state: string
  zipCode: string
}
export function isMailingAddressModelValid(x: MailingAddressModel): boolean {
  return x.addr && x.city && x.state && x.zipCode ? true : false
}
export function newMailingAddressModel(): MailingAddressModel {
  return {
    addr: '',
    addr2: '',
    city: '',
    state: '',
    zipCode: '',
  }
}
export function getDocID(x: MailingAddressModel): string {
  return normalizeString(`${x.addr}${x.zipCode}`)
}
export function mailingAddressModelToString(x: MailingAddressModel): string {
  if (x.addr && x.zipCode) {
    return `${x.addr}, ${x.city}, ${x.state}, ${x.zipCode}`
  }
  return ''
}

export interface PaverConfigModel {
  vendor: string
  style: string
  color: string
  borderColor: string
  pattern: string
}
export function newPaverConfigModel(): PaverConfigModel {
  return {
    vendor: '',
    style: '',
    color: '',
    borderColor: '',
    pattern: '',
  }
}
export function isPaverConfigModelValid(x: PaverConfigModel): boolean {
  return x.vendor && x.style && x.color && x.borderColor && x.pattern ? true : false
}
export interface ProjectModelBase {
  final: boolean
  price: string
  appreciation: string
}
function newProjectModelBase(): ProjectModelBase {
  return {
    final: false,
    price: '',
    appreciation: '',
  }
}
function isProjectModelBaseValid(x: ProjectModelBase): boolean {
  return x.price !== ''
}
export interface PaverProjectModel extends PaverConfigModel, ScheduleModel, ProjectModelBase {}
export function newPaverProjectModel(): PaverProjectModel {
  const m1 = newPaverConfigModel()
  const m2 = newScheduleModel()
  const m3 = newProjectModelBase()
  return {
    ...m1,
    ...m2,
    ...m3,
  }
}
export function isPaverProjectModelValid(x: PaverProjectModel): boolean {
  return isPaverConfigModelValid(x) && isScheduleModelValid(x) && isProjectModelBaseValid(x)
}
export interface PaverWallConfigModel {
  style: string
  color: string
}
export function newPaverWallConfigModel(): PaverWallConfigModel {
  return {
    style: '',
    color: '',
  }
}
function isPaverWallConfigModelValid(x: BackyardPaverConfigModel): boolean {
  const {size, package: pkg} = x
  const isBase = size === 'Cozy'
  if (isBase) {
    return true
  }
  return x && x.wall && x.wall.style && x.wall.color ? true : false
}
export interface PaverFirePitConfigModel {
  shape: string
  color: string
}
export function newPaverFirePitConfigModel(): PaverFirePitConfigModel {
  return {
    shape: '',
    color: '',
  }
}
function isPaverFirePitConfigModelValid(x: BackyardPaverConfigModel): boolean {
  const {size, package: pkg} = x
  const isPremium = size !== 'Cozy' && pkg === backyardPaverProjectPackages[2].name
  if (!isPremium) {
    return true
  }
  return x && x.firepit && x.firepit.shape && x.firepit.color ? true : false
}
export interface PergolaConfigModel {
  style: string
}
export function newPergolaConfigModel(): PergolaConfigModel {
  return {
    style: '',
  }
}
function isPergolaConfigModelValid(x: BackyardPaverConfigModel): boolean {
  const {size, package: pkg} = x
  const isPremium = size !== 'Cozy' && pkg === backyardPaverProjectPackages[2].name
  if (HidePergola || !isPremium) {
    return true
  }
  return x && x.pergola && x.pergola.style ? true : false
}

export interface DrivewayPaverConfigModel extends PaverConfigModel {
  size: string
}
export function newDrivewayPaverConfigModel(): DrivewayPaverConfigModel {
  const m1 = newPaverConfigModel()
  return {
    ...m1,
    size: 'Standard',
  }
}
export function isDrivewayPaverConfigModelValid(x: DrivewayPaverConfigModel): boolean {
  return isPaverConfigModelValid(x) && x.size !== ''
}

export interface BackyardPaverConfigModel extends PaverConfigModel {
  size: string
  package: string
  wall?: PaverWallConfigModel
  firepit?: PaverFirePitConfigModel
  pergola?: PergolaConfigModel
}

export function newBackyardPaverConfigModel(): BackyardPaverConfigModel {
  const m1 = newPaverConfigModel()
  return {
    ...m1,
    size: 'Cozy',
    package: 'Entertainment',
    // wall: newPaverWallConfigModel(),
    // firepit: newPaverFirePitConfigModel(),
    // pergola: newPergolaConfigModel(),
  }
}
export function isBackyardPaverConfigModelValid(x: BackyardPaverConfigModel): boolean {
  return (
    isPaverConfigModelValid(x) &&
    x.size !== '' &&
    x.package !== '' &&
    isPaverWallConfigModelValid(x) &&
    isPaverFirePitConfigModelValid(x) &&
    isPergolaConfigModelValid(x)
  )
}
export function sanitizeBackyardPaverConfigModel(x: BackyardPaverConfigModel) {
  const pkg = x.package
  if (pkg === backyardPaverProjectPackages[1].name) {
    delete x.firepit
    delete x.pergola
  } else if (pkg == backyardPaverProjectPackages[0].name) {
    delete x.firepit
    delete x.pergola
    delete x.wall
  }
}
export interface BackyardPaverProjectModel
  extends BackyardPaverConfigModel,
    ScheduleModel,
    ProjectModelBase {
  backyardPaversRegularBase: number
  backyardPaversRegularEntertainment: number
  backyardPaversExtendedEntertainment: number
  backyardPaversExtendedPremiumEntertainment: number
  backyardPaversRegularBaseAppreciation: number
  backyardPaversRegularEntertainmentAppreciation: number
  backyardPaversExtendedEntertainmentAppreciation: number
  backyardPaversExtendedPremiumEntertainmentAppreciation: number
  backyardPaversRegularSqft: number
  backyardPaversExtendedSqft: number
}

export function newBackyardPaverProjectModel(): BackyardPaverProjectModel {
  const m1 = newBackyardPaverConfigModel()
  const m2 = newScheduleModel()
  const m3 = newProjectModelBase()
  return {
    ...m1,
    ...m2,
    ...m3,
    backyardPaversRegularBase: 0,
    backyardPaversRegularEntertainment: 0,
    backyardPaversExtendedEntertainment: 0,
    backyardPaversExtendedPremiumEntertainment: 0,
    backyardPaversRegularBaseAppreciation: 0,
    backyardPaversRegularEntertainmentAppreciation: 0,
    backyardPaversExtendedEntertainmentAppreciation: 0,
    backyardPaversExtendedPremiumEntertainmentAppreciation: 0,
    backyardPaversRegularSqft: 0,
    backyardPaversExtendedSqft: 0,
  }
}
export function isBackyardPaverProjectModelValid(x: BackyardPaverProjectModel): boolean {
  return isBackyardPaverConfigModelValid(x) && isScheduleModelValid(x) && isProjectModelBaseValid(x)
}

export function getBackyardPaverProjectModelPrice(m: OrderModel): string {
  const x = m.backyardPaverProject
  if (x && x.backyardPaversRegularEntertainment) {
    let price = ''
    const discount = m.discount
    if (x.size === 'Cozy') {
      if (x.package === backyardPaverProjectPackages[0].name) {
        price = `${formatPrice(x.backyardPaversRegularBase - discount)}`
      } else {
        price = `${formatPrice(x.backyardPaversRegularEntertainment - discount)}`
      }
    } else {
      if (x.package === backyardPaverProjectPackages[1].name) {
        price = `${formatPrice(x.backyardPaversExtendedEntertainment - discount)}`
      } else {
        price = `${formatPrice(x.backyardPaversExtendedPremiumEntertainment - discount)}`
      }
    }
    return price
  }
  return ''
}

export function getBackyardPaverProjectModelAppreciation(x: BackyardPaverProjectModel): string {
  if (x.backyardPaversRegularEntertainment) {
    let appreciation = formatPrice(x.backyardPaversRegularEntertainmentAppreciation)
    if (x.size === 'Cozy') {
      if (x.package === backyardPaverProjectPackages[0].name) {
        appreciation = `${formatPrice(x.backyardPaversRegularBaseAppreciation)}`
      } else {
        appreciation = `${formatPrice(x.backyardPaversRegularEntertainmentAppreciation)}`
      }
    } else {
      if (x.package === backyardPaverProjectPackages[1].name) {
        appreciation = `${formatPrice(x.backyardPaversExtendedEntertainmentAppreciation)}`
      } else {
        appreciation = `${formatPrice(x.backyardPaversExtendedPremiumEntertainmentAppreciation)}`
      }
    }
    return appreciation
  }
  return ''
}

export interface DrivewayPaverProjectModel
  extends DrivewayPaverConfigModel,
    ScheduleModel,
    ProjectModelBase {
  frontLayoutCategory: number
  sideStripWidth: number
  sideWalkWidth: number
  proposalPrice: number
  drivewayPaversWithWalkwaySideStrip: number
  drivewayPaversWithWalkwayNoSideStrip: number
  drivewayPaversWithoutWalkwaySideStrip: number
  drivewayPaversWithWalkwaySideStripExtended: number
  drivewayPaversWithWalkwayNoSideStripExtended: number
  drivewayPaversWithoutWalkwaySideStripExtended: number
  drivewayPaversWithWalkwaySideStripAppreciation: number
  drivewayPaversWithWalkwayNoSideStripAppreciation: number
  drivewayPaversWithoutWalkwaySideStripAppreciation: number
  drivewayPaversWithWalkwaySideStripExtendedAppreciation: number
  drivewayPaversWithWalkwayNoSideStripExtendedAppreciation: number
  drivewayPaversWithoutWalkwaySideStripExtendedAppreciation: number
  drivewayPaversWithWalkwaySideStripSqft: number
  drivewayPaversWithWalkwayNoSideStripSqft: number
  drivewayPaversWithoutWalkwaySideStripSqft: number
  drivewayPaversWithWalkwaySideStripExtendedSqft: number
  drivewayPaversWithWalkwayNoSideStripExtendedSqft: number
  drivewayPaversWithoutWalkwaySideStripExtendedSqft: number
}

export function newDrivewayPaverProjectModel(): DrivewayPaverProjectModel {
  const m1 = newDrivewayPaverConfigModel()
  const m2 = newScheduleModel()
  const m3 = newProjectModelBase()
  return {
    ...m1,
    ...m2,
    ...m3,
    frontLayoutCategory: 1,
    sideStripWidth: 0,
    sideWalkWidth: 0,
    proposalPrice: 0,
    drivewayPaversWithWalkwaySideStrip: 0,
    drivewayPaversWithWalkwayNoSideStrip: 0,
    drivewayPaversWithoutWalkwaySideStrip: 0,
    drivewayPaversWithWalkwaySideStripExtended: 0,
    drivewayPaversWithWalkwayNoSideStripExtended: 0,
    drivewayPaversWithoutWalkwaySideStripExtended: 0,
    drivewayPaversWithWalkwaySideStripAppreciation: 0,
    drivewayPaversWithWalkwayNoSideStripAppreciation: 0,
    drivewayPaversWithoutWalkwaySideStripAppreciation: 0,
    drivewayPaversWithWalkwaySideStripExtendedAppreciation: 0,
    drivewayPaversWithWalkwayNoSideStripExtendedAppreciation: 0,
    drivewayPaversWithoutWalkwaySideStripExtendedAppreciation: 0,
    drivewayPaversWithWalkwaySideStripSqft: 0,
    drivewayPaversWithWalkwayNoSideStripSqft: 0,
    drivewayPaversWithoutWalkwaySideStripSqft: 0,
    drivewayPaversWithWalkwaySideStripExtendedSqft: 0,
    drivewayPaversWithWalkwayNoSideStripExtendedSqft: 0,
    drivewayPaversWithoutWalkwaySideStripExtendedSqft: 0,
  }
}
export function isDrivewayPaverProjectModelValid(x: DrivewayPaverProjectModel): boolean {
  return isDrivewayPaverConfigModelValid(x) && isScheduleModelValid(x) && isProjectModelBaseValid(x)
}

export function getDrivewayPaverProjectModelPrice(m: OrderModel): string {
  const x = m.drivewayPaverProject
  if (x && x.drivewayPaversWithWalkwaySideStrip) {
    let price = ''
    const discount = m.discount
    const proposalPrice = x.proposalPrice ? x.proposalPrice - discount : 0
    const isExtendedDW = x.size === 'Extended'
    const proposalPriceStr = proposalPrice ? formatPrice(proposalPrice) : ''
    // if have proposal and not Extended SKU, then use price from proposal for driveway project
    switch (x.frontLayoutCategory) {
      case 3:
        price = isExtendedDW
          ? `${formatPrice(x.drivewayPaversWithoutWalkwaySideStripExtended - discount)}`
          : proposalPriceStr || `${formatPrice(x.drivewayPaversWithoutWalkwaySideStrip - discount)}`
        break
      case 2:
        price = isExtendedDW
          ? `${formatPrice(x.drivewayPaversWithWalkwayNoSideStripExtended - discount)}`
          : proposalPriceStr || `${formatPrice(x.drivewayPaversWithWalkwayNoSideStrip - discount)}`
        break
      default:
        price = isExtendedDW
          ? `${formatPrice(x.drivewayPaversWithWalkwaySideStripExtended - discount)}`
          : proposalPriceStr || `${formatPrice(x.drivewayPaversWithWalkwaySideStrip - discount)}`
        break
    }
    return price
  }
  return ''
}
export function getDrivewayPaverProjectModelAppreciation(x: DrivewayPaverProjectModel): string {
  if (x.drivewayPaversWithWalkwaySideStripAppreciation) {
    let appreciation = ''
    const isExtendedDW = x.size === 'Extended'
    switch (x.frontLayoutCategory) {
      case 3:
        appreciation = isExtendedDW
          ? `${formatPrice(x.drivewayPaversWithoutWalkwaySideStripExtendedAppreciation)}`
          : `${formatPrice(x.drivewayPaversWithoutWalkwaySideStripAppreciation)}`
        break
      case 2:
        appreciation = isExtendedDW
          ? `${formatPrice(x.drivewayPaversWithWalkwayNoSideStripExtendedAppreciation)}`
          : `${formatPrice(x.drivewayPaversWithWalkwayNoSideStripAppreciation)}`
        break
      default:
        appreciation = isExtendedDW
          ? `${formatPrice(x.drivewayPaversWithWalkwaySideStripExtendedAppreciation)}`
          : `${formatPrice(x.drivewayPaversWithWalkwaySideStripAppreciation)}`
        break
    }
    return appreciation
  }
  return ''
}

export enum ShrubCoverage {
  Regular = 'Regular',
  Dense = 'Dense',
}
export interface DroughtTolerantFrontYardModel extends ScheduleModel, ProjectModelBase {
  shrubCoverage: ShrubCoverage
  irrigationReLayout: boolean
  includeSideStrip: boolean
  dtfyRegularWithIrrigation: number
  dtfyRegularNoIrrigation: number
  dtfyDenseWithIrrigation: number
  dtfyDenseNoIrrigation: number
  dtfyRegularWithIrrigationAppreciation: number
  dtfyRegularNoIrrigationAppreciation: number
  dtfyDenseWithIrrigationAppreciation: number
  dtfyDenseNoIrrigationAppreciation: number
  dtfySideStrip: number
}
function newDroughtTolerantFrontYardModel(): DroughtTolerantFrontYardModel {
  const m2 = newScheduleModel()
  const m3 = newProjectModelBase()
  return {
    ...m2,
    ...m3,
    shrubCoverage: ShrubCoverage.Regular,
    irrigationReLayout: true,
    includeSideStrip: false,
    dtfyRegularWithIrrigation: 0,
    dtfyRegularNoIrrigation: 0,
    dtfyDenseWithIrrigation: 0,
    dtfyDenseNoIrrigation: 0,
    dtfyRegularWithIrrigationAppreciation: 0,
    dtfyRegularNoIrrigationAppreciation: 0,
    dtfyDenseWithIrrigationAppreciation: 0,
    dtfyDenseNoIrrigationAppreciation: 0,
    dtfySideStrip: 0,
  }
}
function isDroughtTolerantFrontYardModelValid(x: DroughtTolerantFrontYardModel): boolean {
  return isScheduleModelValid(x) && isProjectModelBaseValid(x)
}
export function getDroughtTolerantFrontYardModelPrice(m: OrderModel): string {
  const x = m.droughtTolerantFrontYardModel
  if (x) {
    let numPrice = 0
    const discount = m.discount
    if (x.shrubCoverage === 'Regular') {
      if (x.irrigationReLayout) {
        numPrice = x.dtfyRegularWithIrrigation - discount
      } else {
        numPrice = x.dtfyRegularNoIrrigation - discount
      }
    } else {
      if (x.irrigationReLayout) {
        numPrice = x.dtfyDenseWithIrrigation - discount
      } else {
        numPrice = x.dtfyDenseNoIrrigation - discount
      }
    }
    if (x.includeSideStrip) {
      numPrice += x.dtfySideStrip
    }
    if (numPrice) {
      return formatPrice(numPrice)
    }
  }
  return ''
}
export function getDroughtTolerantFrontYardModelAppreciation(
  x: DroughtTolerantFrontYardModel,
): string {
  let numPrice = 0
  if (x.shrubCoverage === 'Regular') {
    if (x.irrigationReLayout) {
      numPrice = x.dtfyRegularWithIrrigationAppreciation
    } else {
      numPrice = x.dtfyRegularNoIrrigationAppreciation
    }
  } else {
    if (x.irrigationReLayout) {
      numPrice = x.dtfyDenseWithIrrigationAppreciation
    } else {
      numPrice = x.dtfyDenseNoIrrigationAppreciation
    }
  }
  if (numPrice) {
    return formatPrice(numPrice)
  }
  return ''
}

export interface OrderModel extends MailingAddressModel, ContactModel {
  userId: string | null
  projectType: ProjectTypeValue
  drivewayPaverProject: DrivewayPaverProjectModel
  backyardPaverProject: BackyardPaverProjectModel
  droughtTolerantFrontYardModel: DroughtTolerantFrontYardModel
  promoCode: string // id of promo code
  discount: number // discount from promo code
  lotSize: number
  lotWidth: number
  garageArea: number
  buildingArea: number
  version: string
  latitude: string
  longitude: string
}

export function newOrderModel(projectType: ProjectTypeValue): OrderModel {
  const m1 = newMailingAddressModel()
  const m2 = newContactModel()
  const drivewayPaverProject = newDrivewayPaverProjectModel()
  const backyardPaverProject = newBackyardPaverProjectModel()
  const droughtTolerantFrontYardModel = newDroughtTolerantFrontYardModel()
  return {
    ...m1,
    ...m2,
    projectType,
    drivewayPaverProject,
    backyardPaverProject,
    droughtTolerantFrontYardModel,
    userId: null,
    promoCode: '',
    discount: 0,
    lotSize: 0,
    lotWidth: 0,
    garageArea: 0,
    buildingArea: 0,
    version: '',
    latitude: '',
    longitude: '',
  }
}
export function getOrderProjectModel(
  x: OrderModel,
): PaverProjectModel | BackyardPaverProjectModel | DroughtTolerantFrontYardModel {
  switch (x.projectType) {
    case ProjectTypeValue.PavingStoneDriveway:
      return x.drivewayPaverProject
    case ProjectTypeValue.BackyardPavingStonePatio:
      return x.backyardPaverProject
    case ProjectTypeValue.DroughtTolerantFrontYard:
      return x.droughtTolerantFrontYardModel
    default:
      throw Error('Unsupported project type ' + x.projectType)
  }
}
const requireFullConfigInfoForOrder = false
export function isOrderModelValid(x: OrderModel): boolean {
  const v = isMailingAddressModelValid(x) && isContactModelValid(x) && x.userId !== ''
  if (requireFullConfigInfoForOrder) {
    switch (x.projectType) {
      case ProjectTypeValue.PavingStoneDriveway:
        return v && isDrivewayPaverProjectModelValid(x.drivewayPaverProject)
      case ProjectTypeValue.BackyardPavingStonePatio:
        return v && isBackyardPaverProjectModelValid(x.backyardPaverProject)
      case ProjectTypeValue.DroughtTolerantFrontYard:
        return v && isDroughtTolerantFrontYardModelValid(x.droughtTolerantFrontYardModel)
      default:
        throw Error('Unsupported project type ' + x.projectType)
    }
  } else {
    switch (x.projectType) {
      case ProjectTypeValue.PavingStoneDriveway:
        return v && isScheduleModelValid(x.drivewayPaverProject)
      case ProjectTypeValue.BackyardPavingStonePatio:
        return v && isScheduleModelValid(x.backyardPaverProject)
      case ProjectTypeValue.DroughtTolerantFrontYard:
        return v && isScheduleModelValid(x.droughtTolerantFrontYardModel)
      default:
        throw Error('Unsupported project type ' + x.projectType)
    }
  }
}

export function setMailingAddress(x: OrderModel, ma: MailingAddressModel): OrderModel {
  return {...x, ...ma}
}

function sanitizeAppointmentData(x: ScheduleModel): any {
  if (x.appointmentState === AppointmentState.None) {
    throw Error('Invalid appointment state')
  }
  const y: any = {...x}
  if (y.appointmentState === AppointmentState.Appointment) {
    delete y.comments
    y.appointmentSet = true
  } else {
    delete y.day
    delete y.ampm
  }
  return y
}
// for use with submit order
// only keep data matching selected projectType
// drop projectType and non matching data
export function getOrderData(x: OrderModel): any {
  switch (x.projectType) {
    case ProjectTypeValue.PavingStoneDriveway: {
      const {projectType, backyardPaverProject, droughtTolerantFrontYardModel, ...rest} = x
      rest.drivewayPaverProject = sanitizeAppointmentData(rest.drivewayPaverProject)
      return rest
    }
    case ProjectTypeValue.BackyardPavingStonePatio: {
      const {projectType, drivewayPaverProject, droughtTolerantFrontYardModel, ...rest} = x
      rest.backyardPaverProject = sanitizeAppointmentData(rest.backyardPaverProject)
      return rest
    }
    case ProjectTypeValue.DroughtTolerantFrontYard: {
      const {projectType, drivewayPaverProject, backyardPaverProject, ...rest} = x
      rest.droughtTolerantFrontYardModel = sanitizeAppointmentData(
        rest.droughtTolerantFrontYardModel,
      )
      return rest
    }
    default:
      throw Error('getOrderData: unsupported project type: ' + x.projectType)
  }
}
