import {TextInputDialog} from '../common/TextInputDialog'

export interface PromoCodeDialogProps {
  open: boolean
  onClose: (promoCode: string) => any
}

export function PromoCodeDialog(props: PromoCodeDialogProps) {
  return (
    <TextInputDialog
      {...props}
      buttonLabel="Go!"
      textLabel="Promo Code"
      title="Enter Promo Code"
      message=""
      placeholder=""
    />
  )
}
