import firebase from 'firebase/app'
import 'firebase/firestore'
import {writeEvent} from './FirebaseService'
import Logger from 'js-logger'

const KEY = 'landing'
let lendingInFlight = false
export async function landing(origin: string) {
  Logger.info('>>> landing {')
  const landing = localStorage.getItem(KEY)
  if (!landing && !lendingInFlight) {
    Logger.info('>>> landing - proceed')
    lendingInFlight = true
    try {
      // write event to Firestore the very first time,
      // regardless of whether origin is set or not
      const id = firebase.firestore().collection('events').doc().id
      const now = new Date()
      const referrer = document.referrer
      const r = Math.random() > 0.1
      const cell = r ? 'slide' : 'addr'
      const landingData = JSON.stringify({
        id,
        origin,
        referrer,
        cell,
        timestamp: now.getTime(),
      })
      localStorage.setItem(KEY, landingData)
      await writeEvent('landing', {origin, referrer, cell, timestamp: now.toString()}, id).catch(
        (err) => {
          // fail
          Logger.warn('Failed to create event:', err)
        },
      )
    } finally {
      lendingInFlight = false
    }
  }
}

export function getLandingData(): any {
  const landingData = localStorage.getItem(KEY)
  if (landingData) {
    const data = JSON.parse(landingData)
    if (!data.cell) {
      const r = Math.random() > 0.1
      data.cell = r ? 'slide' : 'addr'
      localStorage.setItem(KEY, JSON.stringify(data))
      const {id, origin, referrer, cell, timestamp} = data
      // won't work b/c user can't update event
      writeEvent(
        'landing',
        {origin, referrer, cell, reason: 'set_cell', timestamp: new Date(timestamp).toString()},
        id + '-update',
      ).catch((err) => {
        // fail
        Logger.warn('Failed to create event:', err)
      })
    }
    return data
  }
  return null
}
