import {RText30} from '../../components/common/RText'

export const SectionTitle = function (props: any) {
  const {children, ...rest} = props

  return (
    <RText30 {...rest} variant="h2" style={{fontWeight: 'bold'}}>
      {children}
    </RText30>
  )
}
