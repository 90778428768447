import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import {store} from './app/store'
import {Provider} from 'react-redux'
import * as serviceWorker from './serviceWorker'
import firebase from 'firebase/app'
// import firebase from 'firebase'
import 'firebase/firestore'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/remote-config'
import 'firebase/storage'
import {SupportedCityStates} from './config/config'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyB5V4BD7pguRepuehqPycOzVdKJoS2KYmE',
  authDomain: 'onecc-1aa84.firebaseapp.com',
  databaseURL: 'https://onecc-1aa84-default-rtdb.firebaseio.com',
  projectId: 'onecc-1aa84',
  storageBucket: 'onecc-1aa84.appspot.com',
  messagingSenderId: '974377415152',
  appId: '1:974377415152:web:25dc5ec10401f5bb8bed35',
  measurementId: 'G-F63WW4LYFV',
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)
firebase.analytics()

if (
  window.location.hostname === 'localhost' &&
  window.location.origin !== 'http://localhost:3000'
) {
  firebase.auth().useEmulator('http://localhost:9099')
  firebase.firestore().useEmulator('localhost', 8080)
  firebase.functions().useEmulator('localhost', 5001)
  firebase.storage().useEmulator('localhost', 9199)
}

const remoteConfig = firebase.remoteConfig()
remoteConfig.settings.minimumFetchIntervalMillis = 12 * 3600000
remoteConfig.defaultConfig = {
  product_config: JSON.stringify({supported_cities: SupportedCityStates}),
}

remoteConfig
  .fetchAndActivate()
  .then((_: boolean) => {
    // no op
  })
  .catch((err) => {
    console.error('remote config fetch and activate error:', err)
  })

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
