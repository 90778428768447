import {useState} from 'react'
import Box from '@material-ui/core/Box'
import {PaverFirePitOptions, PaverFirePitOption} from '../../../../model/pavers'
import {ParagraphHeader, ParagraphSubHeader, FullScreenImageDialog} from '../../../common/common'
import {PaverFirePitConfigModel, newPaverFirePitConfigModel} from '../../../../model/common'
import {TDIOptionComponent} from '../common/TDIOptionComponent'
import {TDIRadioOptionComponent} from '../common/TDIRadioOptionComponent'

const PaverFirePitOptionComp = ({
  po,
  selected,
  onClick,
  isSM,
  final,
  image,
  alt,
  onClickImage,
}: {
  po: PaverFirePitOption
  selected: boolean
  onClick: () => void
  isSM: boolean
  final: boolean
  image: string
  alt: string
  onClickImage: () => void
}) => {
  return isSM ? (
    <TDIOptionComponent
      selected={selected}
      onClick={onClick}
      isSM={isSM}
      final={final}
      image={image}
      alt={alt}
      onClickImage={onClickImage}
      name={po.shape}
      description={po.description}
    />
  ) : (
    <TDIRadioOptionComponent
      selected={selected}
      onClick={onClick}
      isSM={isSM}
      final={final}
      imageURL={image}
      onClickImage={onClickImage}
      name={po.shape}
      alt={alt}
      description={po.description}
      price={''}
      disabled={false}
      disabledText={''}
    />
  )
}

export const PaverFirePitShapeSection = ({
  shape,
  final,
  isSM,
  onUpdate,
}: {
  shape?: string
  final: boolean
  isSM: boolean
  onUpdate: (model: PaverFirePitConfigModel) => void
}) => {
  const [open, setOpen] = useState(false)
  const [image, setImage] = useState('')
  return (
    <Box>
      <Box>
        <ParagraphHeader>Shape</ParagraphHeader>
        <ParagraphSubHeader>Choose the shape of your fire pit.</ParagraphSubHeader>
      </Box>
      <Box style={{marginTop: 20}} display="flex" justifyContent="space-between" flexWrap="wrap">
        {PaverFirePitOptions.map((po, i) => (
          <PaverFirePitOptionComp
            key={po.shape}
            po={po}
            image={`/images/firepit/${po.image}`}
            alt={po.alt}
            selected={po.shape === shape}
            onClick={() => {
              const m = newPaverFirePitConfigModel()
              onUpdate({...m, shape: po.shape})
            }}
            onClickImage={() => setImage(`/images/firepit/${po.image}`)}
            isSM={isSM}
            final={final}
          />
        ))}
        {isSM ? <Box style={{width: '30%'}}></Box> : null}
      </Box>
      <FullScreenImageDialog open={image !== ''} onClose={() => setImage('')} image={image} />
    </Box>
  )
}
