import Box from '@material-ui/core/Box'
import {Typography, Button} from '@material-ui/core'
import {ProjectExamplesFullScreen, BrowsingState} from '../../common/ProjectExamples'
import {ImageCarousel} from '../../common/ImageCarousel'
import {
  ProjectTypeValue,
  ProjectDescriptions,
  getImageURLs,
  getImageTags,
  ProjectDetails,
} from '../../../model/project'
import {GetSmartProposalPanel} from './GetSmartProposalPanel'
import {useTheme} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {bpNumber} from '../../../utils/styles'
import {BRAND_YELLOW} from '../../../config/styles'

function DetailsLabel({label, isSM, classes}: {label: string; isSM: boolean; classes: any}) {
  return (
    <Typography
      className={isSM ? classes.btn : classes.btnSelected}
      align={isSM ? 'left' : 'right'}>
      {isSM ? label + ':' : label}
    </Typography>
  )
}

function ProjectDetailsComp({
  isSM,
  classes,
  mt,
  projectType,
}: {
  isSM: boolean
  classes: any
  mt: any
  projectType: ProjectTypeValue
}) {
  const details = ProjectDetails[projectType]
  return (
    <Box mt={mt}>
      {isSM ? <Typography className={classes.btnSelected}>Details</Typography> : null}
      <Box display="flex" flexWrap="wrap">
        {details.map((d, i) => [
          <Box key={i + 'l'} width="50%" style={{paddingRight: '10px'}}>
            <DetailsLabel label={d[0]} isSM={isSM} classes={classes} />
          </Box>,
          <Box key={i + 'c'} width="50%">
            <Typography className={classes.btn}>{d[1]}</Typography>
          </Box>,
        ])}
      </Box>
    </Box>
  )
}

function UnavailableInfo({isSM, isMD, classes}: {isSM: boolean; isMD: boolean; classes: any}) {
  return (
    <Box
      style={{
        flexGrow: 1,
        width: isMD ? '50%' : '100%',
        marginLeft: isMD ? 80 : 0,
        marginTop: isMD ? 0 : 20,
      }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        style={{
          padding: isSM ? '60px' : '24px',
          borderRadius: '10px',
          border: '1px solid #eeeeee',
          boxShadow: '0px 5px 30px rgba(0,0,0,0.05)',
        }}>
        <Typography className={classes.details}>
          We're finalizing some of the details of this project. In the mean time, send us an email
          if you are interested.
        </Typography>
        <a href="mailto:info@bricko.co">
          <Typography align="center" className={classes.emphasize} style={{color: BRAND_YELLOW}}>
            info@bricko.co
          </Typography>
        </a>
      </Box>
    </Box>
  )
}

export interface ProjectInstanceProps {
  projectType: ProjectTypeValue
  classes: any
  carouselIndex: number
  isSM: boolean
  isMD: boolean
  browsingState: BrowsingState
  available: boolean
  onClick: (imageName: string, index: number) => void
  onChange: (index: number, active: number) => void
  onCarouselIndexChange: (index: number, active: number) => void
  onClose: () => void
  onClickGetSmartProposal: () => void
}
export const ProjectInstance = (props: ProjectInstanceProps) => {
  const theme = useTheme()
  const {
    projectType,
    classes,
    carouselIndex,
    isSM,
    isMD,
    browsingState,
    available,
    onClick,
    onChange,
    onCarouselIndexChange,
    onClose,
    onClickGetSmartProposal,
  } = props
  const imageURLs = getImageURLs(projectType)
  const tags = getImageTags(projectType)
  return (
    <Box px={isSM ? 0 : 2}>
      <Box mt={3} display="flex" flexDirection={isMD ? 'row' : 'column'}>
        <Box style={{flexGrow: 1, width: isMD ? '50%' : '100%'}}>
          <Box>
            <Typography className={classes.btnSelected}>
              {isSM ? 'About This Project' : 'Details'}
            </Typography>

            <Typography className={classes.btn}>{ProjectDescriptions[projectType]}</Typography>

            {isSM ? (
              <ProjectDetailsComp isSM={isSM} classes={classes} mt={3} projectType={projectType} />
            ) : null}
          </Box>
        </Box>
        {isSM ? (
          available ? (
            <Box
              style={{
                flexGrow: 1,
                width: isMD ? '50%' : '100%',
                marginLeft: isMD ? 80 : 0,
                marginTop: isMD ? 0 : 20,
              }}>
              <GetSmartProposalPanel
                isSM={isSM}
                classes={classes}
                onClick={onClickGetSmartProposal}
              />
            </Box>
          ) : (
            <UnavailableInfo isSM={isSM} isMD={isMD} classes={classes} />
          )
        ) : null}
      </Box>
      {available ? (
        <Box mt={isSM ? 2 : 0}>
          <ImageCarousel
            carouselIndex={carouselIndex}
            isSM={isSM}
            imageURLs={imageURLs}
            tags={tags}
            countPerSection={isMD ? 3 : 2}
            itemHeight={bpNumber(theme, useMediaQuery, [140, 180, 200, 260, 260])}
            onClick={onClick}
            onCarouselIndexChange={onCarouselIndexChange}
          />
          <ProjectExamplesFullScreen
            open={browsingState.open}
            onClose={onClose}
            index={browsingState.index}
            imageURLs={imageURLs}
            tags={tags}
            onChange={onChange}
          />
        </Box>
      ) : null}
      {isSM ? null : (
        <ProjectDetailsComp isSM={isSM} classes={classes} mt={2} projectType={projectType} />
      )}
      {isSM ? null : available ? (
        <Box mt={3} mb={3}>
          <Button
            size="large"
            onClick={onClickGetSmartProposal}
            variant="contained"
            style={{
              backgroundColor: BRAND_YELLOW,
              color: 'white',
              textTransform: 'none',
              fontSize: 14,
              fontWeight: 600,
              width: '100%',
            }}>
            Get Instant Smart Proposal
          </Button>
        </Box>
      ) : (
        <UnavailableInfo isSM={isSM} isMD={isMD} classes={classes} />
      )}
    </Box>
  )
}
