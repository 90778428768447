import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import {BRAND_YELLOW} from '../../../../config/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import {ZoomIn} from 'react-feather'
import IconButton from '@material-ui/core/IconButton'
import {useTheme, createStyles, makeStyles, Theme} from '@material-ui/core/styles'

const styles = (theme: Theme) =>
  createStyles({
    radio: {
      marginBottom: 20,
      '&:last-child': {
        marginBottom: 0,
      },
    },
  })

const useStyles = makeStyles(styles)

// Title Description Image Option component props
export interface TDIRadioOptionComponentProps {
  selected: boolean
  isSM: boolean
  final: boolean
  price: string
  disabled: boolean
  disabledText: string
  imageURL: string
  alt: string
  name: string
  description: string
  onClick: () => void
  onClickImage: (url: string) => void
}
// Title Description Image Option component
export const TDIRadioOptionComponent = ({
  selected,
  isSM,
  final,
  price,
  disabled,
  disabledText,
  imageURL,
  alt,
  name,
  description,
  onClick,
  onClickImage,
}: TDIRadioOptionComponentProps) => {
  const theme = useTheme()
  const classes = useStyles()
  const handleClick = () => {
    if (final || disabled) {
      return
    }
    onClick()
  }
  const descComp = (
    <Box>
      <Typography
        align="left"
        style={{
          marginTop: disabled ? 10 : 0,
          fontSize: '14px',
          lineHeight: '21px',
          fontWeight: 300,
          opacity: final && !selected ? 0.4 : 1,
        }}>
        {description}
      </Typography>
    </Box>
  )
  const imageComp = (
    <Box
      style={{
        marginTop: disabled && !isSM ? 10 : 0,
        width: isSM ? '34%' : '100%',
        height: 80,
        backgroundColor: isSM ? '#707070' : selected ? 'white' : '#f5f5f5',
        borderRadius: 10,
        flexGrow: 1 / 2,
        position: 'relative',
      }}>
      <img
        src={imageURL}
        alt={alt}
        style={{
          width: '100%',
          height: 80,
          borderRadius: 10,
          objectFit: 'cover',
          objectPosition: '0 0',
        }}
      />
      <div
        style={{
          width: 28,
          height: 28,
          position: 'absolute',
          bottom: 8,
          right: 8,
          borderRadius: 14,
          backgroundColor: 'rgba(0, 0, 0, 0.30)',
          display: 'flex',
          justifyContent: 'center',
        }}>
        <IconButton
          onClick={(e) => {
            e.stopPropagation()
            setTimeout(() => {
              onClickImage(imageURL)
            }, 200)
          }}
          style={{backgroundColor: 'transparent'}}>
          <ZoomIn color="white" size={18} />
        </IconButton>
      </div>
    </Box>
  )

  return (
    <Box
      onClick={handleClick}
      className={classes.radio}
      style={{
        width: '100%',
        padding: 15,
        border: selected ? `2px solid ${BRAND_YELLOW}` : '2px solid transparent',
        borderRadius: 8,
        // marginBottom: 20,
        backgroundColor: selected ? 'white' : '#f5f5f5',
        cursor: selected || final || disabled ? 'default' : 'pointer',
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'nowrap',
      }}>
      <Box
        style={{
          borderRadius: 8,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        {selected ? (
          <CheckCircleIcon style={{color: BRAND_YELLOW, fontSize: 26, zIndex: 1}} />
        ) : disabled ? (
          <RadioButtonUnchecked style={{color: '#cccccc', fontSize: 26, zIndex: 1}} />
        ) : (
          <Box
            style={{
              width: '26px',
              height: '26px',
              position: 'relative',
            }}>
            <Box
              style={{
                backgroundColor: 'white',
                position: 'absolute',
                top: 4,
                left: 4,
                width: '18px',
                height: '18px',
                borderRadius: '9px',
              }}></Box>
            <RadioButtonUnchecked style={{color: '#cccccc', fontSize: 26, zIndex: 1}} />
          </Box>
        )}
      </Box>
      <Box
        style={
          isSM ? {width: '66%', marginLeft: 15, paddingRight: 15} : {flexGrow: 1, marginLeft: 15}
        }>
        <Box style={{display: 'flex', flexDirection: 'row'}}>
          <Typography
            align="left"
            style={{
              fontSize: '16px',
              lineHeight: '24px',
              fontWeight: 600,
              color: '#333333',
              // marginBottom: 4,
              opacity: final && !selected ? 0.4 : 1,
            }}>
            {name}
          </Typography>
          <Box style={{flexGrow: 1}}></Box>
          <Box>
            {price ? (
              <Typography
                align="left"
                style={{
                  fontSize: '13px',
                  lineHeight: '19px',
                  fontWeight: 'bold',
                  color: disabled ? '#999999' : '#55c122',
                  opacity: final && !selected ? 0.4 : 1,
                  marginRight: isSM ? 0 : 10,
                }}>
                {price}
              </Typography>
            ) : null}
          </Box>
        </Box>
        {disabled && disabledText ? (
          <Box>
            <Typography
              align="left"
              style={{
                fontSize: '14px',
                lineHeight: '21px',
                fontWeight: 500,
                opacity: final && !selected ? 0.4 : 1,
                color: '#999999',
              }}>
              {disabledText}
            </Typography>
          </Box>
        ) : null}
        {isSM ? descComp : null}
        {isSM ? null : (
          <Box display="flex">
            <Box width={imageURL ? '55%' : '100%'} style={{paddingRight: 15}}>
              {descComp}
            </Box>
            {imageURL ? <Box width="45%">{imageComp}</Box> : null}
          </Box>
        )}
      </Box>
      {isSM && imageURL ? imageComp : null}
    </Box>
  )
}
