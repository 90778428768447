import Logger from 'js-logger'

export enum ProjectTypeValue {
  DroughtTolerantFrontYard = 'Drought Tolerant Front Yard',
  BackyardPavingStonePatio = 'Backyard Paving Stone Patio',
  PavingStoneDriveway = 'Paving Stone Driveway',
  Landscaping = 'Landscaping',
}

export const ProjectDescriptions = {
  [ProjectTypeValue.PavingStoneDriveway]:
    'A beautiful paving stone driveway that is lower maintenance and less costly to repair than concrete Driveway and path to front door included!',
  [ProjectTypeValue.BackyardPavingStonePatio]:
    'A beautiful paving stone patio in your backyard that creates an engaging and usable space for your friends and family. Options include a sitting wall, lighting, and fire pit!',
  [ProjectTypeValue.Landscaping]:
    'A lively and invigorating outdoor green space for your home. We’ll redfine your yard by adding new plants, grass, mulch, irrigation, etc. Various plant options are available to suit the space to your taste.',
  [ProjectTypeValue.DroughtTolerantFrontYard]:
    'Tasteful and practical landscape for your front yard with very low water requirements. This includes plants, trees, and optional natural grass alternatives. An economical and environmentally friendly choice!',
}

export const ProjectDetails = {
  [ProjectTypeValue.PavingStoneDriveway]: [
    ['Estimated Time', '2 Weeks'],
    ['Customizations', 'Color, Style, Pattern'],
    ['Avg Increase to Home Value', '$15,200 - $19,100'],
  ],
  [ProjectTypeValue.BackyardPavingStonePatio]: [
    ['Estimated Time', '2-3 Weeks'],
    ['Customizations', 'Color, Style, Pattern'],
    ['Avg Increase to Home Value', '$26,800 - $35,000'],
  ],
  [ProjectTypeValue.Landscaping]: [
    ['Estimated Time', '2-3 Weeks'],
    ['Customizations', 'Density, Trees, Shrubs, Irrigation'],
  ],
  [ProjectTypeValue.DroughtTolerantFrontYard]: [
    ['Estimated Time', '2-3 Weeks'],
    ['Customizations', 'Density, Trees, Shrubs, Irrigation'],
    ['Avg Increase to Home Value', '$2,700 - $4,900'],
  ],
}

// Project Names
const PN_BACKYARD_PATIO = 'Backyard Patio'
const PN_DT_FRONT_YARD = 'Drought Tolerant Front Yard'
const PN_PAVING_DRIVEWAY = 'Paving Stone Driveway'
const PN_LANDSCAPING = 'Landscaping'
export const projectNames = [
  PN_DT_FRONT_YARD,
  PN_BACKYARD_PATIO,
  PN_PAVING_DRIVEWAY,
  PN_LANDSCAPING,
]

// Project Query Parmeters
const PQP_BACKYARD_PATIO = 'backyard_pavers'
const PQP_DT_FRONT_YARD = 'dt_front_yard'
const PQP_PAVING_DRIVEWAY = 'driveway_pavers'
const PQP_LANDSCAPING = 'landscaping'

const projectQueryParams: {[x: string]: string} = {
  [PN_BACKYARD_PATIO]: PQP_BACKYARD_PATIO,
  [PN_DT_FRONT_YARD]: PQP_DT_FRONT_YARD,
  [PN_PAVING_DRIVEWAY]: PQP_PAVING_DRIVEWAY,
  [PN_LANDSCAPING]: PQP_LANDSCAPING,
}

const projectQueryParamsFromPT: {[x: string]: string} = {
  [ProjectTypeValue.DroughtTolerantFrontYard]: PQP_DT_FRONT_YARD,
  [ProjectTypeValue.BackyardPavingStonePatio]: PQP_BACKYARD_PATIO,
  [ProjectTypeValue.PavingStoneDriveway]: PQP_PAVING_DRIVEWAY,
  [ProjectTypeValue.Landscaping]: PQP_LANDSCAPING,
}

const PQP_TO_PT: {[x: string]: ProjectTypeValue} = {
  [PQP_DT_FRONT_YARD]: ProjectTypeValue.DroughtTolerantFrontYard,
  [PQP_BACKYARD_PATIO]: ProjectTypeValue.BackyardPavingStonePatio,
  [PQP_PAVING_DRIVEWAY]: ProjectTypeValue.PavingStoneDriveway,
  [PQP_LANDSCAPING]: ProjectTypeValue.Landscaping,
}

const PT_TO_PN: {[x: string]: string} = {
  [ProjectTypeValue.DroughtTolerantFrontYard]: PN_DT_FRONT_YARD,
  [ProjectTypeValue.BackyardPavingStonePatio]: PN_BACKYARD_PATIO,
  [ProjectTypeValue.PavingStoneDriveway]: PN_PAVING_DRIVEWAY,
  [ProjectTypeValue.Landscaping]: PN_LANDSCAPING,
}

export const PT_TO_PI: {[x: string]: number} = {
  [ProjectTypeValue.BackyardPavingStonePatio]: 1,
  [ProjectTypeValue.DroughtTolerantFrontYard]: 0,
  [ProjectTypeValue.PavingStoneDriveway]: 2,
  [ProjectTypeValue.Landscaping]: 3,
}
export const projectIndexValues = [
  ProjectTypeValue.DroughtTolerantFrontYard,
  ProjectTypeValue.BackyardPavingStonePatio,
  ProjectTypeValue.PavingStoneDriveway,
  ProjectTypeValue.Landscaping,
]
export const projectAvailabilityValues = [true, true, true, false]

export function getProjectIndexByProjectType(projectType: ProjectTypeValue): number {
  return PT_TO_PI[projectType] ?? 0
}

export function getProjectQueryParamByName(project: string): string {
  return projectQueryParams[project] ?? PQP_DT_FRONT_YARD
}

export function getProjectQueryParamByProjectType(projectType: ProjectTypeValue): string {
  return projectQueryParamsFromPT[projectType] ?? PQP_DT_FRONT_YARD
}

export interface ProjectSlideInfo {
  projectType: ProjectTypeValue
  name: string
  image: string
  price: string
  appreciation: string
  location: string
}

function makeProjectSlideInfo(
  projectType: ProjectTypeValue,
  image: string,
  price: string,
  appreciation: string,
  location: string,
): ProjectSlideInfo {
  return {
    projectType,
    name: PT_TO_PN[projectType],
    image,
    price,
    appreciation,
    location,
  }
}
function makeProjectSlideInfoPSD(
  image: string,
  price: string,
  appreciation: string,
  location: string,
): ProjectSlideInfo {
  return makeProjectSlideInfo(
    ProjectTypeValue.PavingStoneDriveway,
    image,
    price,
    appreciation,
    location,
  )
}
function makeProjectSlideInfoDTFY(
  image: string,
  price: string,
  appreciation: string,
  location: string,
): ProjectSlideInfo {
  return makeProjectSlideInfo(
    ProjectTypeValue.DroughtTolerantFrontYard,
    image,
    price,
    appreciation,
    location,
  )
}
function makeProjectSlideInfoBPSP(
  image: string,
  price: string,
  appreciation: string,
  location: string,
): ProjectSlideInfo {
  return makeProjectSlideInfo(
    ProjectTypeValue.BackyardPavingStonePatio,
    image,
    price,
    appreciation,
    location,
  )
}

const psdImagePriceList = [
  ['/images/examples/bricko-driveway-pavers-17.webp', '$11,780', '$19,200', 'San Jose, CA'],
  ['/images/examples/bricko-driveway-pavers-5.webp', '$9,910', '$16,180', 'San Jose, CA'],
  ['/images/examples/bricko-driveway-pavers-20.webp', '$9,670', '$15,500', 'San Jose, CA'],
  ['/images/examples/bricko-driveway-pavers-16.webp', '$12,340', '$20,300', 'Los Gatos, CA'],
]
const PSDProjectSlideInfoList: ProjectSlideInfo[] = psdImagePriceList.map((arr) =>
  makeProjectSlideInfoPSD(arr[0], arr[1], arr[2], arr[3]),
)

const dtfyImagePriceList = [
  [
    '/images/examples/bricko-drought-tolerant-front-yard-21.webp',
    '$2,320',
    '$2,800',
    'San Jose, CA',
  ],
  [
    '/images/examples/bricko-drought-tolerant-front-yard-22.webp',
    '$3,126',
    '$3,800',
    'San Jose, CA',
  ],
  [
    '/images/examples/bricko-drought-tolerant-front-yard-23.webp',
    '$1,765',
    '$2,200',
    'San Jose, CA',
  ],
  ['/images/examples/bricko-driveway-pavers-11b.webp', '$4,577', '$5,300', 'Los Gatos, CA'],
  ['/images/examples/bricko-driveway-pavers-3.webp', '$2,880', '$3,400', 'San Jose, CA'],
]
const DTFYProjectSlideInfoList: ProjectSlideInfo[] = dtfyImagePriceList.map((arr) =>
  makeProjectSlideInfoDTFY(arr[0], arr[1], arr[2], arr[3]),
)

const bpspImagePriceList = [
  ['/images/bricko-backyard-paver-basic.webp', '$6,800', '$12,200', 'Palo Alto, CA'],
  ['/images/bricko-backyard-paver-entertainment.webp', '$14,555', '$21,500', 'Cupertino, CA'],
  ['/images/bricko-backyard-paver-premium.webp', '$18,922', '$28,300', 'San Ramon, CA'],
  ['/images/bricko-backyard-paver-entertainment-ext.webp', '$12,799', '$19,700', 'San Jose, CA'], // '$21,779'
]
const BPSPProjectSlideInfoList: ProjectSlideInfo[] = bpspImagePriceList.map((arr) =>
  makeProjectSlideInfoBPSP(arr[0], arr[1], arr[2], arr[3]),
)

export const FeaturedProjectSlideInfoList: ProjectSlideInfo[] = [
  DTFYProjectSlideInfoList[2],
  DTFYProjectSlideInfoList[0],
  DTFYProjectSlideInfoList[3],
  BPSPProjectSlideInfoList[0],
  BPSPProjectSlideInfoList[1],
  BPSPProjectSlideInfoList[2],
  PSDProjectSlideInfoList[0],
  PSDProjectSlideInfoList[3],
]

const drivewayPaverImages = PSDProjectSlideInfoList.map((x) => x.image)
const backyardPaverImages = BPSPProjectSlideInfoList.map((x) => x.image)
const droughtTolerantFrontYardImages = DTFYProjectSlideInfoList.map((x) => x.image)

export function getImageURLs(projectType: ProjectTypeValue): string[] {
  switch (projectType) {
    case ProjectTypeValue.DroughtTolerantFrontYard:
      return droughtTolerantFrontYardImages
    case ProjectTypeValue.BackyardPavingStonePatio:
      return backyardPaverImages
    case ProjectTypeValue.PavingStoneDriveway:
      return drivewayPaverImages
    default:
      return []
  }
}

const drivewayPaverTags = PSDProjectSlideInfoList.map((x) => x.price)
const backyardPaverTags = BPSPProjectSlideInfoList.map((x) => x.price)
const droughtTolerantFrontYardTags = DTFYProjectSlideInfoList.map((x) => x.price)

export function getImageTags(projectType: ProjectTypeValue): string[] {
  switch (projectType) {
    case ProjectTypeValue.DroughtTolerantFrontYard:
      return droughtTolerantFrontYardTags
    case ProjectTypeValue.BackyardPavingStonePatio:
      return backyardPaverTags
    case ProjectTypeValue.PavingStoneDriveway:
      return drivewayPaverTags
    default:
      return []
  }
}

export function getEventProjectType(projectType: ProjectTypeValue): string {
  switch (projectType) {
    case ProjectTypeValue.DroughtTolerantFrontYard:
      return 'drought_tolerant_front_yard'
    case ProjectTypeValue.BackyardPavingStonePatio:
      return 'backyard_patio'
    case ProjectTypeValue.PavingStoneDriveway:
      return 'driveway_paving'
    case ProjectTypeValue.Landscaping:
      return 'landscaping'
    default:
      return projectType
  }
}

export function getProjectTypeByQueryParamValue(pt: string | null): ProjectTypeValue {
  if (pt) {
    const projectType = PQP_TO_PT[pt]
    if (projectType) {
      return projectType
    }
    Logger.warn('Unknown project type query param: ' + pt)
  }
  return ProjectTypeValue.DroughtTolerantFrontYard
}
