import {ProjectTypeValue} from './project'

// export const drivewayPaverImages: string[] = [
//   '/images/examples/17.webp', // 3740 Darshan Ct 1
//   // '/images/examples/18.webp', // 3740 Darshan Ct 1
//   '/images/examples/5.webp', // 3733 Darshan Ct 3
//   '/images/examples/20.webp', // 3728 Darshan Ct 1
//   // '/images/examples/11.webp', // 15575 Camino Del Corro
//   '/images/examples/16.webp', // 15585 Camino Del Corro
//   // '/images/examples/3.webp', // 3739 Darshan Ct 1
//   // '/images/examples/19.webp', // 3728 Darshan Ct 4
// ]
// export const drivewayPaverTags: string[] = [
//   '$11,780',
//   // '$11,780',
//   '$9,910',
//   '$9,670',
//   // '$13,379',
//   '$12,340',
//   // '$14,553',
//   // '$14,425',
// ]
// export const backyardPaverImages: string[] = [
//   '/images/backyard_paver_basic.webp',
//   '/images/backyard_paver_entertainment.webp',
//   '/images/backyard_paver_premium.webp',
//   '/images/backyard_paver_entertainment_ext.webp',
// ]
// export const backyardPaverTags: string[] = [
//   '$6,800',
//   '$14,555',
//   '$18,922',
//   '$12,799', // '$21,779'
// ]

// export const droughtTolerantFrontYardImages: string[] = [
//   '/images/examples/21.webp', // 1244 Brenton Ave
//   '/images/examples/22.webp', // 995 Amstutz dr, san jose
//   '/images/examples/23.webp', // 5011 country lane
//   '/images/examples/11b.webp', // 15575 Camino Del Cerro
//   '/images/examples/3.webp', // 3739 Darshan Ct 1
// ]
// export const droughtTolerantFrontYardTags: string[] = [
//   '$2,320',
//   '$3,126',
//   '$1,765',
//   '$4,577',
//   '$2,880',
// ]

export interface ProjectSize {
  name: string
  description: string
  descRecommended: string
  descNotRecommended: string
}

export const backyardPaverProjectSizes: ProjectSize[] = [
  {
    name: 'Cozy',
    description:
      'Recommended if you have a standard sized lot (~6000 sqft) and want to retain a balance of paved area and green space.',
    descRecommended:
      'Based on your lot size, we recommend this size if you want to retain a balance of paved area and green space.',
    descNotRecommended:
      'Based on your lot size, choose this option if you want to have a cozier patio that leaves more room for green space.',
  },
  {
    name: 'Extended',
    description:
      'Recommended if you have a larger sized lot (> 7000 sqft); Or if you have a standard sized lot and want to cover the majority of the back yard.',
    descRecommended:
      'Based on your lot size, we recommend this size to create an engaging patio that takes advantage of the available space.',
    descNotRecommended:
      'Based on your lot size, choose this option if you want to cover the majority of your back yard with the patio.',
  },
]

export const drivewayPaverProjectSizes: ProjectSize[] = [
  {
    name: 'Standard',
    description:
      'Choose this option if your current driveway is straight and roughly the width of your garage, with a small path to the front door.',
    descRecommended: '',
    descNotRecommended: '',
  },
  {
    name: 'Extended',
    description:
      'Choose this option if your driveway is curved or garage is set far back on your property. Or if you have (or want) a driveway that extends into the yard or includes a front porch.',
    descRecommended: '',
    descNotRecommended: '',
  },
]

export interface ProjectPackage {
  name: string
  description: string
  price: string
  imageCozy: string
  altCozy: string
  imageExtended: string
  altExtended: string
  extendedOnly?: boolean
  basicOnly?: boolean
}
export const backyardPaverProjectPackages: ProjectPackage[] = [
  {
    name: 'Basic Patio',
    description: 'A paving stone patio with stairs included to any back doors in your home.',
    price: '',
    imageCozy: 'bricko-backyard-paver-basic.webp',
    altCozy: 'Cozy backyard paver',
    imageExtended: 'bricko-backyard-paver-basic.webp',
    altExtended: 'Cozy extended backyard paver',
    basicOnly: true,
  },
  {
    name: 'Entertainment',
    description: 'Basic Patio package plus a sitting wall and lighting.',
    price: '',
    imageCozy: 'bricko-backyard-paver-entertainment-ext.webp',
    altCozy: 'Entertainment backyard paver',
    imageExtended: 'bricko-backyard-paver-entertainment-ext.webp',
    altExtended: 'Entertainment extended backyard paver',
  },
  {
    name: 'Premium Entertainment',
    description: 'Entertainment package plus a fire pit, longer wall, and more lighting',
    price: '',
    imageCozy: 'bricko-backyard-paver-entertainment.webp',
    altCozy: 'Premium Entertainment backyard paver',
    imageExtended: 'bricko-backyard-paver-premium.webp',
    altExtended: 'Premium Entertainment extended backyard paver',
    extendedOnly: true,
  },
]

export interface ShrubCoverageOption {
  name: string
  description: string
  image: string
  alt: string
}

export const shrubCoverageOptions: ShrubCoverageOption[] = [
  {
    name: 'Regular',
    image: 'bricko-drought-tolerant-regular.webp',
    alt: 'Regular shrub coverage',
    description:
      'Choose this option if you would like ornamental shrubs that stand out more or to give each plant room to grow to its full potential',
  },
  {
    name: 'Dense',
    image: 'bricko-drought-tolerant-dense.webp',
    alt: 'Dense shrub coverage',
    description:
      'Choose this option if you prefer to leave less soil without vegeative cover, reducing soil erosion and the room for weedy species',
  },
]

export interface PaverColorOption {
  name: string
  image: string
  alt: string
  vendor: string
  borderOnly?: boolean
  borderOK?: boolean
}
export interface PaverPatternOption {
  name: string
  image: string
  alt: string
  vendors: string[]
}
export interface PaverOption {
  style: string
  image: string
  alt: string
  description: string
  colors: PaverColorOption[]
  patterns: PaverPatternOption[]
}
export interface PaverWallOption {
  style: string
  image: string
  alt: string
  description: string
  colors: PaverColorOption[]
}
export interface PaverFirePitOption {
  shape: string
  image: string
  alt: string
  description: string
  colors: PaverColorOption[]
}
export interface PergolaOption {
  style: string
  image: string
  alt: string
  description: string
}
export const PaverWallOptions: PaverWallOption[] = [
  {
    style: 'Smooth',
    image: 'bricko-wall-modern.webp',
    alt: 'Smooth wall style',
    description: 'Paving stones are flat and smooth with clean edges for a modern look.',
    colors: [
      {
        name: 'Autumn',
        image: 'bricko-wall-modern-autumn.webp',
        alt: 'Autumn color',
        vendor: 'Belgard',
      },
      {
        name: 'Riviera',
        image: 'bricko-wall-modern-riviera.webp',
        alt: 'Riviera color',
        vendor: 'Belgard',
      },
      {
        name: 'Toscana',
        image: 'bricko-wall-modern-toscana.webp',
        alt: 'Toscana color',
        vendor: 'Belgard',
      },
      {
        name: 'Victorian',
        image: 'bricko-wall-modern-victorian.webp',
        alt: 'Victorian color',
        vendor: 'Belgard',
      },
    ],
  },
  {
    style: 'Chiseled',
    image: 'bricko-wall-chiseled.webp',
    alt: 'Chiseled wall style',
    description: 'Paving stones are chiseled for a more natural, yet impressive look.',
    colors: [
      {
        name: 'Toscana',
        image: 'bricko-wall-chiseled-toscana.webp',
        alt: 'Toscana color',
        vendor: 'Belgard',
      },
      {
        name: 'Victorian',
        image: 'bricko-wall-chiseled-victorian.webp',
        alt: 'Victorian color',
        vendor: 'Belgard',
      },
    ],
  },
  {
    style: 'Rustic',
    image: 'bricko-wall-rustic.webp',
    alt: 'Rustic wall style',
    description: 'Paving stones have a rough texture giving them a more charming look.',
    colors: [
      {
        name: 'Bella',
        image: 'bricko-wall-rustic-bella.webp',
        alt: 'Bella color',
        vendor: 'Belgard',
      },
      {
        name: 'Toscana',
        image: 'bricko-wall-rustic-toscana.webp',
        alt: 'Toscana color',
        vendor: 'Belgard',
      },
      {
        name: 'Victorian',
        image: 'bricko-wall-rustic-victorian.webp',
        alt: 'Victorian color',
        vendor: 'Belgard',
      },
    ],
  },
]
export const PaverFirePitOptions: PaverFirePitOption[] = [
  {
    shape: 'Round',
    image: 'bricko-firepit-round.webp',
    alt: 'Round firepit',
    description: 'A round fire pit that adds functionality without taking up too much space.',
    colors: [
      {
        name: 'Riviera',
        image: 'bricko-firepit-round-riviera.webp',
        alt: 'Riviera color',
        vendor: 'Belgard',
      },
      {
        name: 'Toscana',
        image: 'bricko-firepit-round-toscana.webp',
        alt: 'Toscana color',
        vendor: 'Belgard',
      },
      {
        name: 'Victorian',
        image: 'bricko-firepit-round-victorian.webp',
        alt: 'Victorian color',
        vendor: 'Belgard',
      },
    ],
  },
  {
    shape: 'Rectangular',
    image: 'bricko-firepit-rectangular.webp',
    alt: 'Rectangular firepit',
    description: 'A larger rectangular fire pit that is an ideal centerpiece for a sitting space.',
    colors: [
      {
        name: 'Bella',
        image: 'bricko-firepit-rectangular-bella.webp',
        alt: 'Bella color',
        vendor: 'Calstone',
      },
      {
        name: 'Toscana',
        image: 'bricko-firepit-rectangular-toscana.webp',
        alt: 'Toscana color',
        vendor: 'Calstone',
      },
      {
        name: 'Victorian',
        image: 'bricko-firepit-rectangular-victorian.webp',
        alt: 'Victorian color',
        vendor: 'Calstone',
      },
    ],
  },
]
export const PergolaOptions: PergolaOption[] = [
  {
    style: 'Option 1',
    image: 'modern.webp',
    alt: 'Pergola option 1',
    description: 'This will contain some useful description of option 1.',
  },
  {
    style: 'Option 2',
    image: 'textured.webp',
    alt: 'Pergola option 2',
    description: 'This will contain some useful description of option 2.',
  },
]

export const PaverOptions: PaverOption[] = [
  {
    style: 'Modern',
    image: 'bricko-paver-modern.webp',
    alt: 'Modern paver option',
    description: 'Paving stones are flat and smooth with clean edges for a modern look.',
    colors: [
      {
        name: 'Autumn',
        image: 'bricko-paver-BelgardCatalinaGranaAutumn.webp',
        alt: 'Autumn color',
        vendor: 'Belgard',
      },
      {
        name: 'Aspen',
        image: 'bricko-paver-BelgardCatalinaGranaAspen.webp',
        alt: 'Aspen color',
        vendor: 'Belgard',
      },
      {
        name: 'Barcelona',
        image: 'bricko-paver-BelgardCatalinaGranaBarcelona.webp',
        alt: 'Barcelona color',
        vendor: 'Belgard',
      },
      {
        name: 'Bella',
        image: 'bricko-paver-BelgardCatalinaGranaBella.webp',
        alt: 'Bella color',
        vendor: 'Belgard',
        borderOK: true,
      },
      {
        name: 'Riviera',
        image: 'bricko-paver-BelgardCatalinaGranaRiviera.webp',
        alt: 'Riviera color',
        vendor: 'Belgard',
      },
      {
        name: 'Toscana',
        image: 'bricko-paver-BelgardCatalinaGranaToscana.webp',
        alt: 'Toscana color',
        vendor: 'Belgard',
      },
      {
        name: 'Victorian',
        image: 'bricko-paver-BelgardCatalinaGranaVictorian.webp',
        alt: 'Victorian color',
        vendor: 'Belgard',
      },
      {
        name: 'Graphite',
        image: 'bricko-paver-BelgardCatalinaGranaGraphite.webp',
        alt: 'Graphite color',
        vendor: 'Belgard',
        borderOnly: true,
      },
      {
        name: 'Gray Charcoal Tan',
        image: 'bricko-paver-CalstoneMissionGrayCharcoalTan.webp',
        alt: 'Gray Charcoal Tan color',
        vendor: 'Calstone',
      },
      {
        name: 'Tan Brown Charcoal',
        image: 'bricko-paver-CalstoneMissionTanBrownCharcoal.webp',
        alt: 'Tan Brown Charcoal color',
        vendor: 'Calstone',
      },
      {
        name: 'Cream Tan Brown',
        image: 'bricko-paver-CalstoneMissionCreamTanBrown.webp',
        alt: 'Cream Tan Brown color',
        vendor: 'Calstone',
      },
      {
        name: 'Brown Biege Charcoal',
        image: 'bricko-paver-CalstoneMissionBrownBiegeCharcoal.webp',
        alt: 'Brown Biege Charcoal color',
        vendor: 'Calstone',
      },
      {
        name: 'Tan Red Charcoal',
        image: 'bricko-paver-CalstoneMissionTanRedCharcoal.webp',
        alt: 'Tan Red Charcoal color',
        vendor: 'Calstone',
      },
      {
        name: 'Green Gray Charcoal',
        image: 'bricko-paver-CalstoneMissionGreenGrayCharcoal.webp',
        alt: 'Green Gray Charcoal color',
        vendor: 'Calstone',
      },
      {
        name: 'Charcoal',
        image: 'bricko-paver-CalstoneMissionCharcoal.webp',
        alt: 'Charcoal color',
        vendor: 'Calstone',
        borderOnly: true,
      },
      {
        name: 'Gray Charcoal',
        image: 'bricko-paver-CalstoneMissionGrayCharcoal.webp',
        alt: 'Gray Charcoal color',
        vendor: 'Calstone',
      },
    ],
    patterns: [
      {
        name: 'Old Town II',
        image: 'bricko-paver-pattern-OldTownII.webp',
        alt: 'Old Town II pattern',
        vendors: ['Belgard'],
      },
      {
        name: 'Mega Pattern 2',
        image: 'bricko-paver-pattern-MegaPattern2.webp',
        alt: 'Mega 2 pattern',
        vendors: ['Belgard'],
      },
      {
        name: 'Mega Pattern 3',
        image: 'bricko-paver-pattern-MegaPattern3.webp',
        alt: 'Mega 3 pattern',
        vendors: ['Belgard'],
      },
      {
        name: 'Running Bond',
        image: 'bricko-paver-pattern-RunningBond.webp',
        alt: 'Running bond pattern',
        vendors: ['Belgard', 'Calstone'],
      },
      {
        name: 'Herringbone',
        image: 'bricko-paver-pattern-Herringbone45Degree.webp',
        alt: 'Herringbone pattern',
        vendors: ['Belgard', 'Calstone'],
      },
      {
        name: 'Pingwheel 6',
        image: 'bricko-paver-pattern-Pingwheel6.webp',
        alt: 'Pingwheel 6 pattern',
        vendors: ['Belgard', 'Calstone'],
      },
      {
        name: 'Linen',
        image: 'bricko-paver-pattern-Linen.webp',
        alt: 'Linen pattern',
        vendors: ['Belgard', 'Calstone'],
      },
      {
        name: 'Haviland',
        image: 'bricko-paver-pattern-Haviland.webp',
        alt: 'Haviland pattern',
        vendors: ['Belgard', 'Calstone'],
      },
      {
        name: 'Mission 3',
        image: 'bricko-paver-pattern-Mission3.webp',
        alt: 'Mission 3 pattern',
        vendors: ['Belgard', 'Calstone'],
      },
      {
        name: 'Hopscotch',
        image: 'bricko-paver-pattern-Hopscotch.webp',
        alt: 'Hopscotch pattern',
        vendors: ['Calstone'],
      },
      {
        name: 'Dimond Straight',
        image: 'bricko-paver-pattern-DimondStraight45Degree.webp',
        alt: 'Dimond Straight pattern',
        vendors: ['Calstone'],
      },
      {
        name: 'Two-Way Running Bond',
        image: 'bricko-paver-pattern-TwoWayRunningBond.webp',
        alt: 'Two-way running bond pattern',
        vendors: ['Belgard'],
      },
    ],
  },
  {
    style: 'Textured',
    image: 'bricko-paver-textured.webp',
    alt: 'Textured paver option',
    description: 'Paving stones have a textured finish and rougher edges for more personality.',
    colors: [
      {
        name: 'Aspen',
        image: 'bricko-paver-BelgardAvalonSlateAspen.webp',
        alt: 'Aspen color',
        vendor: 'Belgard',
      },
      {
        name: 'Autumn',
        image: 'bricko-paver-BelgardAvalonSlateAutumn.webp',
        alt: 'Autumn color',
        vendor: 'Belgard',
      },
      {
        name: 'Bella',
        image: 'bricko-paver-BelgardAvalonSlateBella.webp',
        alt: 'Bella color',
        vendor: 'Belgard',
        borderOK: true,
      },
      {
        name: 'Graphite',
        image: 'bricko-paver-BelgardAvalonSlateGraphite.webp',
        alt: 'Graphite color',
        vendor: 'Belgard',
        borderOnly: true,
      },
      {
        name: 'Riviera',
        image: 'bricko-paver-BelgardAvalonSlateRiviera.webp',
        alt: 'Riviera color',
        vendor: 'Belgard',
      },
      {
        name: 'Toscana',
        image: 'bricko-paver-BelgardAvalonSlateToscana.webp',
        alt: 'Toscana color',
        vendor: 'Belgard',
      },
      {
        name: 'Victorian',
        image: 'bricko-paver-BelgardAvalonSlateVictorian.webp',
        alt: 'Victorian color',
        vendor: 'Belgard',
      },
      {
        name: 'Oak Barrel',
        image: 'bricko-paver-CalstoneQuarryOakBarrel.webp',
        alt: 'Oak Barrel color',
        vendor: 'Calstone',
      },
      {
        name: 'Sunset Terracotta',
        image: 'bricko-paver-CalstoneQuarrySunsetTerracotta.webp',
        alt: 'Sunset Terracotta color',
        vendor: 'Calstone',
      },
      {
        name: 'Cameron Cream',
        image: 'bricko-paver-CalstoneQuarryCameronCream.webp',
        alt: 'Cameron Cream color',
        vendor: 'Calstone',
      },
      {
        name: 'Sequoia Sandstone',
        image: 'bricko-paver-CalstoneQuarrySequoiaSandstone.webp',
        alt: 'Sequoia Sandstone color',
        vendor: 'Calstone',
      },
      {
        name: 'Rustic Yellowstone',
        image: 'bricko-paver-CalstoneQuarryRusticYellowstone.webp',
        alt: 'Rustic Yellowstone color',
        vendor: 'Calstone',
      },
      {
        name: 'Sierra Granite',
        image: 'bricko-paver-CalstoneQuarrySierraGranite.webp',
        alt: 'Sierra Graphite color',
        vendor: 'Calstone',
      },
      {
        name: 'Chaco Canyon',
        image: 'bricko-paver-CalstoneQuarryChacoCanyon.webp',
        alt: 'Chaco Canyon color',
        vendor: 'Calstone',
      },
      {
        name: 'Connecticut Fieldstone',
        image: 'bricko-paver-CalstoneQuarryConnecticutFieldstone.webp',
        alt: 'Connecticut Fieldstone color',
        vendor: 'Calstone',
      },
      {
        name: 'Charcoal',
        image: 'bricko-paver-CalstoneQuarryCharcoal.webp',
        alt: 'Charcoal color',
        vendor: 'Calstone',
        borderOnly: true,
      },
    ],
    patterns: [
      {
        name: 'Mega Pattern 2',
        image: 'bricko-paver-pattern-MegaPattern2.webp',
        alt: 'Mega 2 pattern',
        vendors: ['Belgard', 'Calstone'],
      },
      {
        name: 'Mega Pattern 3',
        image: 'bricko-paver-pattern-MegaPattern3.webp',
        alt: 'Mega 3 pattern',
        vendors: ['Belgard', 'Calstone'],
      },
      {
        name: 'QS 1',
        image: 'bricko-paver-pattern-QS1.webp',
        alt: 'OS 1 pattern',
        vendors: ['Calstone'],
      },
      {
        name: 'QS 2',
        image: 'bricko-paver-pattern-QS2.webp',
        alt: 'OS 2 pattern',
        vendors: ['Calstone'],
      },
      {
        name: 'QS 3',
        image: 'bricko-paver-pattern-QS3.webp',
        alt: 'OS 3 pattern',
        vendors: ['Calstone'],
      },
      {
        name: 'QS 4',
        image: 'bricko-paver-pattern-QS4.webp',
        alt: 'OS 4 pattern',
        vendors: ['Calstone'],
      },
      {
        name: 'QS 8',
        image: 'bricko-paver-pattern-QS8.webp',
        alt: 'OS 8 pattern',
        vendors: ['Calstone'],
      },
      {
        name: 'Two-Way Running Bond',
        image: 'bricko-paver-pattern-TwoWayRunningBond.webp',
        alt: 'Two-Way Running Bond pattern',
        vendors: ['Belgard', 'Calstone'],
      },
      {
        name: 'Herringbone',
        image: 'bricko-paver-pattern-Herringbone45Degree.webp',
        alt: 'Herringbone pattern',
        vendors: ['Belgard', 'Calstone'],
      },
      {
        name: 'Old Town II',
        image: 'bricko-paver-pattern-OldTownII.webp',
        alt: 'Old Town II pattern',
        vendors: ['Belgard', 'Calstone'],
      },
      {
        name: 'Running Bond',
        image: 'bricko-paver-pattern-RunningBond.webp',
        alt: 'Running Bond pattern',
        vendors: ['Belgard'],
      },
      {
        name: 'Pingwheel 6',
        image: 'bricko-paver-pattern-Pingwheel6.webp',
        alt: 'Pingwheel 6 pattern',
        vendors: ['Belgard'],
      },
      {
        name: 'Linen',
        image: 'bricko-paver-pattern-Linen.webp',
        alt: 'Linen pattern',
        vendors: ['Belgard'],
      },
      {
        name: 'Haviland',
        image: 'bricko-paver-pattern-Haviland.webp',
        alt: 'Haviland pattern',
        vendors: ['Belgard'],
      },
      {
        name: 'Mission 3',
        image: 'bricko-paver-pattern-Mission3.webp',
        alt: 'Mission 3 pattern',
        vendors: ['Belgard'],
      },
    ],
  },
  {
    style: 'Cobble',
    image: 'bricko-paver-cobble.webp',
    alt: 'Cobble paver option',
    description: 'Paving stones have smooth rounded edges for a more classic look.',
    colors: [
      {
        name: 'Autumn',
        image: 'bricko-paver-BelgardCambridgeCobbleAutumn.webp',
        alt: 'Autumn color',
        vendor: 'Belgard',
      },
      {
        name: 'Barcelona',
        image: 'bricko-paver-BelgardCambridgeCobbleBarcelona.webp',
        alt: 'Barcelona color',
        vendor: 'Belgard',
      },
      {
        name: 'Bella',
        image: 'bricko-paver-BelgardCambridgeCobbleBella.webp',
        alt: 'Bella color',
        vendor: 'Belgard',
        borderOK: true,
      },
      {
        name: 'Graphite',
        image: 'bricko-paver-BelgardCambridgeCobbleGraphite.webp',
        alt: 'Graphite color',
        vendor: 'Belgard',
        borderOnly: true,
      },
      {
        name: 'Riviera',
        image: 'bricko-paver-BelgardCambridgeCobbleRiviera.webp',
        alt: 'Riviera color',
        vendor: 'Belgard',
      },
      {
        name: 'Toscana',
        image: 'bricko-paver-BelgardCambridgeCobbleToscana.webp',
        alt: 'Toscana color',
        vendor: 'Belgard',
      },
      {
        name: 'Victorian',
        image: 'bricko-paver-BelgardCambridgeCobbleVictorian.webp',
        alt: 'Victorian color',
        vendor: 'Belgard',
      },
      {
        name: 'Gray Charcoal Tan',
        image: 'bricko-paver-CalstoneClassicCobbleGrayCharcoalTan.webp',
        alt: 'Gray Charcoal color',
        vendor: 'Calstone',
      },
      {
        name: 'Tan Brown Charcoal',
        image: 'bricko-paver-CalstoneClassicCobbleTanBrownCharcoal.webp',
        alt: 'Tan Brown Charcoal color',
        vendor: 'Calstone',
      },
      {
        name: 'Gray Charcoal',
        image: 'bricko-paver-CalstoneClassicCobbleGrayCharcoal.webp',
        alt: 'Gray Charcoal color',
        vendor: 'Calstone',
      },
      {
        name: 'Cream Tan Brown',
        image: 'bricko-paver-CalstoneClassicCobbleCreamTanBrown.webp',
        alt: 'Cream Tan Brown color',
        vendor: 'Calstone',
      },
      {
        name: 'Brown Beige Charcoal',
        image: 'bricko-paver-CalstoneClassicCobbleBrownBeigeCharcoal.webp',
        alt: 'Brown Beige Charcoal color',
        vendor: 'Calstone',
      },
      {
        name: 'Tan Red Charcoal',
        image: 'bricko-paver-CalstoneClassicCobbleTanRedCharcoal.webp',
        alt: 'Tan Red Charcoal color',
        vendor: 'Calstone',
      },
      {
        name: 'Charcoal',
        image: 'bricko-paver-CalstoneClassicCobbleCharcoal.webp',
        alt: 'Charcoal color',
        vendor: 'Calstone',
        borderOnly: true,
      },
    ],
    patterns: [
      {
        name: 'Herringbone',
        image: 'bricko-paver-pattern-Herringbone45Degree.webp',
        alt: 'Herringbone pattern',
        vendors: ['Belgard', 'Calstone'],
      },
      {
        name: 'Three-Way Running Bond',
        image: 'bricko-paver-pattern-ThreeWayRunningBond.webp',
        alt: 'Three-Way Running Bond pattern',
        vendors: ['Calstone'],
      },
      {
        name: 'Old Town II',
        image: 'bricko-paver-pattern-OldTownII.webp',
        alt: 'Old Town II pattern',
        vendors: ['Belgard', 'Calstone'],
      },
      {
        name: 'Old Town Pattern III',
        image: 'bricko-paver-pattern-OldTownPatternIII.webp',
        alt: 'Old Town III pattern',
        vendors: ['Calstone'],
      },
      {
        name: 'Two-Way Parquet',
        image: 'bricko-paver-pattern-TwoWayParquet.webp',
        alt: 'Two-Way Parquet pattern',
        vendors: ['Calstone'],
      },
      {
        name: 'Mega Pattern 2',
        image: 'bricko-paver-pattern-MegaPattern2.webp',
        alt: 'Mega 2 pattern',
        vendors: ['Belgard', 'Calstone'],
      },
      {
        name: 'Mega Pattern 3',
        image: 'bricko-paver-pattern-MegaPattern3.webp',
        alt: 'Mega 3 pattern',
        vendors: ['Belgard', 'Calstone'],
      },
      {
        name: 'Mega Pattern 4',
        image: 'bricko-paver-pattern-MegaPattern4.webp',
        alt: 'Mega 4 pattern',
        vendors: ['Calstone'],
      },
      {
        name: 'Two-Way Running Bond',
        image: 'bricko-paver-pattern-TwoWayRunningBond.webp',
        alt: 'Two-Way Running Bond pattern',
        vendors: ['Belgard', 'Calstone'],
      },
    ],
  },
]
