import {useState} from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import withWidth, {isWidthUp, WithWidth} from '@material-ui/core/withWidth'
import {SectionTitle} from './SectionTitle'
import {Typography} from '@material-ui/core'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'
import {BRAND_YELLOW} from '../../config/styles'
import {BrowsingState} from '../../components/common/ProjectExamples'
import {useHistory} from 'react-router-dom'
import {
  ProjectTypeValue,
  getProjectQueryParamByName,
  projectNames,
  projectIndexValues,
  projectAvailabilityValues,
  PT_TO_PI as projectValueIndexMap,
} from '../../model/project'
import {RText2016} from '../../components/common/RText'
import {useTheme} from '@material-ui/core/styles'
import {ProjectType} from '../../components/common/ProjectType'
import {writeEvent} from '../../services/FirebaseService'
import Logger from 'js-logger'
import {ProjectInstance} from './project/ProjectInstance'

const styles = (theme: Theme) =>
  createStyles({
    title: {fontSize: '22px', lineHeight: '33px', fontWeight: 600, color: '#333333'},
    description: {fontWeight: 300, color: '#333333'},
    emphasize: {fontSize: '20px', lineHeight: '30px', fontWeight: 600, color: '#333333'},
    btn: {fontSize: '16px', lineHeight: '24px', fontWeight: 300, color: '#333333'},
    btnSelected: {fontSize: '16px', lineHeight: '24px', fontWeight: 600, color: '#333333'},
    details: {fontSize: '14px', lineHeight: '21px', fontWeight: 300, color: '#333333'},
  })
const stylesMobile = (theme: Theme) =>
  createStyles({
    title: {fontSize: '18px', lineHeight: '27px', fontWeight: 600, color: '#333333'},
    description: {fontWeight: 300, color: '#333333'},
    emphasize: {fontSize: '16px', lineHeight: '24px', fontWeight: 600, color: '#333333'},
    btn: {fontSize: '14px', lineHeight: '21px', fontWeight: 300, color: '#333333'},
    btnSelected: {fontSize: '14px', lineHeight: '21px', fontWeight: 600, color: '#333333'},
    details: {fontSize: '14px', lineHeight: '21px', fontWeight: 300, color: '#333333'},
  })
const useStyles = makeStyles(styles)
const useStylesMobile = makeStyles(stylesMobile)

const ProjectButton = ({
  title,
  isSM,
  selected,
  onClick,
  classes,
  first,
}: {
  title: string
  isSM: boolean
  selected: boolean
  onClick: () => void
  classes: any
  first: boolean
}) => {
  const w = isSM ? `${40 + 9 * title.length}px` : '100%'
  return (
    <Box
      onClick={onClick}
      style={{
        marginLeft: first || !isSM ? 0 : 20,
        marginTop: first || isSM ? 0 : 6,
        marginBottom: 8,
        cursor: 'pointer',
        padding: '10px 20px',
        backgroundColor: selected ? 'white' : '#f5f5f5',
        border: '1px solid ' + (selected ? BRAND_YELLOW : 'white'),
        borderRadius: 6,
        width: w,
      }}>
      <Typography align="center" className={selected ? classes.btnSelected : classes.btn}>
        {title}
      </Typography>
    </Box>
  )
}

const ProjectButtonMobile = ({
  title,
  selected,
  onClick,
  classes,
  first,
  last,
}: {
  title: string
  selected: boolean
  onClick: () => void
  classes: any
  first: boolean
  last: boolean
}) => {
  const w = '150px'
  return (
    <Box style={{paddingRight: last ? 16 : 0}}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        onClick={onClick}
        style={{
          marginLeft: first ? 16 : 10,
          cursor: 'pointer',
          padding: '10px 8px',
          backgroundColor: selected ? 'white' : '#f5f5f5',
          border: '1px solid ' + (selected ? BRAND_YELLOW : 'white'),
          borderRadius: 6,
          width: w,
          minWidth: w,
          height: 'calc(100% - 20px)',
        }}>
        <Typography align="center" className={selected ? classes.btnSelected : classes.btn}>
          {title}
        </Typography>
      </Box>
    </Box>
  )
}

export interface ExampleProjectsProps {
  projectIndex: number
  onProjectIndexChange: (index: number) => void
  onClickSuggestProject: () => void
}

export const ExampleProjects = withWidth()(function (props: ExampleProjectsProps) {
  const theme = useTheme()
  const isSM = isWidthUp('sm', (props as any as WithWidth).width)
  const isMD = isWidthUp('md', (props as any as WithWidth).width)
  const classes = isSM ? useStyles() : useStylesMobile()
  const history = useHistory()

  const [carouselIndex, setCarouselIndex] = useState(0)
  const [browsingState, setBrowsingState] = useState<BrowsingState>({open: false, index: 0})
  const {projectIndex, onProjectIndexChange, onClickSuggestProject} = props
  const projects = projectNames

  const onClickProject = (index: number) => {
    onProjectIndexChange(index)
    setCarouselIndex(0)
  }

  const onClick = (imageName: string, index: number) => {
    setBrowsingState({
      open: true,
      index,
    })
  }
  const onClose = () => {
    const ci = isSM ? Math.floor(browsingState.index / 3) : browsingState.index
    setCarouselIndex(ci)
    setBrowsingState({
      ...browsingState,
      open: false,
    })
  }
  const onChange = (index: number, active: number) => {
    setBrowsingState({
      ...browsingState,
      index,
    })
  }
  const onCarouselIndexChange = (index: number, active: number) => {
    setCarouselIndex(index)
  }

  const onClickGetSmartProposal = (project: string) => {
    // add query parameter to pre-select project type in order page
    const projectType = getProjectQueryParamByName(project)
    writeEvent('action_smart_proposal', {
      operation: 'open',
      page: 'home',
      from: 'example_projects',
      projectType,
    }).catch((err) => {
      // fail
      Logger.warn('Failed to create action_smart_proposal event:', err)
    })
    history.push(`/shop?projectType=${projectType}`)
  }

  const containerStyle: any = {
    borderBottom: isSM ? 'none' : '5px solid #f5f5f5',
  }
  if (!isSM) {
    containerStyle.padding = '0px'
  }

  return (
    <Container style={containerStyle}>
      <Box
        style={{
          marginTop: isSM ? '80px' : '0px',
          paddingTop: isSM ? '0px' : '50px',
          paddingBottom: isSM ? '0px' : '30px',
          paddingLeft: isSM ? 0 : 4,
          paddingRight: isSM ? 0 : 4,
        }}>
        <Box px={isSM ? 0 : 2}>
          <SectionTitle>Projects</SectionTitle>
        </Box>

        <Box px={isSM ? 0 : 2}>
          <RText2016 className={classes.description}>
            Take a look at our current offerings and examples of our finished work! Interested in a
            renovation that we don't offer yet?{' '}
            <Link
              component="button"
              style={{
                color: BRAND_YELLOW,
                marginTop: -4.5,
                backgroundColor: 'white',
              }}
              onClick={onClickSuggestProject}>
              <RText2016
                style={{
                  color: BRAND_YELLOW,
                  fontWeight: 'bold',
                }}>
                Let us know
              </RText2016>
            </Link>
          </RText2016>
        </Box>
        <Box>
          {isSM ? (
            <Box mt={3} display="flex" flexDirection="row" flexWrap="wrap">
              {projects.map((project, i) => (
                <ProjectButton
                  key={i}
                  first={i === 0}
                  title={project}
                  isSM={isSM}
                  selected={i === projectIndex}
                  onClick={() => onClickProject(i)}
                  classes={classes}
                />
              ))}
            </Box>
          ) : true ? (
            <div
              style={{
                width: '100%',
                height: '60px',
                overflow: 'hidden',
                marginTop: 20,
                position: 'relative',
              }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  bottom: -20,
                  right: 0,
                  overflow: 'scroll',
                }}>
                {projects.map((project, i) => (
                  <ProjectButtonMobile
                    key={i}
                    first={i === 0}
                    last={i === projects.length - 1}
                    title={project}
                    selected={i === projectIndex}
                    onClick={() => onClickProject(i)}
                    classes={classes}
                  />
                ))}
              </div>
            </div>
          ) : (
            <Box mt={1}>
              <ProjectType
                allowSelectDisabled
                isSM={isSM}
                label="Project"
                headerFontWeight={300}
                showDescription={false}
                value={projectIndexValues[projectIndex]}
                onChange={(value: ProjectTypeValue) => onClickProject(projectValueIndexMap[value])}
              />
            </Box>
          )}
          <ProjectInstance
            projectType={projectIndexValues[projectIndex]}
            classes={classes}
            carouselIndex={carouselIndex}
            isSM={isSM}
            isMD={isMD}
            browsingState={browsingState}
            available={projectAvailabilityValues[projectIndex]}
            onClick={onClick}
            onChange={onChange}
            onCarouselIndexChange={onCarouselIndexChange}
            onClose={onClose}
            onClickGetSmartProposal={() => onClickGetSmartProposal(projects[projectIndex])}
          />
        </Box>
      </Box>
    </Container>
  )
})
