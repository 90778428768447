import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import withWidth, {isWidthUp} from '@material-ui/core/withWidth'

export const Footer = withWidth()(function (props: {onClickQuestions: () => void}) {
  const {onClickQuestions} = props
  const isMD = isWidthUp('md', (props as any).width)
  if (isMD) {
    return (
      <div style={{clear: 'both', backgroundColor: '#333333', width: '100%'}}>
        <Container>
          <Box display="flex" flexDirection="row" alignItems="center" style={{padding: '20px 0'}}>
            <Box>
              <Typography align="left" style={{color: 'white', fontSize: 16}}>
                Questions?{' '}
                <a href="mailto:info@bricko.co" onClick={onClickQuestions}>
                  info@bricko.co
                </a>
              </Typography>
            </Box>
            <Box style={{marginLeft: '50px'}}>
              <Typography align="left" style={{color: 'white', fontSize: 16}}>
                <a href="/invite">Invite a Friend</a>
              </Typography>
            </Box>
            <Box style={{marginLeft: '50px'}}>
              <Typography align="left" style={{color: 'white', fontSize: 16}}>
                <a href="/shop">Track My Project</a>
              </Typography>
            </Box>
            <Box style={{marginLeft: '50px'}}>
              <Typography align="left" style={{color: 'white', fontSize: 16}}>
                <a href="/jobs">Jobs</a>
              </Typography>
            </Box>
            <Box style={{flexGrow: 1}}></Box>
            <Box>
              <Typography
                align="right"
                style={{
                  color: 'white',
                  marginTop: 5,
                  fontSize: 14,
                }}>
                &copy; 2021 Bricko, Inc
              </Typography>
              <Typography
                align="right"
                style={{
                  color: 'white',
                  marginTop: 5,
                  fontSize: 14,
                }}>
                500 Santana Row, San Jose, CA
              </Typography>
            </Box>
          </Box>
        </Container>
      </div>
    )
  }
  return (
    <div style={{clear: 'both', backgroundColor: '#333333', width: '100%'}}>
      <Container>
        <Box style={{padding: '20px 8px'}}>
          <Grid container spacing={0}>
            <Grid item md={1} xs={12}></Grid>
            <Grid item md={2} xs={12}>
              <Typography align="left" style={{color: 'white', fontSize: 16}}>
                Questions?{' '}
                <a href="mailto:info@bricko.co" onClick={onClickQuestions}>
                  info@bricko.co
                </a>
              </Typography>
            </Grid>
            <Grid item md={2} xs={12}>
              <Typography align="left" style={{color: 'white', fontSize: 16, marginTop: 12}}>
                <a href="/invite">Invite a Friend</a>
              </Typography>
            </Grid>
            <Grid item md={2} xs={12}>
              <Typography align="left" style={{color: 'white', fontSize: 16, marginTop: 12}}>
                <a href="/shop">Track My Project</a>
              </Typography>
            </Grid>
            <Grid item md={2} xs={12}>
              <Typography align="left" style={{color: 'white', fontSize: 16, marginTop: 12}}>
                <a href="/jobs">Jobs</a>
              </Typography>
            </Grid>
            <Grid item md={4} xs={12}></Grid>
            <Grid item md={2} xs={12}>
              <Typography
                align="left"
                style={{
                  color: 'white',
                  marginTop: 12,
                  fontSize: 12,
                }}>
                &copy; 2021 Bricko, Inc
              </Typography>
              <Typography
                align="left"
                style={{
                  color: 'white',
                  fontSize: 12,
                }}>
                500 Santana Row, San Jose, CA
              </Typography>
            </Grid>
            <Grid item md={1} xs={12}></Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  )
})
