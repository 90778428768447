import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import {BRAND_YELLOW, HEADER_HEIGHT, MOBILE_HOME_HEADER_HEIGHT} from '../../config/styles'
import withWidth, {isWidthUp} from '@material-ui/core/withWidth'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {RText4020, RText2012} from '../../components/common/RText'
import {useTheme} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {bpNumber} from '../../utils/styles'

const styles = (theme: Theme) =>
  createStyles({
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  })
const useStyles = makeStyles(styles)

export interface HomeImageHeaderProps {
  onClickGetSmartProposal: () => void
}
export const HomeImageHeader = withWidth()(function (props: HomeImageHeaderProps) {
  const theme = useTheme()
  const classes = useStyles()
  const isSM = isWidthUp('sm', (props as any).width)
  const imgStyle: any = isSM
    ? {width: '100%'}
    : {
        height: '217px',
        width: '100%',
        objectPosition: '80% 0%',
        objectFit: 'cover',
      }

  const fontSize = bpNumber(theme, useMediaQuery, [12, 12, 14, 16, 16])
  const minWidth = bpNumber(theme, useMediaQuery, [12, 220, 240, 263, 263])

  return (
    <Box
      style={{position: 'relative', marginTop: isSM ? HEADER_HEIGHT : MOBILE_HOME_HEADER_HEIGHT}}>
      <img
        alt="Bricko - easy, reliable home renovation"
        src="/images/bricko-home.webp"
        style={imgStyle}></img>
      <Box
        style={{
          position: 'absolute',
          flexDirection: 'column',
          top: bpNumber(theme, useMediaQuery, [20, 30, 40, 50, 50]),
          left: bpNumber(theme, useMediaQuery, [20, 30, 40, 50, 50]),
        }}>
        <RText4020
          align="left"
          variant="h1"
          style={{
            fontWeight: 600,
            color: 'white',
            textShadow: '0px 1px 20px rgba(0,0,0,0.15)',
          }}>
          One Click Home Renovations
        </RText4020>
        <RText2012
          align="left"
          variant="h2"
          style={{
            fontWeight: 500,
            color: 'white',
            textShadow: '0px 1px 20px rgba(0,0,0,0.15)',
          }}>
          Instantly Priced. Reliably Delivered.
        </RText2012>
        {isSM ? (
          <Box mt={3} className="headerAction">
            <Button
              size="large"
              onClick={props.onClickGetSmartProposal}
              variant="contained"
              style={{
                backgroundColor: BRAND_YELLOW,
                color: 'white',
                textTransform: 'none',
                fontSize,
                fontWeight: 600,
                minWidth,
              }}>
              Get Instant Smart Proposal
            </Button>
          </Box>
        ) : null}
      </Box>
    </Box>
  )
})
