import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import {BRAND_YELLOW} from '../../config/styles'
import {useTheme} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {bpNumber, bpString} from '../../utils/styles'
import {RText2218} from '../../components/common/RText'

export const ValueComp = ({
  title,
  description,
  IconComp,
  isSM,
  mt,
}: {
  title: string
  description: string
  IconComp: any
  isSM: boolean
  mt: number
}) => {
  const theme = useTheme()
  const style: any = {
    borderRadius: '10px',
    boxShadow: '0px 5px 30px rgba(0, 0, 0, 0.12)',
    backgroundColor: 'white',
  }
  if (isSM) {
    style.height = '100%'
  }
  return (
    <Box mt={mt} style={style}>
      <Box
        style={{
          padding: bpString(theme, useMediaQuery, [
            '30px 40px',
            '30px 40px',
            '20px 20px',
            '30px 40px',
            '30px 40px',
          ]),
        }}>
        <Box>
          <Typography align="center">
            <IconComp size={70} style={{color: BRAND_YELLOW}} />
          </Typography>
        </Box>
        <Box>
          <RText2218
            align="center"
            variant="h3"
            style={{
              fontWeight: 'bold',
              color: '#333333',
              marginTop: '10px',
            }}>
            {title}
          </RText2218>
        </Box>
        <Box>
          <Typography
            align="center"
            style={{
              fontSize: '16px',
              lineHeight: '24px',
              fontWeight: 300,
              color: '#333333',
              marginTop: '10px',
            }}>
            {description}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
