import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withWidth, {isWidthUp} from '@material-ui/core/withWidth'
import {ParagraphSubHeader} from '../common/common'
import {AlertCircle} from 'react-feather'
import {
  BackyardPaverProjectConfigProps,
  PaverProjectConfigModel,
  BackyardPaverProjectConfigModel,
} from './project/paver/common'
import {PaverStyleSection} from './project/paver/PaverStyle'
import {PaverColorSection} from './project/paver/PaverColor'
import {PaverBorderColorSection} from './project/paver/PaverBorderColor'
import {PaverPatternSection} from './project/paver/PaverPattern'
import {PaverWallStyleSection} from './project/paver/PaverWallStyle'
import {PaverFirePitShapeSection} from './project/paver/PaverFirePitShape'
import {PergolaStyleSection} from './project/paver/PergolaStyle'
import {PaverWallColorSection} from './project/paver/PaverWallColor'
import {PaverFirePitColorSection} from './project/paver/PaverFirePitColor'
import {PaverWallConfigModel, PaverFirePitConfigModel, PergolaConfigModel} from '../../model/common'
import {backyardPaverProjectPackages} from '../../model/pavers'
import {HidePergola} from '../../config/config'
import {useTheme} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {bpNumber, bpString} from '../../utils/styles'
import {RText3220} from '../../components/common/RText'

export const BackyardPaverProjectConfigComp = withWidth()(function (
  props: BackyardPaverProjectConfigProps,
) {
  const theme = useTheme()
  const isMD = isWidthUp('md', (props as any).width)
  const isSM = isWidthUp('sm', (props as any).width)
  const {wall, firepit, pergola, onUpdate, final, projectType, ...rest} = props
  // must not have property with value = undefined,
  // otherwise write to Firestore will fail
  const sanitizedModel: BackyardPaverProjectConfigModel = rest
  if (wall) {
    sanitizedModel.wall = wall
  }
  if (firepit) {
    sanitizedModel.firepit = firepit
  }
  if (pergola) {
    sanitizedModel.pergola = pergola
  }

  const onPaverUpdate = (m: PaverProjectConfigModel) => {
    onUpdate({...sanitizedModel, ...m})
  }
  const onWallUpdate = (wall: PaverWallConfigModel) => {
    onUpdate({...sanitizedModel, wall})
  }
  const onFirePitUpdate = (firepit: PaverFirePitConfigModel) => {
    onUpdate({...sanitizedModel, firepit})
  }
  const onPergolaUpdate = (pergola: PergolaConfigModel) => {
    onUpdate({...sanitizedModel, pergola})
  }

  const {size, package: pkg} = props
  const isBase = pkg === backyardPaverProjectPackages[0].name
  const isPremium = !isBase && pkg === backyardPaverProjectPackages[2].name

  const px = isMD ? 40 : 20
  return (
    <Box
      mt={bpNumber(theme, useMediaQuery, [2, 3, 4, 5, 5])}
      mx={bpNumber(theme, useMediaQuery, [-2, 0, 0, 0, 0])}>
      <Paper elevation={0} style={{borderRadius: isMD ? 8 : 0, backgroundColor: 'white'}}>
        <Box
          style={{
            paddingLeft: px,
            paddingRight: px,
            paddingTop: 30,
            paddingBottom: isSM ? 50 : 30,
          }}>
          <Box>
            <Typography
              style={{
                fontSize: bpString(theme, useMediaQuery, ['24px', '27px', '30px', '33px', '36px']),
                lineHeight: bpString(theme, useMediaQuery, [
                  '36px',
                  '40px',
                  '45px',
                  '48px',
                  '54px',
                ]),
                fontWeight: 300,
                color: '#333333',
              }}
              align="center">
              Configuration
            </Typography>
          </Box>

          <Box display="flex" flexDirection="row" style={{marginTop: isSM ? 32 : 11}}>
            <Box>
              <AlertCircle />
            </Box>
            <Box style={{marginLeft: 6}}>
              <ParagraphSubHeader>
                Don’t worry, these choices will be reviewed with you and can be changed later. They
                don’t affect your upfront price.
              </ParagraphSubHeader>
            </Box>
          </Box>

          <Box style={{marginTop: 30}}>
            <RText3220
              style={{
                fontWeight: 'bold',
                color: '#333333',
              }}>
              Patio
            </RText3220>
          </Box>

          <Box style={{marginTop: 30}}>
            <PaverStyleSection
              style={props.style}
              isSM={isMD}
              final={props.final}
              projectType={props.projectType}
              onUpdate={onPaverUpdate}
            />
          </Box>

          <Box style={{marginTop: 30}}>
            <PaverColorSection
              style={props.style}
              color={props.color}
              borderColor={props.borderColor}
              pattern={props.pattern}
              isSM={isMD}
              final={props.final}
              projectType={props.projectType}
              onUpdate={onPaverUpdate}
            />
          </Box>

          <Box style={{marginTop: 30}}>
            <PaverBorderColorSection
              style={props.style}
              color={props.color}
              borderColor={props.borderColor}
              pattern={props.pattern}
              isSM={isMD}
              final={props.final}
              onUpdate={onPaverUpdate}
            />
          </Box>

          <Box style={{marginTop: 30}}>
            <PaverPatternSection
              style={props.style}
              color={props.color}
              borderColor={props.borderColor}
              pattern={props.pattern}
              isSM={isMD}
              final={props.final}
              onUpdate={onPaverUpdate}
            />
          </Box>

          {isBase ? null : (
            <Box style={{marginTop: 30}}>
              <Box>
                <RText3220
                  style={{
                    fontWeight: 'bold',
                    color: '#333333',
                  }}>
                  Wall
                </RText3220>
              </Box>

              <Box style={{marginTop: 30}}>
                <PaverWallStyleSection
                  style={props.wall?.style}
                  isSM={isMD}
                  final={props.final}
                  onUpdate={onWallUpdate}
                />
              </Box>

              <Box style={{marginTop: 30}}>
                <PaverWallColorSection
                  style={props.wall?.style}
                  color={props.wall?.color}
                  isSM={isMD}
                  final={props.final}
                  onUpdate={onWallUpdate}
                />
              </Box>
            </Box>
          )}

          {isPremium ? (
            <Box style={{marginTop: 30}}>
              <Box>
                <RText3220
                  style={{
                    fontWeight: 'bold',
                    color: '#333333',
                  }}>
                  Fire Pit
                </RText3220>
              </Box>
              <Box style={{marginTop: 30}}>
                <PaverFirePitShapeSection
                  shape={props.firepit?.shape}
                  isSM={isMD}
                  final={props.final}
                  onUpdate={onFirePitUpdate}
                />
              </Box>

              <Box style={{marginTop: 30}}>
                <PaverFirePitColorSection
                  shape={props.firepit?.shape}
                  color={props.firepit?.color}
                  isSM={isMD}
                  final={props.final}
                  onUpdate={onFirePitUpdate}
                />
              </Box>
            </Box>
          ) : null}

          {HidePergola || !isPremium ? null : (
            <Box style={{marginTop: 30}}>
              <Box>
                <RText3220
                  style={{
                    fontWeight: 'bold',
                    color: '#333333',
                  }}>
                  Pergola
                </RText3220>
              </Box>
              <Box style={{marginTop: 30}}>
                <PergolaStyleSection
                  style={props.pergola?.style}
                  isSM={isMD}
                  final={props.final}
                  onUpdate={onPergolaUpdate}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  )
})
