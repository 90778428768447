import Box from '@material-ui/core/Box'
import {Header} from '../components/shop/Header'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import {TitledPanel} from '../components/common/TitledPanel'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import {HEADER_HEIGHT, MOBILE_HOME_HEADER_HEIGHT} from '../config/styles'
import withWidth, {isWidthUp} from '@material-ui/core/withWidth'
import {RText2016, RText1612} from '../components/common/RText'

const styles = (theme: Theme) =>
  createStyles({
    description: {fontWeight: 300, color: '#333333'},
    emphasize: {fontWeight: 'bold', color: '#333333'},
    entryTitle: {fontWeight: 600, color: '#333333'},
  })
const useStyles = makeStyles(styles)

export const Jobs = withWidth()(function (props: any) {
  const isMD = isWidthUp('md', (props as any).width)
  const isSM = isWidthUp('sm', (props as any).width)
  const classes = useStyles()
  return (
    <Box flexDirection="column" style={{minHeight: 'calc(100vh - 92px)'}}>
      <Header />
      <Box
        style={{
          backgroundColor: '#f5f5f5',
          flexGrow: 1,
          marginTop: isSM ? HEADER_HEIGHT : MOBILE_HOME_HEADER_HEIGHT,
        }}>
        <Container style={{padding: 0}}>
          <Grid container spacing={0} justify="center">
            <Grid item lg={9} md={10} sm={11} xs={12}>
              <TitledPanel title="Jobs" narrow={isSM} mx={isMD ? 0 : 0} mt={isMD ? 0 : 1}>
                <Box>
                  <RText2016 className={classes.description}>
                    We are currently hiring for the following positions.
                  </RText2016>
                </Box>
                <Box mt={3}>
                  <RText2016 className={classes.description}>
                    Interested? Send us an email with your resume to:
                  </RText2016>
                  <RText2016 className={classes.emphasize}>jobs@bricko.co</RText2016>
                </Box>
                <Box mb={5}>
                  {[
                    {title: 'Marketing Director, Digital Communications', location: 'San Jose, CA'},
                    {title: 'Mobile Developer', location: 'San Jose, CA'},
                    {title: 'Visual Designer', location: 'San Jose, CA'},
                    {title: 'Business Development', location: 'San Jose, CA'},
                  ].map((t, i) => (
                    <Box mt={4} key={i}>
                      <RText1612 className={classes.entryTitle}>{t.title}</RText1612>
                      <RText1612>{t.location}</RText1612>
                    </Box>
                  ))}
                </Box>
              </TitledPanel>
              <Box mb={10}></Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
})
