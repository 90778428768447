import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import {BRAND_YELLOW} from '../../config/styles'
import * as Icon from 'react-feather'

export interface CheckBoxCompProps {
  checked: boolean
  label: string
  description: string
  isSM: boolean
  final: boolean
  onToggle: () => void
}
export const CheckBoxComp = (props: CheckBoxCompProps) => {
  const {checked, label, description, isSM, final, onToggle} = props
  return (
    <Box
      style={{
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer',
        border: checked ? `2px solid ${BRAND_YELLOW}` : '2px solid transparent',
        backgroundColor: checked ? 'white' : '#f5f5f5',
        padding: '10px 20px',
      }}
      onClick={onToggle}>
      {checked ? (
        <Box>
          <Icon.CheckSquare size={26} style={{color: BRAND_YELLOW, zIndex: 1}} />
        </Box>
      ) : (
        <Box
          style={{
            width: '26px',
            height: '26px',
            position: 'relative',
            marginTop: -5,
          }}>
          <Box
            style={{
              backgroundColor: 'white',
              position: 'absolute',
              top: 4,
              left: 4,
              width: '18px',
              height: '18px',
              borderRadius: '2px',
            }}></Box>
          <Icon.Square size={26} style={{color: '#cccccc', zIndex: 1}} />
        </Box>
      )}
      <Box ml={2}>
        <Box>
          <Typography
            style={{
              fontSize: isSM ? '16px' : '14px',
              lineHeight: isSM ? '24px' : '21px',
              fontWeight: 600,
              color: '#333333',
              // marginBottom: 4,
              opacity: final && !checked ? 0.4 : 1,
            }}>
            {label}
          </Typography>
        </Box>

        <Box>
          <Typography
            style={{
              marginTop: 4,
              fontSize: '13px',
              lineHeight: '19px',
              fontWeight: 300,
              opacity: final && !checked ? 0.4 : 1,
            }}>
            {description}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export const CircleCheckBoxComp = (props: CheckBoxCompProps) => {
  const {checked, label, description, isSM, final, onToggle} = props
  return (
    <Box
      style={{
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer',
        border: checked ? `2px solid ${BRAND_YELLOW}` : '2px solid transparent',
        backgroundColor: checked ? 'white' : '#f5f5f5',
        padding: '10px 20px',
      }}
      onClick={onToggle}>
      {checked ? (
        <CheckCircleIcon style={{color: BRAND_YELLOW, fontSize: 26, zIndex: 1}} />
      ) : (
        <Box
          style={{
            width: '26px',
            height: '26px',
            position: 'relative',
          }}>
          <Box
            style={{
              backgroundColor: 'white',
              position: 'absolute',
              top: 4,
              left: 4,
              width: '18px',
              height: '18px',
              borderRadius: '9px',
            }}></Box>
          <RadioButtonUnchecked style={{color: '#cccccc', fontSize: 26, zIndex: 1}} />
        </Box>
      )}
      <Box ml={2}>
        <Box>
          <Typography
            style={{
              fontSize: isSM ? '16px' : '14px',
              lineHeight: isSM ? '24px' : '21px',
              fontWeight: 600,
              color: '#333333',
              // marginBottom: 4,
              opacity: final && !checked ? 0.4 : 1,
            }}>
            {label}
          </Typography>
        </Box>

        <Box>
          <Typography
            style={{
              marginTop: 4,
              fontSize: '13px',
              lineHeight: '19px',
              fontWeight: 300,
              opacity: final && !checked ? 0.4 : 1,
            }}>
            {description}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
