import {PaverFirePitOptions, PaverColorOption, PaverFirePitOption} from '../../../../model/pavers'

export const getPaverFirePitOption = (shape: string): PaverFirePitOption | null => {
  if (shape) {
    const paverOpt = PaverFirePitOptions.find((po) => po.shape === shape)
    return paverOpt ? paverOpt : null
  }
  return null
}

export const getPaverFirePitColors = (shape: string): PaverColorOption[] => {
  const paverOpt = getPaverFirePitOption(shape)
  if (paverOpt) {
    return paverOpt.colors
  }
  return []
}
