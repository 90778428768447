import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography, {TypographyProps} from '@material-ui/core/Typography'
import withWidth, {isWidthUp} from '@material-ui/core/withWidth'

import {useTheme} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {bpNumber, bpString} from '../../utils/styles'

/*
xs, extra-small: 0px
sm, small: 600px
md, medium: 960px
lg, large: 1280px
xl, extra-large: 1920px
*/

// we use the same size for lg and xl
function interpolate(min: number, max: number): number[] {
  const results = [min]
  const delta = (max - min) / 3
  for (let i = 1; i <= 2; i++) {
    const n = min + Math.round(delta * i)
    results.push(n)
  }
  results.push(max)
  results.push(max)
  return results
}
export interface RTextProps extends TypographyProps {
  size: number
  msize?: number
}

// RText - R for Responsive
// size = font size at max breakpoint
// msize (optional) = font size at min breakpoint
// if msize not set, will set it to 2/3 * size
export function RText(props: RTextProps) {
  const {size, msize, children, style: _style, ...rest} = props
  const theme = useTheme()
  let minSize = msize
  let style = _style
  if (!minSize) {
    minSize = Math.round((size * 2) / 3)
  }
  const sizes = interpolate(minSize, size)
  const fontSize = bpNumber(theme, useMediaQuery, sizes)
  const lineHeight = Math.round(1.5 * fontSize) + 'px'
  const fstyle = {fontSize, lineHeight}
  if (!style) {
    style = fstyle
  } else {
    style = {...style, ...fstyle}
  }
  return (
    <Typography {...rest} style={style}>
      {children}
    </Typography>
  )
}
export function RTextN(n: number): typeof Typography {
  return function (props: TypographyProps) {
    const {children, ...rest} = props
    return (
      <RText {...rest} size={n}>
        {children}
      </RText>
    )
  }
}
export function RTextMN(max: number, min: number): typeof Typography {
  return function (props: TypographyProps) {
    const {children, ...rest} = props
    return (
      <RText {...rest} size={max} msize={min}>
        {children}
      </RText>
    )
  }
}
export const RText48 = RTextN(48)
export const RText44 = RTextN(44)
export const RText40 = RTextN(40)
export const RText36 = RTextN(36)
export const RText32 = RTextN(32)
export const RText30 = RTextN(30)
export const RText28 = RTextN(28)
export const RText26 = RTextN(26)
export const RText24 = RTextN(24)
export const RText22 = RTextN(22)
export const RText20 = RTextN(20)
export const RText18 = RTextN(18)
export const RText16 = RTextN(16)
export const RText14 = RTextN(14)
export const RText12 = RTextN(12)

export const RText4832 = RTextMN(48, 32)
export const RText4020 = RTextMN(40, 20)
export const RText3220 = RTextMN(32, 20)
export const RText2218 = RTextMN(22, 18)
export const RText2016 = RTextMN(20, 16)
export const RText2012 = RTextMN(20, 12)
export const RText1614 = RTextMN(16, 14)
export const RText1612 = RTextMN(16, 12)

export function RSpan(props: RTextProps) {
  const {size, msize, children, style: _style, ...rest} = props
  const theme = useTheme()
  let minSize = msize
  let style = _style
  if (!minSize) {
    minSize = Math.round((size * 2) / 3)
  }
  const sizes = interpolate(minSize, size)
  const fontSize = bpNumber(theme, useMediaQuery, sizes)
  const lineHeight = Math.round(1.5 * fontSize) + 'px'
  const fstyle = {fontSize, lineHeight}
  if (!style) {
    style = fstyle
  } else {
    style = {...style, ...fstyle}
  }
  return (
    <span {...rest} style={style}>
      {children}
    </span>
  )
}
export function RSpanN(n: number): typeof Typography {
  return function (props: TypographyProps) {
    const {children, ...rest} = props
    return (
      <RSpan {...rest} size={n}>
        {children}
      </RSpan>
    )
  }
}
export function RSpanMN(max: number, min: number): typeof Typography {
  return function (props: TypographyProps) {
    const {children, ...rest} = props
    return (
      <RSpan {...rest} size={max} msize={min}>
        {children}
      </RSpan>
    )
  }
}

export const RSpan1612 = RSpanMN(16, 12)
export const RSpan1614 = RSpanMN(16, 14)
