import {useState, useRef, useEffect} from 'react'
// import '../App.css'
import firebase from 'firebase/app'
// import './Home.css'
import Box from '@material-ui/core/Box'
import {useHistory, useLocation} from 'react-router-dom'
import {AboutBricko} from '../components/home/AboutBricko'
import {BrickoServices} from '../components/home/BrickoServices'
import {HowBrickoWorks} from '../components/home/HowBrickoWorks'
import {HomeHeader} from '../components/home/HomeHeader'
import {BrickoActions} from '../components/home/BrickoActions'
import {BrickoValues} from '../components/home/BrickoValues'
import {TechDriven} from '../components/home/TechDriven'
import {ExampleProjects} from '../components/home/ExampleProjects'
import {HomeImageHeader} from '../components/home/HomeImageHeader'
import {PhoneNumberInputDialog} from '../components/shop/PhoneNumberInputDialog'
import {phoneSignIn} from '../services/FirebaseService'
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
  getBlankConfirmationDialogProps,
  makeShowErrorDialogFn,
} from '../components/common/dialogs'
import {PhoneVerificationCodeDialog} from '../components/shop/PhoneVerificationCodeDialog'
import {
  SuggestProjectsDialogModel,
  getBlankSuggestProjectsDialogModel,
} from '../components/common/SuggestProjectsDialog'
import {writeEvent} from '../services/FirebaseService'
import Logger from 'js-logger'
import withWidth, {isWidthUp} from '@material-ui/core/withWidth'
import {
  ProjectTypeValue,
  getProjectQueryParamByProjectType,
  FeaturedProjectSlideInfoList,
  getProjectIndexByProjectType,
  projectIndexValues,
} from '../model/project'
import {Header} from '../components/shop/Header'
import {
  MailingAddressModel,
  isMailingAddressModelValid,
  mailingAddressModelToString,
  newMailingAddressModel,
} from '../model/common'
import {AddrProjForm} from '../components/home/AddrProjForm'
import {MobileLanding} from '../components/home/MobileLanding'

const Home = withWidth()(function (props: any) {
  const query = new URLSearchParams(useLocation().search)
  const isSM = isWidthUp('sm', props.width)
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const [phoneNumberInputDialogOpen, setPhoneNumberInputDialogOpen] = useState(false)
  const captchaRef = useRef(null)
  const introRef = useRef(null)
  const projectsRef = useRef(null)
  const approachRef = useRef(null)
  const aboutRef = useRef(null)
  const [captchaKey, setCaptchaKey] = useState('k-' + Date.now())

  const [phoneVerificationCodeDialogOpen, setPhoneVerificationCodeDialogOpen] = useState(false)
  const [suggestProjectsDialogOpen, setSuggestProjectsDialogOpen] = useState(false)

  const suggestProjectsModelZero = getBlankSuggestProjectsDialogModel()
  const [suggestProjectModel, setSuggestProjectModel] = useState(suggestProjectsModelZero)

  const [errorDialogProps, setErrorDialogProps] = useState<ConfirmationDialogProps>(
    getBlankConfirmationDialogProps(),
  )
  const showErrorDialog = makeShowErrorDialogFn(setErrorDialogProps)

  const [confirmationResult, setConfirmationResult] =
    useState<firebase.auth.ConfirmationResult | null>(null)

  const [addressInputProjType, setAddressInputProjType] = useState<ProjectTypeValue>(
    ProjectTypeValue.DroughtTolerantFrontYard,
  )
  const [addrInfo, setAddrInfo] = useState<MailingAddressModel>(newMailingAddressModel())

  const [projectIndex, setProjectIndex] = useState(0)
  const onProjectIndexChange = (index: number) => {
    setProjectIndex(index)
    writeEvent('browse_project', {
      operation: 'click',
      page: 'home',
      project: projectIndexValues[index],
      from: 'project_tabs',
    }).catch((err) => {
      // fail
      Logger.warn('Failed to create browse_project event:', err)
    })
  }

  const onChangeSuggestProjectModel = (model: SuggestProjectsDialogModel) => {
    setSuggestProjectModel(model)
  }

  const onClosePhoneNumberInputDialog = async (phoneNumber: string) => {
    setPhoneNumberInputDialogOpen(false)
    if (phoneNumber) {
      try {
        const cr = await phoneSignIn(phoneNumber, captchaRef)
        setConfirmationResult(cr)
        // now show dialog to let user enter confirmation code
        setPhoneVerificationCodeDialogOpen(true)
      } catch (e) {
        Logger.error('Sign in with phone number failed', e)
        // hack to force clean up of the captcha container
        // recommended clean up code (from: https://firebase.google.com/docs/auth/web/phone-auth)
        // does not work for me
        setCaptchaKey('k-' + Date.now())
        // log event
        firebase.analytics().logEvent('phone_signin_error', {
          phone: phoneNumber,
          page: 'home',
          error: e.toString(),
        })
        if (e.code === 'auth/invalid-phone-number') {
          showErrorDialog(`Sign in with phone number failed: invalid phone number ${phoneNumber}`)
        } else {
          showErrorDialog(`Sign in with phone number ${phoneNumber} failed`)
        }
      }
    }
  }

  const onClosePhoneVerificationCodeDialog = async (code: string) => {
    setPhoneVerificationCodeDialogOpen(false)
    //
    if (code && confirmationResult) {
      try {
        const userCreds = await confirmationResult.confirm(code)
        Logger.info('phone verification code confirmed, userCreds=', userCreds)
        const user = userCreds.user
        if (user) {
          history.push('/shop')
        } else {
          showErrorDialog('No user after sign in')
        }
      } catch (e) {
        showErrorDialog('Failed to confirm phone verification code: ' + e.toString())
      }
    } else {
      if (!code) {
        showErrorDialog('No phone confirmation code')
      } else {
        showErrorDialog('No phone confirmation result')
      }
    }
  }

  const onCloseSuggestProjectsDialog = (model: SuggestProjectsDialogModel | null) => {
    setSuggestProjectsDialogOpen(false)
    if (!model) {
      return
    }

    let userId: any = null
    const user = firebase.auth().currentUser
    if (user) {
      userId = user.uid
    }

    const doc = {
      ...model,
      userId,
      createAt: new Date(),
    }

    firebase
      .firestore()
      .collection('suggest_projects')
      .doc()
      .set(doc)
      .then(() => {
        // success
        firebase.analytics().logEvent('suggest_project_api_success', {
          ...doc,
          operation: 'create',
        })

        // write event to Firestore
        writeEvent('suggest_projects', doc).catch((err) => {
          // fail
          Logger.info('Failed to create event:', err)
        })
      })
      .catch((err) => {
        // fail
        Logger.info('Failed to suggest project:', err)
        firebase.analytics().logEvent('api_error', {
          ...doc,
          operation: 'suggest_project',
          error: err.toString(),
        })
        showErrorDialog('Failed to handle your request, please try again later.')
      })
  }

  const handleClickOpen = () => {
    history.push('/shop')
  }

  const handleClickOpenFromUpperRightLink = () => {
    writeEvent('action_smart_proposal', {
      operation: 'open',
      page: 'home',
      from: 'upper_right_link',
    }).catch((err) => {
      // fail
      Logger.warn('Failed to create action_smart_proposal event:', err)
    })
    handleClickOpen()
  }
  const onClickLink = (link: string) => {
    const offset = isSM ? 120 : 90
    const offset2 = isSM ? 40 : 90
    switch (link) {
      case 'Home':
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
        break
      case 'Intro':
        if (introRef.current) {
          window.scrollTo({
            top: (introRef.current as any).offsetTop - offset,
            left: 0,
            behavior: 'smooth',
          })
        }
        break
      case 'Projects':
        if (projectsRef.current) {
          window.scrollTo({
            top: (projectsRef.current as any).offsetTop - offset,
            left: 0,
            behavior: 'smooth',
          })
        }
        break
      case 'Approach':
        if (approachRef.current) {
          window.scrollTo({
            top: (approachRef.current as any).offsetTop - offset,
            left: 0,
            behavior: 'smooth',
          })
        }
        break
      case 'About':
        if (aboutRef.current) {
          window.scrollTo({
            top: (aboutRef.current as any).offsetTop - offset2,
            left: 0,
            behavior: 'smooth',
          })
        }
        break
      default:
        break
    }
  }
  const handleClickOpenFromPanelButton = () => {
    writeEvent('action_smart_proposal', {
      operation: 'open',
      page: 'home',
      from: 'panel_button',
    }).catch((err) => {
      // fail
      Logger.warn('Failed to create action_smart_proposal event:', err)
    })
    handleClickOpen()
  }
  const handleClickOpenFromImageButton = () => {
    writeEvent('action_smart_proposal', {
      operation: 'open',
      page: 'home',
      from: 'image_button',
    }).catch((err) => {
      // fail
      Logger.warn('Failed to create action_smart_proposal event:', err)
    })
    handleClickOpen()
  }

  const handleClickOpenFromHowWork = () => {
    writeEvent('action_smart_proposal', {
      operation: 'open',
      page: 'home',
      from: 'how_works',
    }).catch((err) => {
      // fail
      Logger.warn('Failed to create action_smart_proposal event:', err)
    })
    handleClickOpen()
  }

  const handleClickOpenFromDialog = () => {
    writeEvent('action_smart_proposal', {
      operation: 'open',
      page: 'home',
      from: 'dialog',
    }).catch((err) => {
      // fail
      Logger.warn('Failed to create action_smart_proposal event:', err)
    })
    handleClickOpen()
  }

  const onClickProjectDetails = () => {
    firebase.analytics().logEvent('action_project_details', {operation: 'open', page: 'home'})
    // if not logged in: show phone dialog > verification code
    // once logged in, make sure user has proposal + order?
    //   yes > navigate to page
    //   no  > error dialog, project not found for phone number
    const user = firebase.auth().currentUser
    if (user) {
      // handleProjectDetails()
      history.push('/shop')
    } else {
      setPhoneNumberInputDialogOpen(true)
    }
  }
  const onClickRequestProposal = () => {
    history.push('/rfp')
  }
  const onClickRequestProposalFromPanel = () => {
    firebase
      .analytics()
      .logEvent('action_request_proposal', {operation: 'open', page: 'home', from: 'panel'})
    onClickRequestProposal()
  }
  const onClickRequestProposalFromDialog = () => {
    firebase
      .analytics()
      .logEvent('action_request_proposal', {operation: 'open', page: 'home', from: 'dialog'})
    onClickRequestProposal()
  }
  const onClickRequestProposalFromImageLink = () => {
    firebase
      .analytics()
      .logEvent('action_request_proposal', {operation: 'open', page: 'home', from: 'image'})
    onClickRequestProposal()
  }
  const onClickRequestProposalFromHowWorkLink = () => {
    firebase
      .analytics()
      .logEvent('action_request_proposal', {operation: 'open', page: 'home', from: 'how_work_link'})
    onClickRequestProposal()
  }
  const onClickInviteFriend = () => {
    firebase.analytics().logEvent('action_invite_friend', {operation: 'open', page: 'home'})
    history.push('/invite')
  }

  const onClickSuggestProject = () => {
    firebase.analytics().logEvent('action_suggest_project', {operation: 'open', page: 'home'})
    setSuggestProjectsDialogOpen(true)
  }

  const [addressGetSmartProposalEnabled, setAddressGetSmartProposalEnabled] = useState(false)
  const addressRef = useRef<any>(null)
  const onUpdateAddress = (x: MailingAddressModel) => {
    setAddrInfo(x)
    setAddressGetSmartProposalEnabled(isMailingAddressModelValid(x))
    addressRef.current = x
  }
  const onAddressInputChangeHook = (event: any, newValue: string) => {
    // check address match with auto complete - force user to use auto complete
    setAddressGetSmartProposalEnabled(
      addressRef.current && newValue.trim() === mailingAddressModelToString(addressRef.current),
    )
  }

  const onClickAddressInputGetSmartProposal = () => {
    // add query parameter to pre-select project type in order page
    // note: enter_address event will be logged by the order page (Shop.tsx)
    // due to the presence of the address query parameter
    const projectType = getProjectQueryParamByProjectType(addressInputProjType)
    const address = encodeURIComponent(JSON.stringify(addressRef.current))
    history.push(`/shop?projectType=${projectType}&address=${address}`)
  }

  const onClickMobileLandingGetSmartProposal = (projectType: ProjectTypeValue) => {
    writeEvent('action_smart_proposal', {
      operation: 'open',
      page: 'home',
      from: 'mobile_landing',
      projectType,
    }).catch((err) => {
      // fail
      Logger.warn('Failed to create action_smart_proposal event:', err)
    })
    // add query parameter to pre-select project type in order page
    const pt = getProjectQueryParamByProjectType(projectType)
    history.push(`/shop?projectType=${pt}`)
  }

  const onClickMobileLandingGetSmartProposal2 = (projectType: ProjectTypeValue) => {
    writeEvent('action_smart_proposal', {
      operation: 'open',
      page: 'home',
      from: 'mobile_landing',
      projectType,
    }).catch((err) => {
      // fail
      Logger.warn('Failed to create action_smart_proposal event:', err)
    })
    // add query parameter to pre-select project type in order page
    const pt = getProjectQueryParamByProjectType(projectType)
    const address = encodeURIComponent(JSON.stringify(addressRef.current))
    history.push(`/shop?projectType=${pt}&address=${address}`)
  }

  useEffect(() => {
    const link = query.get('link')
    if (link) {
      setTimeout(() => {
        onClickLink(link)
      }, 500)
    }
  }, [])

  // const landing_cell = query.get('landing_cell')
  // const landingData = getLandingData()
  // const newLanding =
  //   !isSM && (landing_cell ? landing_cell === 'slide' : landingData?.cell === 'slide')
  const newLanding = !isSM
  return (
    <Box>
      {isSM ? (
        <HomeHeader
          onClickGetSmartProposal={handleClickOpenFromUpperRightLink}
          onClickLink={onClickLink}
        />
      ) : (
        <Header onClickLink={onClickLink} />
      )}

      {newLanding ? null : (
        <HomeImageHeader onClickGetSmartProposal={handleClickOpenFromImageButton} />
      )}

      {isSM || newLanding ? null : (
        <AddrProjForm
          addr={addrInfo.addr}
          addr2={addrInfo.addr2}
          city={addrInfo.city}
          state={addrInfo.state}
          zipCode={addrInfo.zipCode}
          onUpdateAddress={onUpdateAddress}
          onAddressInputChangeHook={onAddressInputChangeHook}
          addressGetSmartProposalEnabled={addressGetSmartProposalEnabled}
          projectType={addressInputProjType}
          onClickAddressInputGetSmartProposal={onClickAddressInputGetSmartProposal}
          onChangeProjectType={(value: ProjectTypeValue) => setAddressInputProjType(value)}
        />
      )}
      {newLanding ? (
        <MobileLanding
          slides={FeaturedProjectSlideInfoList}
          onClickLearnMore={(projectType: ProjectTypeValue) => {
            // TODO FIXME
            // set project type, then scroll to project
            setProjectIndex(getProjectIndexByProjectType(projectType))
            onClickLink('Projects')
          }}
          onClickGetSmartProposal={onClickMobileLandingGetSmartProposal2}
          addr={addrInfo.addr}
          addr2={addrInfo.addr2}
          city={addrInfo.city}
          state={addrInfo.state}
          zipCode={addrInfo.zipCode}
          addressGetSmartProposalEnabled={addressGetSmartProposalEnabled}
          onUpdateAddress={onUpdateAddress}
          onAddressInputChangeHook={onAddressInputChangeHook}
        />
      ) : null}

      <div ref={introRef}></div>
      <HowBrickoWorks onClickGetSmartProposal={handleClickOpenFromHowWork} />
      {isSM ? <BrickoValues /> : null}
      <div ref={projectsRef}></div>
      <ExampleProjects
        onClickSuggestProject={onClickSuggestProject}
        projectIndex={projectIndex}
        onProjectIndexChange={onProjectIndexChange}
      />
      {isSM ? (
        <BrickoActions
          onClickOpenFromPanelButton={handleClickOpenFromPanelButton}
          onClickProjectDetails={onClickProjectDetails}
          onClickInviteFriend={onClickInviteFriend}
        />
      ) : null}
      <div ref={approachRef}></div>
      <TechDriven />
      <BrickoServices
        onClickSuggestProject={onClickSuggestProject}
        suggestProjectsDialogOpen={suggestProjectsDialogOpen}
        onCloseSuggestProjectsDialog={onCloseSuggestProjectsDialog}
        model={suggestProjectModel}
        onChange={onChangeSuggestProjectModel}
      />
      <div ref={aboutRef}></div>
      <AboutBricko />
      <div key={captchaKey} id="recaptcha-container-home" ref={captchaRef}></div>
      <PhoneNumberInputDialog
        title="Track My Project"
        message="Enter your phone number so we can retrieve your project details"
        open={phoneNumberInputDialogOpen}
        onClose={onClosePhoneNumberInputDialog}
        placeholder=""
      />
      <ConfirmationDialog {...errorDialogProps} />
      <PhoneVerificationCodeDialog
        open={phoneVerificationCodeDialogOpen}
        onClose={onClosePhoneVerificationCodeDialog}
      />
    </Box>
  )
})

export default Home
