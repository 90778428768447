import firebase from 'firebase/app'
import {PromoCodeDialog} from './PromoCodeDialog'
import Logger from 'js-logger'

import {
  OrderModel,
  getDroughtTolerantFrontYardModelPrice,
  getBackyardPaverProjectModelPrice,
  getDrivewayPaverProjectModelPrice,
} from '../../model/common'

export interface PromoCodeDialogWrapperProps {
  model: OrderModel
  open: boolean
  onClose: () => any
  onModelUpdate: (mdl: OrderModel) => void
  onError: (msg: string) => void
}

export function PromoCodeDialogWrapper(props: PromoCodeDialogWrapperProps) {
  const {model, open, onClose, onModelUpdate, onError} = props

  const handlePromoCode = async (code: string) => {
    if (!code) {
      return
    }
    firebase.analytics().logEvent('enter_promo_code', {
      ...model,
      operation: 'create',
      code,
    })
    try {
      const resp = await firebase.functions().httpsCallable('promoCode')({
        code,
      })
      // if found, resp.data will have the promo code data
      // if not found, resp.data will be null
      Logger.info('handlePromoCode code=', code, 'response=', resp)
      if (!resp.data) {
        onError(`Promo code [${code}] is not valid.`)
        return
      }
      // now have promo code
      firebase.analytics().logEvent('enter_promo_code_success', {
        ...model,
        operation: 'create',
        code,
      })
      const mdl = {
        ...model,
        promoCode: resp.data.id,
        discount: resp.data.discount,
      }
      // re-calculate prices
      const {drivewayPaverProject, backyardPaverProject, droughtTolerantFrontYardModel} = mdl
      if (drivewayPaverProject) {
        drivewayPaverProject.price = getDrivewayPaverProjectModelPrice(mdl)
      }
      if (backyardPaverProject) {
        backyardPaverProject.price = getBackyardPaverProjectModelPrice(mdl)
      }
      if (droughtTolerantFrontYardModel) {
        droughtTolerantFrontYardModel.price = getDroughtTolerantFrontYardModelPrice(mdl)
      }
      onModelUpdate(mdl)
    } catch (e) {
      Logger.error('failed to get promoCode:', e)
      onError('Failed to lookup promo code. Please try again later.')
    }
  }

  return (
    <PromoCodeDialog
      open={open}
      onClose={(code: string) => {
        // setPromoCodeDialogOpen(false)
        onClose()
        handlePromoCode(code)
      }}
    />
  )
}
