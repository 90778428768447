import {useState} from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import {BRAND_YELLOW} from '../../config/styles'
import {useHistory} from 'react-router-dom'
import withWidth, {isWidthUp} from '@material-ui/core/withWidth'
import Menu from '@material-ui/core/Menu'
import * as Icon from 'react-feather'
import firebase from 'firebase/app'
import {writeEvent} from '../../services/FirebaseService'
import Logger from 'js-logger'

const ActionButton = ({label, onClick}: {label: string; onClick: () => void}) => (
  <Box>
    <Button
      size={'large'}
      onClick={onClick}
      variant="contained"
      style={{
        backgroundColor: BRAND_YELLOW,
        color: 'white',
        textTransform: 'none',
        fontSize: 14,
        fontWeight: 600,
        width: 165,
        marginLeft: 20,
        marginBottom: 12,
      }}>
      {label}
    </Button>
  </Box>
)

export const Header = withWidth()(function (props: any) {
  const history = useHistory()
  const isSM = isWidthUp('sm', props.width)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }
  const handleMobileMenuOpen = (event: any) => {
    Logger.info('menu click: event=', event)
    setMobileMoreAnchorEl(event.target.parentElement)
  }
  const links = ['Home', 'Intro', 'Projects', 'Approach', 'About']

  const mobileMenuId = 'header-hamburger-menu-mobile'
  const onClickInviteFriend = () => {
    handleMobileMenuClose()
    firebase.analytics().logEvent('action_invite_friend', {operation: 'open', page: 'menu'})
    history.push('/invite')
  }
  const onClickProjectDetails = () => {
    handleMobileMenuClose()
    firebase.analytics().logEvent('action_project_details', {operation: 'open', page: 'menu'})
    history.push('/shop')
  }
  const onClickSmartProposal = () => {
    handleMobileMenuClose()
    writeEvent('action_smart_proposal', {
      operation: 'open',
      page: 'menu',
      from: 'menu_button',
    }).catch((err) => {
      // fail
      Logger.warn('Failed to create action_smart_proposal event:', err)
    })
    history.push('/shop')
  }
  const MobileMenu = (props: any) => (
    <Menu
      className="hamburger"
      style={{marginTop: 47, width: '100%'}}
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{vertical: 'top', horizontal: 'right'}}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}>
      <Box px={3} width="100%" display="flex">
        <Box style={{width: '40%', borderRight: '1px solid #eeeeee'}}>
          {links.map((x, i) => (
            <Box
              style={{
                width: window.innerWidth,
                margin: 0,
              }}
              key={x}
              onClick={() => {
                setMobileMoreAnchorEl(null)
                if (props.onClickLink) {
                  props.onClickLink(x)
                } else {
                  history.push('/?link=' + x)
                }
              }}>
              <p>{x}</p>
            </Box>
          ))}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          style={{width: '60%'}}>
          <ActionButton label="Invite Friend" onClick={onClickInviteFriend} />
          <ActionButton label="Track My Project" onClick={onClickProjectDetails} />
          <ActionButton label="Smart Proposal" onClick={onClickSmartProposal} />
        </Box>
      </Box>
    </Menu>
  )

  const IconComp = isMobileMenuOpen ? Icon.X : Icon.Menu

  return (
    <Box
      style={{
        position: 'fixed',
        top: 0,
        zIndex: 5,
        width: '100%',
        backgroundColor: 'white',
        borderBottom: '1px solid #eeeeee',
      }}>
      <Box
        style={{
          display: 'flex',
          flexDirection: isSM ? 'row' : 'column',
          alignItems: 'center',
          padding: isSM ? '20px 50px' : '5px 20px',
        }}>
        <Box
          onClick={() => {
            if (isSM) {
              history.replace('/')
            } else {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
              })
            }
          }}
          style={{cursor: 'pointer'}}>
          <Typography
            align="left"
            variant="h1"
            style={{
              color: BRAND_YELLOW,
              fontFamily: 'Days One',
              fontWeight: 'normal',
              fontSize: isSM ? 48 : 30,
              lineHeight: isSM ? '61px' : '45px',
            }}>
            Bricko
          </Typography>
        </Box>
      </Box>
      {!isSM ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            padding: '15px 20px',
            height: '100%',
          }}>
          <IconComp
            aria-label="show more"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            size={26}
            style={{color: BRAND_YELLOW, zIndex: 1}}
            onClick={handleMobileMenuOpen}
          />
          <MobileMenu {...props} />
        </Box>
      ) : null}
    </Box>
  )
})
