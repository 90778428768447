import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import {Typography} from '@material-ui/core'
import {BRAND_YELLOW} from '../../../config/styles'
import {useTheme} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {bpNumber} from '../../../utils/styles'

export interface GetSmartProposalPanelProps {
  isSM: boolean
  classes: any
  onClick: () => void
}
export const GetSmartProposalPanel = (props: GetSmartProposalPanelProps) => {
  const theme = useTheme()
  const {onClick, isSM, classes} = props
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      style={{
        padding: isSM ? '40px' : '36px',
        borderRadius: '10px',
        border: '1px solid #eeeeee',
        boxShadow: '0px 5px 30px rgba(0,0,0,0.05)',
      }}>
      <Box>
        <Button
          size={'large'}
          onClick={onClick}
          variant="contained"
          style={{
            backgroundColor: BRAND_YELLOW,
            color: 'white',
            textTransform: 'none',
            fontSize: bpNumber(theme, useMediaQuery, [14, 14, 14, 16, 16]),
            fontWeight: 600,
          }}>
          Get Instant Smart Proposal
        </Button>
      </Box>
      <Box mt={3}>
        <Typography className={classes.details}>
          A Bricko Smart Proposal is prepared immediately, enables you to customize to match your
          style and gives you an upfront & guaranteed price!
        </Typography>
      </Box>
    </Box>
  )
}
