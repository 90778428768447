import {useState} from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import {ParagraphHeader, ParagraphSmallPrint} from './common'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import {ProjectTypeValue, ProjectDescriptions} from '../../model/project'
import Link from '@material-ui/core/Link'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: '100%',
    },
    select: {
      marginTop: theme.spacing(0.5),
      // backgroundColor: '#f5f5f5',
    },
  }),
)

const showMoreComingSoon = false

export interface ProjectTypeProps {
  value: string
  onChange: (value: ProjectTypeValue) => void
  headerFontWeight?: string | number
  isSM: boolean
  showDescription: boolean
  label?: string
  allowSelectDisabled?: boolean
}
export const ProjectType = (props: ProjectTypeProps) => {
  const classes = useStyles()
  const {
    value,
    isSM,
    showDescription,
    headerFontWeight: fontWeight,
    allowSelectDisabled,
    onChange,
  } = props
  const description = ProjectDescriptions[value as ProjectTypeValue]
  const label = props.label || 'Renovation'
  const disabled = !allowSelectDisabled
  const toggleDescription = !isSM && showDescription && !!description
  const [descriptionVisible, setDescriptionVisible] = useState(
    showDescription && !!description && isSM,
  )
  return (
    <Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <ParagraphHeader fontWeight={fontWeight}>{label}</ParagraphHeader>
        <Box style={{flexGrow: 1}}></Box>
        {showMoreComingSoon ? <ParagraphSmallPrint>More Coming Soon!</ParagraphSmallPrint> : null}
      </Box>
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          className={classes.select}
          id="demo-simple-select-outlined"
          value={value}
          style={{fontSize: isSM ? 16 : 14}}
          onChange={(e) => onChange(e.target.value as ProjectTypeValue)}>
          <MenuItem
            value={ProjectTypeValue.DroughtTolerantFrontYard}
            style={{fontSize: isSM ? 16 : 14}}>
            <span
              style={{
                fontSize: isSM ? 16 : 14,
              }}>{`${ProjectTypeValue.DroughtTolerantFrontYard}`}</span>
          </MenuItem>
          <MenuItem
            value={ProjectTypeValue.BackyardPavingStonePatio}
            style={{fontSize: isSM ? 16 : 14}}>
            {ProjectTypeValue.BackyardPavingStonePatio}
          </MenuItem>
          <MenuItem value={ProjectTypeValue.PavingStoneDriveway} style={{fontSize: isSM ? 16 : 14}}>
            {ProjectTypeValue.PavingStoneDriveway}
          </MenuItem>
          <MenuItem
            disabled={disabled}
            value={ProjectTypeValue.Landscaping}
            style={{fontSize: isSM ? 16 : 14}}>
            <span style={{fontSize: isSM ? 16 : 14}}>{`${ProjectTypeValue.Landscaping}`}</span>
            {disabled ? <span style={{flexGrow: 2}}></span> : null}
            {disabled ? <span style={{fontSize: isSM ? 14 : 10}}>&nbsp;Coming Soon!</span> : null}
          </MenuItem>
        </Select>
      </FormControl>
      {descriptionVisible ? (
        <Box mt={1}>
          <Typography
            style={{
              marginTop: 8,
              fontSize: '13px',
              lineHeight: '19px',
              fontWeight: 300,
            }}>
            {description}
          </Typography>
        </Box>
      ) : null}
      {toggleDescription ? (
        <Link
          component="button"
          style={{
            color: '#005eb5',
            backgroundColor: 'transparent',
            marginTop: 8,
            textDecoration: 'none',
          }}
          onClick={(e: any) => {
            e.preventDefault()
            setDescriptionVisible(!descriptionVisible)
          }}>
          <Typography
            style={{
              fontSize: '13px',
              lineHeight: '19px',
              fontWeight: 500,
              color: '#005eb5',
            }}>
            {descriptionVisible ? 'Hide Description' : 'Show Description'}
          </Typography>
        </Link>
      ) : null}
    </Box>
  )
}
